import { BannerSlider as SourceBannerSlider } from 'AdvoxComponent/BannerSlider/BannerSlider.component';
import Button from 'Component/Button';
import Link from 'Component/Link';
import TypographyHeader from 'Component/TypographyHeader';
import media from 'Util/Media';
import { isColorLight } from 'Util/Transform';

import { CTA_LINK_TYPE_BANNER } from './BannerSlider.config';

/** @namespace Pwa/Component/BannerSlider/Component */
export class BannerSlider extends SourceBannerSlider {
    state = {
        isHovered: false,
    };

    handleMouseEnter = () => {
        this.setState({ isHovered: true });
    };

    handleMouseLeave = () => {
        this.setState({ isHovered: false });
    };

    wrapWithLink = (wrap, content, link) =>
        wrap ? (
            <Link block="BannerSlider" elem="Link" to={link}>
                {content}
            </Link>
        ) : (
            content
        );

    renderSlide(
        {
            body,
            header,
            image,
            tablet_image: tabletImage,
            mobile_image: mobileImage,
            cta_text: ctaText,
            cta_link: ctaLink,
            cta_link_type: ctaLinkType,
            content_position: contentPosition,
            cta_color,
        },
        index
    ) {
        const { isHovered } = this.state;
        const color = cta_color || '#ffffff';
        const wrapWithLink = ctaLinkType === CTA_LINK_TYPE_BANNER;
        const isLight = isColorLight(cta_color);

        const btnColor = isLight ? '#000000' : color;

        const content = (
            <>
                {image && (
                    <picture>
                        {mobileImage ? <source media="(max-width: 767px)" srcSet={media(mobileImage)} /> : null}
                        {tabletImage ? <source media="(max-width: 1023px)" srcSet={media(tabletImage)} /> : null}
                        <img src={media(image)} alt={header} />
                    </picture>
                )}

                {(body || ctaText) && (
                    <div block="BannerSlider" elem="Content" mods={{ contentPosition: contentPosition || 'left' }}>
                        {body ? (
                            <TypographyHeader style={{ color }} variant="medium" tag="h2">
                                {body}
                            </TypographyHeader>
                        ) : null}
                        {ctaText ? (
                            <Button
                                asLink={!wrapWithLink}
                                variant="outlineWhite"
                                to={ctaLink}
                                onMouseLeave={this.handleMouseLeave}
                                onMouseEnter={this.handleMouseEnter}
                            >
                                <span style={{ color: isHovered && btnColor }}>{ctaText}</span>
                            </Button>
                        ) : null}
                    </div>
                )}
            </>
        );

        return <div key={`slider${index}`}>{this.wrapWithLink(wrapWithLink, content, ctaLink)}</div>;
    }

    renderSliderSlides({ slides }) {
        return slides.map(this.renderSlide.bind(this));
    }
}

export default BannerSlider;
