import { Field } from 'SourceUtil/Query';

const itemField = (args, callback, instance) => {
    const tags = new Field('tags').addFieldList(['title']);
    return callback.apply(instance, args).addField(tags);
};

export default {
    'Altom/Blog/Query/BlogPosts/Query/BlogPostsQuery': {
        'member-function': {
            itemField
        }
    }
};
