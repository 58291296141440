export const SLIDES_TO_SHOW_DESKTOP = 4;

export const DEFAULT_SLICK_SETTINGS = {
    lazyLoad: false,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 1023,
            settings: {
                arrows: false,
                slidesToShow: 2.5,
                swipeToSlide: false,
                lazyLoad: false,
                swipe: false,
                useCSS: false,
                useTransform: false,
                infinite: false,
            },
        },
        {
            breakpoint: 99999,
            settings: {
                swipeToSlide: true,
                slidesToShow: SLIDES_TO_SHOW_DESKTOP,
                slidesToScroll: SLIDES_TO_SHOW_DESKTOP,
            },
        },
    ],
};
