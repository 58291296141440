import { NewsletterSubscription as SourceNewsletterSubscription } from 'AdvoxComponent/NewsletterSubscription/NewsletterSubscription.component';
import Button from 'Component/Button';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import newsletterSubscriptionForm from 'Component/NewsletterSubscription/NewsletterForm.form';
import ReCaptcha from 'Component/ReCaptcha';

/** @namespace Pwa/Component/NewsletterSubscription/Component */
export class NewsletterSubscription extends SourceNewsletterSubscription {
    get fieldMap() {
        const { inPopup } = this.props;
        return newsletterSubscriptionForm(inPopup);
    }

    renderActions() {
        const { isLoading } = this.props;
        return (
            <Button type={FIELD_TYPE.submit} disabled={isLoading} aria-label={__('Submit')}>
                {__('Subscribe')}
            </Button>
        );
    }

    renderReCaptcha() {
        const { onReCaptchaChanged } = this.props;
        return <ReCaptcha onChangeValue={onReCaptchaChanged} />;
    }

    renderFormBody() {
        const { isLoading } = this.props;
        return (
            <>
                <div block="FieldForm" elem="Fieldset" mods={{ isLoading }}>
                    {super.renderFormBody()}
                </div>
                {this.renderReCaptcha()}
            </>
        );
    }
}

export default NewsletterSubscription;
