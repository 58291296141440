import { BannerSlider as SourceBannerSlider } from 'AdvoxComponent/BannerSlider/BannerSlider.component';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import media from 'Util/Media';

import { SLIDER_DESKTOP_HEIGHT, SLIDER_MOBILE_HEIGHT, SLIDER_TABLET_HEIGHT } from './BannerTiles.config';

import './BannerTiles.style.scss';

/** @namespace Pwa/Component/BannerTiles/Component */
export class BannerTiles extends SourceBannerSlider {
    wrapWithLink = (content, link) =>
        link ? (
            <Link block="BannerTiles" elem="Link" to={link}>
                {content}
            </Link>
        ) : (
            content
        );

    renderSlide({ header, image, tablet_image: tabletImage, mobile_image: mobileImage, cta_link: ctaLink }, index) {
        const content = (
            <>
                {image && (
                    <picture>
                        {mobileImage ? <source media="(max-width: 767px)" srcSet={media(mobileImage)} /> : null}
                        {tabletImage ? <source media="(max-width: 1023px)" srcSet={media(tabletImage)} /> : null}
                        <img src={media(image)} alt={header} />
                    </picture>
                )}
            </>
        );

        return (
            <div block="BannerTiles" elem="Tile" key={`slider${index}`}>
                {this.wrapWithLink(content, ctaLink)}
            </div>
        );
    }

    renderSliderSlides({ slides }) {
        return slides.map(this.renderSlide.bind(this));
    }

    render() {
        const { slider, isMobile, isTablet } = this.props;
        let loaderHeight = SLIDER_DESKTOP_HEIGHT;

        if (isMobile) {
            loaderHeight = SLIDER_MOBILE_HEIGHT;
        }

        if (isTablet) {
            loaderHeight = SLIDER_TABLET_HEIGHT;
        }

        if (!slider || !slider?.slides) {
            return <Loader displayRelative height={loaderHeight} />;
        }

        return (
            <div block="BannerTiles">
                <div block="BannerTiles" elem="Tiles">
                    {this.renderSliderSlides(slider)}
                </div>
            </div>
        );
    }
}

export default BannerTiles;
