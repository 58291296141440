import { CheckoutAddressTable as SourceCheckoutAddressTable } from 'AdvoxComponent/CheckoutAddressTable/CheckoutAddressTable.component';

import './CheckoutAddressTable.override.style';

/** @namespace Pwa/Component/CheckoutAddressTable/Component */
export class CheckoutAddressTable extends SourceCheckoutAddressTable {
    get dataPairArray() {
        const { address, showAdditionalFields } = this.props;

        const additionalFields = [
            {
                key: 'postcode',
                label: __('Postal code'),
                source: address,
            },
            {
                key: 'telephone',
                label: __('No. telephone'),
                source: address,
            },
        ];

        return [
            {
                key: 'firstname',
                label: __('First name'),
                source: address,
            },
            {
                key: 'lastname',
                label: __('Last name'),
                source: address,
            },
            {
                key: 'street',
                label: __('Street'),
                source: address,
            },
            ...(showAdditionalFields ? additionalFields : []),
        ];
    }
}

export default CheckoutAddressTable;
