import { connect } from 'react-redux';

import {
    CheckoutPaymentsContainer as SourceCheckoutPaymentsContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'AdvoxComponent/CheckoutPayments/CheckoutPayments.container';

export { mapDispatchToProps };

/** @namespace Pwa/Component/CheckoutPayments/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    advoxExtraFeeEnabled: state.ConfigReducer.advox_extra_fee_enabled,
    advoxExtraFeeValue: state.ConfigReducer.advox_extra_fee_value,
    advoxExtraFeeTaxEnabled: state.ConfigReducer.advox_extra_fee_tax_enabled,
    advoxExtraFeeValueIncludingTax: state.ConfigReducer.advox_extra_fee_value_including_tax,
    currencyCode: state.ConfigReducer.currencyData.current_currency_code,
});

/** @namespace Pwa/Component/CheckoutPayments/Container */
export class CheckoutPaymentsContainer extends SourceCheckoutPaymentsContainer {
    __construct(props) {
        super.__construct(props);
    }

    componentDidMount() {
        const { paymentMethods, setSelectedPaymentMethod } = this.props;
        const [{ code } = {}] = paymentMethods;

        setSelectedPaymentMethod(code);
    }

    containerProps() {
        const {
            selectedPaymentMethod,
            advoxExtraFeeEnabled,
            advoxExtraFeeValue,
            advoxExtraFeeTaxEnabled,
            advoxExtraFeeValueIncludingTax,
            currencyCode,
        } = this.props;

        return {
            ...super.containerProps(),
            selectedPaymentCode: selectedPaymentMethod,
            advoxExtraFeeEnabled,
            advoxExtraFeeValue,
            advoxExtraFeeTaxEnabled,
            advoxExtraFeeValueIncludingTax,
            currencyCode,
        };
    }

    collectAdditionalData() {
        const { selectedPaymentMethod } = this.props;
        const additionalDataGetter = this.dataMap[selectedPaymentMethod];

        if (!additionalDataGetter) {
            return {};
        }

        return additionalDataGetter();
    }

    selectPaymentMethod({ code }) {
        const { onPaymentMethodSelect, setOrderButtonEnableStatus } = this.props;
        onPaymentMethodSelect(code);
        setOrderButtonEnableStatus(true);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentsContainer);
