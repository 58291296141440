import SlickSlider from 'react-slick';

import { CatalogWidget as SourceCatalogWidget } from 'AdvoxComponent/CatalogWidget/CatalogWidget.component';
import { DEFAULT_CATALOG_WIDGET_SLICK_SETTINGS } from 'Component/CatalogWidget/CatalogWidget.config';
import Loader from 'Component/Loader';
import ProductCard from 'Component/ProductCard';
import SliderNextArrow from 'Component/SliderNextArrow';
import SliderPrevArrow from 'Component/SliderPrevArrow';

import {
    SECONDARY_CATALOG_WIDGET_SLICK_SETTINGS,
    SECONDARY_SLIDES_TO_SHOW_DESKTOP,
    SLIDES_TO_SHOW_DESKTOP,
    VARIANT_SECONDARY,
} from './CatalogWidget.config';

import './CatalogWidget.style';

/** @namespace Pwa/Component/CatalogWidget/Component */
export class CatalogWidget extends SourceCatalogWidget {
    renderProductCard(item, i, variant) {
        return (
            <div block="CatalogWidget" elem="Item" key={i}>
                <ProductCard product={item} sliderMode variant={variant} />
            </div>
        );
    }

    render() {
        const { isInspirationGrid, isLoading, items, variant } = this.props;

        if (isLoading) {
            return (
                <div block="CatalogWidget" elem="Loader">
                    <Loader />
                </div>
            );
        }

        if (!items || !items?.length) {
            return (
                <div block="CatalogWidget" elem="NoProducts">
                    {__('No products')}
                </div>
            );
        }

        if (isInspirationGrid) {
            return (
                <div block="CatalogWidget" mods={{ isInspirationGrid: true }}>
                    {items.map(this.renderProductCard)}
                </div>
            );
        }

        if (variant === VARIANT_SECONDARY) {
            return (
                <div block="CatalogWidget" mods={{ secondary: true }}>
                    <SlickSlider
                        prevArrow={<SliderPrevArrow variant="secondary" />}
                        nextArrow={<SliderNextArrow variant="secondary" />}
                        {...SECONDARY_CATALOG_WIDGET_SLICK_SETTINGS}
                        {...(items.length < SECONDARY_SLIDES_TO_SHOW_DESKTOP ? { infinite: false } : {})}
                    >
                        {items.map((item, i) => this.renderProductCard(item, i, 'secondary'))}
                    </SlickSlider>
                </div>
            );
        }

        return (
            <div block="CatalogWidget">
                <SlickSlider
                    prevArrow={<SliderPrevArrow />}
                    nextArrow={<SliderNextArrow />}
                    {...DEFAULT_CATALOG_WIDGET_SLICK_SETTINGS}
                    {...(items.length < SLIDES_TO_SHOW_DESKTOP ? { infinite: false } : {})}
                >
                    {items.map(this.renderProductCard)}
                </SlickSlider>
            </div>
        );
    }
}

export default CatalogWidget;
