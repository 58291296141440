import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    mapDispatchToProps,
    mapStateToProps as srouceMapStateToProps,
    NewsletterSubscriptionContainer as SourceNewsletterSubscriptionContainer,
} from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.container';

/** @namespace Pwa/Component/NewsletterSubscription/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...srouceMapStateToProps(state),
    isReCaptchaEnabled: state.ConfigReducer.g_recaptcha_recaptcha_enabled,
});

export { mapDispatchToProps };

/** @namespace Pwa/Component/NewsletterSubscription/Container */
export class NewsletterSubscriptionContainer extends SourceNewsletterSubscriptionContainer {
    static propTypes = {
        ...SourceNewsletterSubscriptionContainer.propTypes,
        inPoup: PropTypes.bool,
        onSuccess: PropTypes.func,
    };

    static defaultProps = {
        ...SourceNewsletterSubscriptionContainer.defaultProps,
        inPopup: false,
        onSuccess: () => {},
    };

    containerFunctions = {
        ...this.containerFunctions,
        onReCaptchaChanged: this.onReCaptchaChanged.bind(this),
    };

    state = {
        ...this.state,
        isReCaptcha: false,
    };

    onReCaptchaChanged() {
        this.setState({ isReCaptcha: true, isLoading: false });
    }

    onFormSubmit(form, fields) {
        const { isReCaptchaEnabled, subscribeToNewsletter, allowGuestSubscribe, isSignedIn, showErrorNotification } =
            this.props;
        const { isReCaptcha } = this.state;
        const { value: email } = fields.find(({ name }) => name === 'newsletterEmail') || {};
        if (!allowGuestSubscribe && !isSignedIn) {
            showErrorNotification(
                __('Guests can not subscribe to the newsletter. You must create an account or login to subscribe.')
            );

            return;
        }

        this.setState({ isLoading: true });

        // check for reCaptcha
        if (+isReCaptchaEnabled === 0) {
            return subscribeToNewsletter(email).then(this.onFormSubmitDone).catch(this.onFormSubmitDone);
        }

        if (+isReCaptchaEnabled === 1 && !isReCaptcha) {
            return showErrorNotification(__('Please confirm that you are not a robot'));
        }

        subscribeToNewsletter(email).then(this.onFormSubmitDone).catch(this.onFormSubmitDone);
    }

    onFormSubmitDone() {
        const { onSuccess } = this.props;
        this.setState({ isLoading: false });

        onSuccess();
    }

    containerProps() {
        const { inPopup } = this.props;

        return { ...super.containerProps(), inPopup };
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewsletterSubscriptionContainer));
