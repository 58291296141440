import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MyAccountAddressFormContainer as SourceMyAccountAddressFormContainer,
} from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.container';

export * from 'SourceComponent/MyAccountAddressForm/MyAccountAddressForm.container';

/** @namespace Pwa/Component/MyAccountAddressForm/Container */
export class MyAccountAddressFormContainer extends SourceMyAccountAddressFormContainer {
    getCurrentAddress() {
        const {
            address,
            address: { id: addressId },
        } = this.props;

        if (!addressId) {
            return {
                region: '',
                regionId: 1,
                zipCode: '',
                city: '',
            };
        }

        const { region: selectedRegion, postcode, city } = address;
        const { region, region_id: regionId } = selectedRegion || {};

        return {
            region,
            regionId,
            postcode,
            city,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountAddressFormContainer);
