/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import getStore from 'SourceUtil/Store';

import { waitForCallback } from '../util/wait';
import { getProductData } from './product';

/** @namespace Scandiweb/GoogleTagManager/Data/Cart/getCartItemData */
export const getCartItemData = async (item) => {
    const {
        qty: quantity,
        price_incl_tax: price,
        product
    } = item;

    return {
        ...getProductData(product),
        price,
        quantity
    };
};

/** @namespace Scandiweb/GoogleTagManager/Data/Cart/getCartItemsData */
export const getCartItemsData = async () => {
    // vvv Wait for cart id
    await waitForCallback(() => getStore().getState().CartReducer?.cartTotals?.id);

    const {
        cartTotals: {
            items = [],
            quote_currency_code: currencyCode,
            subtotal_incl_tax
        }
    } = getStore().getState().CartReducer;

    if (items.length === 0) {
        return [];
    }

    return {
        currency: currencyCode,
        value: subtotal_incl_tax,
        items: await Promise.all(items.map(getCartItemData)),
        _clear: true
    };
};

/** @namespace Scandiweb/GoogleTagManager/Data/Cart/getRemoveFromCartData */
export const getRemoveFromCartData = async (item) => ({
    ecommerce: {
        items: [await getCartItemData(item)],
        _clear: true
    }
});

/** @namespace Scandiweb/GoogleTagManager/Data/Cart/getAddToCartData */
export const getAddToCartData = async (item, currencyCode) => ({
    ecommerce: {
        items: [await getCartItemData(item)],
        value: item.price_incl_tax * item.qty,
        currency: currencyCode,
        _clear: true
    }
});
