import { ProductWishlistButton as SourceProductWishlistButton } from 'AdvoxComponent/ProductWishlistButton/ProductWishlistButton.component';
import NotificationList from 'Component/NotificationList';
import { getNotificationPosition } from 'Util/Notification/Options';

/** @namespace Pwa/Component/ProductWishlistButton/Component */
export class ProductWishlistButton extends SourceProductWishlistButton {
    renderNotifications() {
        const { magentoProduct: [{ productId }] = [] } = this.props;

        if (!productId) {
            return null;
        }

        return <NotificationList position={getNotificationPosition('ProductWishlistButton', productId)} />;
    }

    renderContent() {
        return (
            <div block="ProductWishlistButton">
                {this.renderButton()}
                {this.renderLoader()}
                {this.renderNotifications()}
            </div>
        );
    }
}

export default ProductWishlistButton;
