import { Field } from 'Util/Query';

/** @namespace Pwa/Query/CompanyData/Query */
export class CompanyDataQuery {
    getCompanyDataByNip(nip) {
        return new Field('gusDataByNip').addArgument('nip', 'String!', nip).addFieldList(this._getFieldList());
    }

    _getFieldList() {
        return ['apartmentNumber', 'city', 'name', 'nip', 'propertyNumber', 'street', 'zipCode'];
    }
}

export default new CompanyDataQuery();
