import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { MyAccount, Router as AdvoxRouter, withStoreRegex } from 'AdvoxComponent/Router/Router.component';
import Delayed from 'Component/Delayed';
import { LOYALTY_PROGRAM, RMA_ORDERS, RMA_STATUSES } from 'Type/Account.type';

import {
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TO_REMOVE,
    BEFORE_ITEMS_TYPE,
    MY_ACCOUNT_LOYALTY_PROGRAM,
    MY_ACCOUNT_RMA_ORDERS,
    NEW_VERSION_POPUP,
    PAYMENT_THANKYOU,
    RMA,
    SHARED_WISHLIST,
    SWITCH_ITEMS_TYPE,
    SWITCH_ITEMS_TYPE_TO_REMOVE,
} from './Router.config';

export const NewVersionPopup = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/NewVersionPopup')
);
export const RmaPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "rma" */ 'Route/RmaPage'));
export const CheckoutThankyouPage = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "payment-thankyou" */ 'Route/CheckoutThankyouPage')
);
export const AdvoxPopupComponent = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/AdvoxPopup')
);

export const WishlistShared = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/WishlistSharedPage')
);

export const HeadIncludes = lazy(() =>
    import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Component/HeadIncludes')
);

export * from 'AdvoxComponent/Router/Router.component';

/** @namespace Pwa/Component/Router/Component */
export class Router extends AdvoxRouter {
    [SWITCH_ITEMS_TYPE] = [
        ...(this[SWITCH_ITEMS_TYPE].filter(({ name }) => !SWITCH_ITEMS_TYPE_TO_REMOVE.includes(name)) || []),
        {
            component: (
                <Route
                    path={withStoreRegex('/loyalty-program')}
                    render={(props) => <MyAccount {...props} selectedTab={LOYALTY_PROGRAM} />}
                />
            ),
            position: 76,
            name: MY_ACCOUNT_LOYALTY_PROGRAM,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/rma/orders/:orderNumber?')}
                    render={(props) => <MyAccount {...props} selectedTab={RMA_ORDERS} />}
                />
            ),
            position: 76,
            name: MY_ACCOUNT_RMA_ORDERS,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/sales/rma/statuses/:requestNumber?')}
                    render={(props) => <MyAccount {...props} selectedTab={RMA_STATUSES} />}
                />
            ),
            position: 77,
            name: MY_ACCOUNT_RMA_ORDERS,
        },
        {
            component: <Route path={withStoreRegex('/rma/:step?')} render={(props) => <RmaPage {...props} />} />,
            position: 76,
            name: RMA,
        },

        {
            component: (
                <Route
                    path={withStoreRegex('/wishlist/shared/:code?')}
                    render={(props) => <WishlistShared {...props} />}
                />
            ),
            position: 21,
            name: SHARED_WISHLIST,
        },
        {
            component: (
                <Route
                    path={withStoreRegex('/paymentthankyoupage')}
                    render={(props) => <CheckoutThankyouPage {...props} />}
                />
            ),
            position: 78,
            name: PAYMENT_THANKYOU,
        },
    ];

    [BEFORE_ITEMS_TYPE] = [
        ...(this[BEFORE_ITEMS_TYPE].filter(({ name }) => !BEFORE_ITEMS_TO_REMOVE.includes(name)) || []),
        {
            component: (
                <Delayed waitBeforeShow={500}>
                    <NewVersionPopup />
                </Delayed>
            ),
            position: 35,
            name: NEW_VERSION_POPUP,
        },
    ];

    [AFTER_ITEMS_TYPE] = [
        ...this[AFTER_ITEMS_TYPE],
        {
            component: (
                <Delayed waitBeforeShow={500}>
                    <AdvoxPopupComponent />
                </Delayed>
            ),
            position: 30,
            name: 'ADVOX_POPUP',
        },
        {
            component: <HeadIncludes />,
            position: 40,
            name: 'HEAD_INCLUDES',
        },
    ];
}

export default Router;
