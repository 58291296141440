import { PureComponent } from 'react';

import Icons from 'Component/Icons';

import './StaticPopup.style';

/** @namespace Pwa/Component/StaticPopup/Component */
export class StaticPopup extends PureComponent {
    render() {
        const { content, isVisible, closePopup } = this.props;

        return (
            <div block="StaticPopup" mods={{ isVisible }}>
                <div block="StaticPopup" elem="Content">
                    {content}
                </div>
                <div block="StaticPopup" elem="Close" onClick={closePopup}>
                    <Icons name="close_thin" width="22" height="22" />
                </div>
            </div>
        );
    }
}

export default StaticPopup;
