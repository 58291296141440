/** @namespace Pwa/Component/WPEvents/Util/runWPAddToCartPixel */
export const runWPAddToCartPixel = (product, quantity) => {
    const { wph } = window;

    const pixelContent = {
        id: `${product?.id}`,
        name: product?.name,
        price: Number(product?.price_range?.minimum_price?.final_price?.value?.toFixed(2)),
        quantity,
        category: product?.categories[product?.categories.length - 1]?.name,
        in_stock: product?.stock_item?.in_stock,
    };

    if (wph) {
        wph('track', 'AddToCart', {
            contents: [pixelContent],
        });
    }
};

/** @namespace Pwa/Component/WPEvents/Util/runWPConsentPixel */
export const runWPConsentPixel = () => {
    const { wph } = window;

    if (wph) {
        wph('consent', 'grant');
    }
};
