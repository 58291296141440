import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { LocationType } from 'Type/Router.type';

import ShowRewardPoints from './ShowRewardPoints.component';

export const RewardsPointsDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/RewardsPoints/RewardsPoints.dispatcher'
);

/** @namespace Pwa/Component/ShowRewardPoints/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isSignedIn: state.MyAccountReducer.isSignedIn,
    earnPoints: state.RewardsPointsReducer.earnPoints,
    balance: state.RewardsPointsReducer.balance,
    moneyBalance: state.RewardsPointsReducer.moneyBalance,
    totals: state.CartReducer.cartTotals,
});

/** @namespace Pwa/Component/ShowRewardPoints/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    reInitEarnedPoints: () =>
        RewardsPointsDispatcher.then(({ default: dispatcher }) => dispatcher.initialize(dispatch)),
});

/** @namespace Pwa/Component/ShowRewardPoints/Container */
export class ShowRewardPointsContainer extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        location: LocationType,
        reInitEarnedPoints: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { isSignedIn, reInitEarnedPoints } = this.props;

        if (isSignedIn) reInitEarnedPoints();
    }

    componentDidUpdate(prevProps) {
        const {
            reInitEarnedPoints,
            totals: { items_qty },
            isSignedIn,
        } = this.props;
        const {
            totals: { items_qty: prevQty },
            isSignedIn: prevIsSignedIn,
        } = prevProps;

        if ((items_qty !== prevQty || isSignedIn !== prevIsSignedIn) && isSignedIn) {
            reInitEarnedPoints();
        }
    }

    containerProps() {
        return {
            ...this.props,
            ...this.state,
        };
    }

    render() {
        return <ShowRewardPoints {...this.containerProps()} />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShowRewardPointsContainer));
