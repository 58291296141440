import { withRouter } from 'react-router-dom';

import { ProductCard as SourceProductCard } from 'AdvoxComponent/ProductCard/ProductCard.component';
import AddToCart from 'Component/AddToCart';
import Image from 'Component/Image';
import ImageLazyLoading from 'Component/ImageLazyLoading';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import LowestPriceIn30Days from 'Component/LowestPriceIn30Days';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import ProductConfigurableAttributes from 'Component/ProductConfigurableAttributes';
import RelatedProductsByAttribute from 'Component/RelatedProductsByAttribute';
import TypographyHeader from 'Component/TypographyHeader';
import { GRID_LAYOUT, LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { filterConfigurableOptions } from 'Util/Product';

import productPlaceholder from '../../assets/images/productPlaceholder.png';
/** @namespace Pwa/Component/ProductCard/Component */
export class ProductCard extends SourceProductCard {
    state = {
        relatedImage: null,
    };

    renderDeliveryTime() {
        const { shipping_time_enabled, shipping_time_shipping_time } = this.props;

        return shipping_time_enabled === '1' && shipping_time_shipping_time ? (
            <div block="ProductCard" elem="DeliveryTime">
                {__('Delivery time')}: <span>{shipping_time_shipping_time}</span>
            </div>
        ) : null;
    }

    requiresConfiguration() {
        const {
            product: { type_id: type, options = [], links_purchased_separately },
        } = this.props;

        const configureGrouped = type === PRODUCT_TYPE.grouped;
        const configureCustomize = options.some(({ required = false }) => required);
        const configureDownloadableLinks = PRODUCT_TYPE.downloadable && links_purchased_separately === 1;

        return configureGrouped || configureCustomize || configureDownloadableLinks;
    }

    renderAddToCart() {
        const { layout, showSelectOptionsNotification, inStock } = this.props;

        if (inStock && this.requiresConfiguration()) {
            return this.renderCardLinkWrapper(
                <button block="Button AddToCart" mods={{ layout }} onClick={showSelectOptionsNotification}>
                    {__('Add to cart')}
                </button>
            );
        }

        return this.renderAddToCartButton(layout);
    }

    renderAddToCartButton(layout = GRID_LAYOUT) {
        const { addToCart, inStock, quantity, getActiveProduct, getSelectedProduct } = this.props;

        return (
            <AddToCart
                mix={{ block: this.className, elem: 'AddToCart' }}
                addToCart={addToCart}
                isDisabled={!inStock}
                isIconEnabled={false}
                layout={layout}
                quantity={quantity}
                product={getActiveProduct()}
                selectedProduct={getSelectedProduct()}
            />
        );
    }

    renderLowestPriceIn30Days() {
        const {
            product: { lowest_price },
        } = this.props;

        return lowest_price && <LowestPriceIn30Days lowestPrice={lowest_price} />;
    }

    renderPicture(mix = {}) {
        const {
            product: { id, name },
            thumbnail,
            sliderMode,
        } = this.props;

        this.sharedComponent = (
            <Image
                src={thumbnail}
                alt={name}
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture', mix }}
                isPlaceholder={!id}
                useNativeLazyLoading={sliderMode}
                lazyLoadingType="productTile"
            />
        );

        return thumbnail ? (
            <>
                {this.sharedComponent}
                <ImageLazyLoading
                    style={{ display: 'none' }}
                    alt={name}
                    src={thumbnail}
                    lazyLoadingType="productTile"
                />
            </>
        ) : (
            <img src={productPlaceholder} alt={name} />
        );
    }

    renderCardLinkWrapper(children, mix = {}) {
        const {
            linkTo,
            product: { url },
        } = this.props;

        if (!url) {
            return (
                <div block="ProductCard" elem="Link">
                    {children}
                </div>
            );
        }

        return (
            <Link block="ProductCard" elem="Link" to={linkTo} mix={mix}>
                {children}
            </Link>
        );
    }

    renderCardContent() {
        const { renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <>
                {this.renderDiscountLabel()}
                <div block="ProductCard" elem="LabelsWithActions">
                    <div block="ProductCard" elem="Labels">
                        {this.renderLabels()}
                    </div>
                </div>
                {this.renderCardLinkWrapper(
                    <>
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                {this.renderPicture()}
                            </figure>
                            <figure block="ProductCard" elem="FigureAlternative">
                                {this.renderAlternativePicture()}
                            </figure>
                            <figure block="ProductCard" elem="FigureRelatedProduct">
                                {this.renderRelatedProduct()}
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content">
                            <div block="ProductCard" elem="Price">
                                {this.renderPrice()}
                            </div>
                            {this.renderName()}
                            {this.renderLowestPriceIn30Days()}
                        </div>
                    </>
                )}
                <div block="ProductCard" elem="VisibleOnHover">
                    {this.renderVisibleOnHover()}
                </div>
            </>
        );
    }

    renderCardListContent() {
        const { children, layout, renderContent } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <>
                {this.renderCardLinkWrapper(
                    <>
                        <div block="ProductCard" elem="Labels">
                            {this.renderLabels()}
                            {this.renderDiscountLabel()}
                        </div>
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                {this.renderPicture()}
                            </figure>
                            <figure block="ProductCard" elem="FigureAlternative">
                                {this.renderAlternativePicture()}
                            </figure>
                            <figure block="ProductCard" elem="FigureRelatedProduct">
                                {this.renderRelatedProduct()}
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content" mods={{ layout }}>
                            <div block="ProductCard" elem="MainInfo">
                                {this.renderMainDetails()}
                                {this.renderBrand()}
                            </div>
                            <div block="ProductCard" elem="ActionWrapper">
                                {this.renderPrice()}
                                {this.renderAddToCartField()}
                            </div>
                            <div block="ProductCard" elem="AdditionalContent">
                                {children}
                            </div>
                        </div>
                    </>
                )}
                <div block="ProductCard" elem="VisibleOnHover">
                    {this.renderVisibleOnHover(true)}
                </div>
            </>
        );
    }

    getConfigurableAttributes() {
        const {
            product: { configurable_options: configurableOptions = {}, variants = {} },
        } = this.props;

        return filterConfigurableOptions(configurableOptions, variants);
    }

    renderConfigurableOptions() {
        const {
            setActiveProduct,
            parameters,
            product: { type_id: type, variants = {} },
            inStock,
            addToCartTriggeredWithError,
            updateAddToCartTriggeredWithError,
        } = this.props;

        if (type !== PRODUCT_TYPE.configurable) {
            return null;
        }

        return (
            <div block="ProductActions" elem="AttributesWrapper">
                <ProductConfigurableAttributes
                    // eslint-disable-next-line no-magic-numbers
                    numberOfPlaceholders={[2, 4]}
                    updateAddToCartTriggeredWithError={updateAddToCartTriggeredWithError}
                    addToCartTriggeredWithError={addToCartTriggeredWithError}
                    mix={{ block: this.className, elem: 'Attributes' }}
                    parameters={parameters}
                    variants={variants}
                    updateConfigurableVariant={setActiveProduct}
                    configurable_options={this.getConfigurableAttributes()}
                    isContentExpanded
                    inStock={inStock}
                    showProductAttributeAsLink={false}
                />
            </div>
        );
    }

    renderVisibleOnHover(removeAddToCard = false) {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }

        return (
            <>
                {this.renderDeliveryTime()}
                {this.renderRelatedProductsByAttribute()}
                {this.renderConfigurableOptions()}
                {!removeAddToCard && this.renderAddToCartField()}
            </>
        );
    }

    renderAddToCartField() {
        const { inStock } = this.props;
        if (!inStock) return this.renderAddToCart();

        return (
            <div
                block="ProductCard"
                elem="AddToCartWrapper"
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                {this.renderQuantityChanger()}
                {this.renderAddToCart()}
            </div>
        );
    }

    renderRelatedProductsByAttribute() {
        const {
            product: { related_products_by_attribute, related_products_by_attribute_label },
        } = this.props;

        return (
            <RelatedProductsByAttribute
                products={related_products_by_attribute}
                variant="secondary"
                onMouseEnter={(image) => {
                    this.setState({
                        relatedImage: image,
                    });
                }}
                onMouseLeave={() => {
                    this.setState({
                        relatedImage: null,
                    });
                }}
                label={related_products_by_attribute_label}
            />
        );
    }

    renderAlternativePicture() {
        const {
            product: { id, name, alternative_hover_image },
        } = this.props;

        return alternative_hover_image ? (
            <Image
                src={alternative_hover_image}
                alt={`${name} 1`}
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture' }}
                isPlaceholder={!id}
                useNativeLazyLoading={false}
                lazyLoadingType="productTile"
            />
        ) : null;
    }

    renderRelatedProduct() {
        const { relatedImage } = this.state;
        const {
            product: { name },
        } = this.props;

        return relatedImage ? (
            <Image
                src={relatedImage}
                alt={`${name} 2`}
                ratio="custom"
                mix={{ block: 'ProductCard', elem: 'Picture' }}
                isPlaceholder={false}
            />
        ) : null;
    }

    renderName() {
        const {
            product: { name },
        } = this.props;

        return (
            <TypographyHeader block="ProductCard" elem="Name" mods={{ isLoaded: !!name }} variant="normal" tag="p">
                {name}
            </TypographyHeader>
        );
    }

    render() {
        const { children, mix, isLoading, layout, sliderMode, variant } = this.props;

        if (layout === LIST_LAYOUT) {
            return (
                <li block="ProductCard" mods={{ layout, variant }} mix={mix}>
                    <Loader isLoading={isLoading} />
                    {this.renderCardListContent()}
                </li>
            );
        }

        return (
            <li block="ProductCard" mods={{ layout, isSlider: sliderMode, variant }} mix={mix}>
                <Loader isLoading={isLoading} />
                {this.renderCardContent()}
                <div block="ProductCard" elem="AdditionalContent">
                    {children}
                </div>
            </li>
        );
    }
}

export default withRouter(ProductCard);
