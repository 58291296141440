export const UPDATED_PRODUCT_RELATED = 'UPDATED_PRODUCT_RELATED';
export const CLEAR_PRODUCT_RELATED = 'CLEAR_PRODUCT_RELATED';
export const REMOVE_PRODUCT_RELATED = 'REMOVE_PRODUCT_RELATED';

/** @namespace Pwa/Store/ProductRelatedAccessories/Action/addProductToList */
export const addProductToList = (payload) => ({
    type: UPDATED_PRODUCT_RELATED,
    payload,
});

/** @namespace Pwa/Store/ProductRelatedAccessories/Action/removeProductFromList */
export const removeProductFromList = (payload) => ({
    type: REMOVE_PRODUCT_RELATED,
    payload,
});

/** @namespace Pwa/Store/ProductRelatedAccessories/Action/clearRelatedProductList */
export const clearRelatedProductList = () => ({
    type: CLEAR_PRODUCT_RELATED,
});
