import { PureComponent } from 'react';
import { connect } from 'react-redux';

import LowestPriceIn30Days from './LowestPriceIn30Days.component';

/** @namespace Pwa/Component/LowestPriceIn30Days/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    currencyCode: state.ConfigReducer.currencyData.current_currency_code,
    omnibusEnableFront: state.ConfigReducer.omnibus_enable_front,
});

/** @namespace Pwa/Component/LowestPriceIn30Days/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/LowestPriceIn30Days/Container */
export class LowestPriceIn30DaysContainer extends PureComponent {
    containerProps() {
        const { omnibusEnableFront, currencyCode, lowestPrice } = this.props;
        return {
            currencyCode,
            lowestPrice,
            omnibusEnableFront,
        };
    }

    render() {
        return <LowestPriceIn30Days {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LowestPriceIn30DaysContainer);
