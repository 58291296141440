import { PureComponent } from 'react';
import { connect } from 'react-redux';

import Html from './Html.component';

/** @namespace Pwa/Component/Html/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    bannerVisibleFrom: state.ConfigReducer.advox_banner_visible_from,
    bannerVisibleTo: state.ConfigReducer.advox_banner_visible_to,
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/Html/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/Html/Container */
export class HtmlContainer extends PureComponent {
    containerProps() {
        return {
            ...this.props,
        };
    }

    render() {
        return <Html {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HtmlContainer);
