export class CheckoutBillingContainerPlugin {
    onPaymentMethodSelect(args, callback, instance) {
        const { savePaymentInformationOnly, setSelectedPaymentMethod } = instance.props;
        const [code] = args;

        instance.setState({ paymentMethod: code });
        setSelectedPaymentMethod(code);
        savePaymentInformationOnly(code);
    }

    containerProps(args, callback, instance) {
        const { savePaymentInformationOnly } = instance.props;

        return {
            ...callback(),
            savePaymentInformationOnly,
        };
    }
}
const { onPaymentMethodSelect, containerProps } = new CheckoutBillingContainerPlugin();

export default {
    'Pwa/Component/CheckoutBilling/Container': {
        'member-function': {
            onPaymentMethodSelect,
            containerProps,
        },
    },
};
