Mosaic.setPlugins([require('./../node_modules/@scandipwa/webpack-i18n-runtime/src/plugin/App.plugin.js'),
require('./../packages/altom/@altom/gtm/src/plugin/config/ConfigDispatcher.plugin.js'),
require('./../packages/altom/@altom/gtm/src/plugin/config/ConfigQuery.plugin.js'),
require('./../packages/altom/@altom/gtm/src/plugin/config/ConfigReducer.plugin.js'),
require('./../packages/altom/@altom/gtm/src/plugin/config/MyAccountQuery.plugin.js'),
require('./../packages/altom/@altom/gtm/src/plugin/config/ProductListQuery.plugin.js'),
require('./../packages/altom/@altom/gtm/src/plugin/events/cart.plugin.js'),
require('./../packages/altom/@altom/gtm/src/plugin/events/checkout.plugin.js'),
require('./../packages/altom/@altom/gtm/src/plugin/events/general.plugin.js'),
require('./../packages/altom/@altom/gtm/src/plugin/events/list.plugin.js'),
require('./../packages/altom/@altom/gtm/src/plugin/events/notFound.plugin.js'),
require('./../packages/altom/@altom/gtm/src/plugin/events/product.plugin.js'),
require('./../packages/altom/@altom/gtm/src/plugin/events/search.plugin.js'),
require('./../packages/altom/@altom/gtm/src/plugin/events/user.plugin.js'),
require('./../packages/altom/@altom/mageplaza/src/plugin/ConfigDispatcher.plugin.js'),
require('./../packages/altom/@altom/mageplaza/src/plugin/ConfigReducer.plugin.js'),
require('./../packages/altom/@altom/mageplaza/src/plugin/MyAccountSignInComponent.plugin.js'),
require('./../packages/altom/@altom-advox/extrafee/src/plugin/CheckoutBillingComponent.plugin.js'),
require('./../packages/altom/@altom-advox/extrafee/src/plugin/CheckoutBillingContainer.plugin.js'),
require('./../packages/altom/@altom-advox/extrafee/src/plugin/CheckoutComponent.plugin.js'),
require('./../packages/altom/@altom-advox/extrafee/src/plugin/CheckoutContainer.plugin.js'),
require('./../packages/altom/@altom-advox/extrafee/src/plugin/CheckoutMapDispatchToProps.plugin.js'),
require('./../packages/altom/@altom-advox/extrafee/src/plugin/CheckoutPaymentsContainer.plugin.js'),
require('./../packages/altom/@altom-advox/blog/src/plugin/BlogCategoriesComponent.plugin.js'),
require('./../packages/altom/@altom-advox/blog/src/plugin/BlogPostCardComponent.plugin.js'),
require('./../packages/altom/@altom-advox/blog/src/plugin/BlogPostQuery.plugin.js'),
require('./../packages/altom/@altom-advox/blog/src/plugin/BlogPostsQuery.plugin.js'),
require('./../packages/altom/@altom-advox/blog/src/plugin/BlogRecentPostsComponent.plugin.js'),
require('./../packages/altom/@altom-advox/blog/src/plugin/BlogRelatedProductsComponent.plugin.js'),
require('./../packages/altom/@altom-advox/blog/src/plugin/BlogSearchBarComponent.plugin.js'),
require('./../packages/altom/@altom-advox/blog/src/plugin/BlogSearchBarContainer.plugin.js'),
require('./../packages/altom/@altom-advox/blog/src/plugin/BlogSearchOverlayComponent.plugin.js'),
require('./../packages/altom/@altom-advox/blog/src/plugin/PostsDetailsComponent.plugin.js'),
require('./../packages/altom/@altom-advox/blog/src/plugin/PostsDetailsContainer.plugin.js'),
require('./../packages/altom/@altom-advox/blog/src/plugin/PostsListingComponent.plugin.js'),
require('./../packages/altom/@altom-advox/blog/src/plugin/PostsListingContainer.plugin.js'),
require('./../packages/altom/@altom/extrafee/src/plugin/CartPageComponent.plugin.js'),
require('./../packages/altom/@altom/extrafee/src/plugin/CheckoutOrderSummaryPriceLineComponent.plugin.js'),
require('./../packages/altom/@altom/extrafee/src/plugin/StoreReducer.plugin.js'),
require('./../packages/altom/@altom/blog/src/plugin/ConfigQuery.plugin.js'),
require('./../packages/altom/@altom/blog/src/plugin/HeaderComponent.plugin.js'),
require('./../packages/altom/@altom/blog/src/plugin/Image.plugin.js'),
require('./../packages/altom/@altom/blog/src/plugin/Router.plugin.js'),
require('./../packages/altom/@altom/blog/src/plugin/StoreIndex.plugin.js')]);
import { render } from 'react-dom';

import App from 'Component/App';
import { subscribeToReloadPageAfterOrder } from 'Util/BroadcastChannel';

import 'Util/Polyfill';
import 'intersection-observer/intersection-observer';
import 'Util/Sentry';
import 'Style/main';

subscribeToReloadPageAfterOrder();

// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker.register(swUrl, { scope: '/' });
    });
}

render(<App />, document.getElementById('root'));
