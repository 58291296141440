import { CheckoutAddressForm as SourceCheckoutAddressForm } from 'AdvoxComponent/CheckoutAddressForm/CheckoutAddressForm.component';
import { checkoutAccountAddressForm } from 'Component/CheckoutAddressForm/CheckoutAddressForm.form';

import './CheckoutAddressForm.override.style';

/** @namespace Pwa/Component/CheckoutAddressForm/Component */
export class CheckoutAddressForm extends SourceCheckoutAddressForm {
    get fieldMap() {
        const {
            onAddressChange,
            props: {
                id: step,
                address,
                countries,
                addressLinesQty,
                regionDisplayAll,
                showVatNumber,
                defaultCountry,
                availableRegions,
                isStateRequired,
                countryId,
                currentRegion,
                currentCity,
                currentRegionId,
                currentZipcode,
                onCountryChange,
                onZipcodeChange,
                onCityChange,
                onRegionChange,
                onRegionIdChange,
                onVatIdBlur,
            } = {},
        } = this;

        return checkoutAccountAddressForm(
            {
                address,
                countries,
                addressLinesQty,
                regionDisplayAll,
                showVatNumber,
                defaultCountry,
                availableRegions,
                isStateRequired,
                countryId,
                currentRegion,
                currentCity,
                currentRegionId,
                currentZipcode,
                ...address,
            },
            {
                onCountryChange,
                onZipcodeChange,
                onCityChange,
                onRegionChange,
                onRegionIdChange,
                onAddressChange,
                onVatIdBlur,
            },
            step
        );
    }

    render() {
        const { id: step } = this.props;

        return (
            <div block="CheckoutAddressForm" mods={{ step }}>
                {this.renderFormBody()}
            </div>
        );
    }
}

export default CheckoutAddressForm;
