import { CheckoutAddressBook as SourceCheckoutAddressBook } from 'AdvoxComponent/CheckoutAddressBook/CheckoutAddressBook.component';
import CheckoutAddressForm from 'Component/CheckoutAddressForm';
import CheckoutAddressTable from 'Component/CheckoutAddressTable';
import ExpandableContent from 'Component/ExpandableContent';
import { BILLING_STEP, SHIPPING_STEP } from 'Route/Checkout/Checkout.config';
import { getDefaultAddressLabel } from 'Util/Address';

import { MAX_ITEMS_VISIBLE } from './CheckoutAddressBook.config';

import './CheckoutAddressBook.override.style';

/** @namespace Pwa/Component/CheckoutAddressBook/Component */
export class CheckoutAddressBook extends SourceCheckoutAddressBook {
    state = {
        isCustomAddressExpanded: false,
        isAddressesListExpanded: false,
    };

    expandOrCollapseAddressList() {
        const { isAddressesListExpanded } = this.state;

        this.setState({ isAddressesListExpanded: !isAddressesListExpanded });
    }

    renderHeading() {
        const { isBilling } = this.props;

        if (isBilling) {
            return null;
        }

        return (
            <h2 block="Checkout" elem="SubHeading">
                {__('New address')}
            </h2>
        );
    }

    renderOptionalCustomAddress() {
        const { isCustomAddressExpanded } = this.state;

        return (
            <div block="CheckoutAddressBook" elem="CustomAddressWrapper">
                {!isCustomAddressExpanded && (
                    <button
                        block="Button"
                        mods={{ isCustomAddressExpanded }}
                        type="button"
                        onClick={this.expandCustomAddress}
                    >
                        {__('Add a new address')}
                    </button>
                )}
                {isCustomAddressExpanded && [this.renderHeading(), this.renderCustomAddress()]}
            </div>
        );
    }

    renderCustomAddress() {
        const { isBilling, onShippingEstimationFieldsChange, isSubmitted, shippingAddress } = this.props;
        const formPortalId = isBilling ? BILLING_STEP : SHIPPING_STEP;
        const address = shippingAddress ?? {};

        return (
            <CheckoutAddressForm
                onShippingEstimationFieldsChange={onShippingEstimationFieldsChange}
                address={address}
                id={formPortalId}
                isSubmitted={isSubmitted}
            />
        );
    }

    renderAddressList() {
        const {
            customer: { addresses },
        } = this.props;
        const { isAddressesListExpanded } = this.state;

        if (!addresses) {
            return this.renderLoading();
        }

        if (!addresses.length) {
            return this.renderNoAddresses();
        }

        if (addresses <= MAX_ITEMS_VISIBLE) {
            return addresses.map(this.renderAddress);
        }

        const firstAddresses = addresses.slice(0, MAX_ITEMS_VISIBLE - 1);
        const firstAddressesContent = firstAddresses.map(this.renderAddress);
        const remainingAddresses = addresses.slice(MAX_ITEMS_VISIBLE - 1);
        const remainingAddressesContent = remainingAddresses.map((item, index) =>
            this.renderAddress(item, index + MAX_ITEMS_VISIBLE - 1)
        );

        return (
            <div block="CheckoutAddressBook" elem="AddressList">
                {firstAddressesContent}
                {remainingAddresses.length ? (
                    <ExpandableContent
                        heading={isAddressesListExpanded ? __('Show less') : __('Show more')}
                        iconVariant="secondary"
                        isExpandableOnDesktop
                        isScrolled={false}
                        isContentExpanded={isAddressesListExpanded}
                        onClick={this.expandOrCollapseAddressList.bind(this)}
                        mix={{ block: 'CheckoutAddressBook', elem: 'ExpandableContentList' }}
                        key="remainingAddressesContent"
                    >
                        <div block="CheckoutAddressBook" elem="ExpandableAddressList">
                            {remainingAddressesContent}
                        </div>
                    </ExpandableContent>
                ) : null}
            </div>
        );
    }

    renderAddress(address, index) {
        const { onAddressSelect, selectedAddressId, isBilling } = this.props;
        const addressNumber = index + 1;
        const { id, default_billing, default_shipping } = address;
        const postfix =
            (isBilling && default_billing) || (!isBilling && default_shipping) ? getDefaultAddressLabel(address) : '';

        return (
            <CheckoutAddressTable
                onClick={onAddressSelect}
                isSelected={selectedAddressId === id}
                title={__('Address #%s%s', addressNumber, postfix)}
                address={address}
                key={id}
                showAdditionalFields
            />
        );
    }
}

export default CheckoutAddressBook;
