/* eslint-disable array-func/prefer-array-from */
import {
    CLEAR_PRODUCT_RELATED,
    REMOVE_PRODUCT_RELATED,
    UPDATED_PRODUCT_RELATED,
} from './ProductRelatedAccessories.action';

/** @namespace Pwa/Store/ProductRelatedAccessories/Reducer/getInitialState */
export const getInitialState = () => ({
    productsRelated: [],
});

/** @namespace Pwa/Store/ProductRelatedAccessories/Reducer/ProductRelatedAccessoriesReducer */
export const ProductRelatedAccessoriesReducer = (state = getInitialState(), action) => {
    const { payload, type } = action;
    switch (type) {
        case UPDATED_PRODUCT_RELATED:
            return {
                productsRelated: [...state.productsRelated, payload],
            };
        case REMOVE_PRODUCT_RELATED:
            return {
                productsRelated: state.productsRelated.filter((product) => product.sku !== payload.sku),
            };
        case CLEAR_PRODUCT_RELATED:
            return {
                productsRelated: [],
            };

        default:
            return state;
    }
};

export default ProductRelatedAccessoriesReducer;
