/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { updateCustomerSignInStatus, updateIsLoading } from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { setAuthorizationToken } from 'Util/Auth';
import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import getStore from 'Util/Store';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

/** @namespace Altom/Mageplaza/Util/Login/executeLoginFlow */
export const executeLoginFlow = async (token) => {
    const { dispatch } = getStore();

    setAuthorizationToken(token);

    // if customer is authorized, `createEmptyCart` mutation returns customer cart token
    const cartDispatcher = (await CartDispatcher).default;
    const guestCartToken = getGuestQuoteId();

    const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

    if (guestCartToken && guestCartToken !== customerCartToken) {
        // merge guest cart id and customer cart id using magento capabilities
        await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
    }

    setGuestQuoteId(customerCartToken);
    cartDispatcher.updateInitialCartData(dispatch);

    WishlistDispatcher.then(({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch));

    ProductCompareDispatcher.then(({ default: dispatcher }) => dispatcher.assignCompareList(dispatch));

    const myAccountDispatcher = (await MyAccountDispatcher).default;
    await myAccountDispatcher.requestCustomerData(dispatch);

    dispatch(updateCustomerSignInStatus(true));
    dispatch(updateIsLoading(false));
    dispatch(hideActiveOverlay());
    dispatch(showNotification('success', __('You are successfully logged in!')));
};
