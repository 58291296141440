import { BLOG_SEARCH } from '@altom/blog/src/component/BlogSearchBar/BlogSearchBar.config';

export class BlogSearchBarContainerPlugin {
    containerFunctions = (originalMember, instance) => ({
        ...originalMember,
        onCloseButtonClick: this.onCloseButtonClick.bind(instance),
        onSearchBarClick: this.onSearchBarClick.bind(instance)
    });

    onCloseButtonClick() {
        const { hideActiveOverlay, goToPreviousNavigationState } = this.props;
        const { isSearchVisible } = this.state;

        if (!isSearchVisible) {
            return;
        }

        hideActiveOverlay();
        goToPreviousNavigationState();

        this.setState({ isSearchVisible: false });
        this.setState({ searchCriteria: '' });
    }

    onSearchBarClick() {
        const { showOverlay } = this.props;
        const { isSearchVisible } = this.state;
        if (isSearchVisible) {
            return;
        }

        this.setState({ isSearchVisible: true });
        showOverlay(BLOG_SEARCH);
        this.setHeaderState();
    }
}
const { containerFunctions } = new BlogSearchBarContainerPlugin();
export default {
    'Altom/Blog/Component/BlogSearchBar/Container/BlogSearchBarContainer': {
        'member-function': {
            containerFunctions
        }
    }
};
