import { Notification as AdvoxNotification } from 'AdvoxComponent/Notification/Notification.component';
import Icons from 'Component/Icons';

import './Notification.override.style';

/** @namespace Pwa/Component/Notification/Component */
export class Notification extends AdvoxNotification {
    render() {
        const { notification, id } = this.props;
        const { isNotificationVisible } = this.state;
        const { msgText, msgType } = notification;

        const mods = {
            type: msgType.toLowerCase(),
            is: isNotificationVisible ? 'opening' : 'closing',
        };
        const icon = mods.type === 'success' ? 'check_fill' : 'warning_fill';

        return (
            <div block="Notification" mods={mods} ref={this.notification} id={id}>
                <Icons block="Notification" elem="Icon" name={icon} fill="#FFFFFF" />
                <p block="Notification" elem="Text">
                    {msgText}
                </p>
                {this.renderDebug()}
            </div>
        );
    }
}

export default Notification;
