/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { roundPrice } from 'SourceUtil/Price';
import getStore from 'Util/Store';
import { waitForCallback } from "../util/wait";

export const DL_VAL_CATEGORY_LIMIT = 5;

/** @namespace Altom/Gtm/Data/Product/getProductVariantData */
export const getProductVariantData = (product) => {
    const { variants = [] } = product;
    const { sku } = variants[0] || {};
    return sku || '';
};

/** @namespace Altom/Gtm/Data/Product/getProductDimensionsData */
export const getProductDimensionsData = async (product) => {
    const { variants = [], dimensions } = product;
    const { dimensions: childDimensions } = variants[0] || {};
    const rawDimensions = childDimensions || dimensions;
    return rawDimensions ? JSON.parse(rawDimensions) : {};
};

/** @namespace Altom/Gtm/Data/Product/getProductCategoriesData */
export const getProductCategoriesData = async ({ categories = [] }) => categories
        .slice(0, DL_VAL_CATEGORY_LIMIT)
        .map(({ name }) => name)
        .join('/');

export const getProductCategoriesLevelsData = ({ categories = [] }) => categories
        .slice(0, DL_VAL_CATEGORY_LIMIT)
        .map(({ name }) => name)
        .reduce(
            (result, category, index) => ({ ...result, [`item_category${index === 0 ? '' : index + 1}`]: category }),
            {}
        );

/** @namespace Altom/Gtm/Data/Product/getProductPriceData */
export const getProductPriceData = (product) => {
    const { variants = [], type_id, price_range } = product;

    if (!price_range) {
        return -1;
        // ^^^ must be replaced, if price range is not present
    }

    if (type_id === 'grouped') {
        return 0;
    }

    const {
        price_range: { minimum_price },
    } = variants[0] || product;

    if (!minimum_price) {
        return;
    }
    const {
        final_price: { value: discountValue = null } = {}, regular_price: { value = 0 } = {},
    } = minimum_price;

    return +roundPrice(discountValue || value);
};

/** @namespace Altom/Gtm/Data/Product/getProductData */
export const getProductData = (product) => {
    const { sku, name } = product;

    return {
        item_id: sku,
        item_name: name,
        price: getProductPriceData(product),
        item_variant: getProductVariantData(product),
        ...getProductCategoriesLevelsData(product)
    };
};

/** @namespace Altom/Gtm/Data/Product/getProductListEntryData */
export const getProductListEntryData = async (product, position) => {
    return {
        ...(await getProductData(product)),
        index: position
    };
};

/** @namespace Altom/Gtm/Data/Product/getProductsListData */
export const getProductsListData = (products) => {
    return products.map((product, index) => ({
        ...(getProductData(product)),
        index: index + 1
    }));
};


/** @namespace Altom/Gtm/Data/Product/getProductDetailsData */
export const getProductDetailsData = async (product) => {
    await waitForCallback(() => getStore().getState().ConfigReducer?.currencyData?.current_currency_code);
    const currencyCode = getStore().getState().ConfigReducer?.currencyData?.current_currency_code;

    return {
        ecommerce: {
            currency: currencyCode,
            value: getProductPriceData(product),
            items: [getProductData(product)],
            _clear: true
        }
    }
};
