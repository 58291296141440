import { Popup as SourcePopup } from 'AdvoxComponent/Popup/Popup.component';

import { ESCAPE_KEY } from './Popup.config';

import './Popup.style';

/** @namespace Pwa/Component/Popup/Component */
export class Popup extends SourcePopup {
    handleKeyDown(e) {
        switch (e.keyCode) {
            case ESCAPE_KEY:
                this.hidePopUp();
                break;
            default:
                break;
        }
    }
}

export default Popup;
