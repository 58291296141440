/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { DL_VAL_CHECKOUT_BILLING_STEP, getCheckoutEventData } from '../data/checkout';
import { getPurchaseEventData } from '../data/purchase';
import {
    GTM_EVENT_KEY_CHECKOUT_ADD_PAYMENT_INFO, GTM_EVENT_KEY_CHECKOUT_ADD_SHIPPING_INFO,
    GTM_EVENT_KEY_CHECKOUT_BEGIN,
    GTM_EVENT_KEY_PURCHASE
} from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

export const fireCheckoutOptionEvent = debounceCallback(async (step, title) => {
    pushToDataLayer({
        event: step === DL_VAL_CHECKOUT_BILLING_STEP ? GTM_EVENT_KEY_CHECKOUT_ADD_PAYMENT_INFO : GTM_EVENT_KEY_CHECKOUT_ADD_SHIPPING_INFO,
        [step === DL_VAL_CHECKOUT_BILLING_STEP ? 'payment_type' : 'shipping_tier']: title,
        ...await getCheckoutEventData()
    });
});

export const fireCheckoutEvent = debounceCallback(async () => {
    pushToDataLayer({
        event: GTM_EVENT_KEY_CHECKOUT_BEGIN,
        ...await getCheckoutEventData()
    });
});

export const firePurchaseEvent = debounceCallback(async (orderId, guestQuoteId) => {
    pushToDataLayer({
        event: GTM_EVENT_KEY_PURCHASE,
        ...await getPurchaseEventData(orderId, guestQuoteId)
    });
});
