import { getInitialState } from 'SourceStore/Notification/Notification.reducer';
import { HIDE_NOTIFICATION, SHOW_NOTIFICATION } from 'Store/Notification/Notification.action';

export * from 'SourceStore/Notification/Notification.reducer';

/** @namespace Pwa/Store/Notification/Reducer/NotificationReducer */
export const NotificationReducer = (state = getInitialState(), action) => {
    const notifications = { ...state.notifications };

    switch (action.type) {
        case SHOW_NOTIFICATION:
            const { msgType, msgText, msgDebug, msgOptions } = action;
            let alreadyExist = false;

            Object.entries(notifications).forEach(([_, value]) => {
                if (value.msgText.toString() === msgText.toString()) {
                    alreadyExist = true;
                }
            });

            if (!alreadyExist) {
                notifications[Date.now()] = { msgType, msgText, msgDebug, msgOptions: msgOptions ?? {} };

                return {
                    ...state,
                    notifications,
                };
            }

            return state;

        case HIDE_NOTIFICATION:
            // eslint-disable-next-line no-unused-vars
            const { [action.id]: id, ...shownNotifications } = notifications;

            return {
                ...state,
                notifications: shownNotifications,
            };

        default:
            return state;
    }
};

export default NotificationReducer;
