import { BannerSliderContainer } from 'Component/BannerSlider/BannerSlider.container';

import BannerTiles from './BannerTiles.component';

/** @namespace Pwa/Component/BannerTiles/Container */
export class BannerTilesContainer extends BannerSliderContainer {
    __construct(props) {
        const { code } = props;

        super.__construct(props, `BannerTiles-${code}`);
    }

    render() {
        return <BannerTiles {...this.containerProps()} />;
    }
}

export default BannerTilesContainer;
