import { v4 as uuidv4 } from 'uuid';

import { TabsContainer as SourceTabsContainer } from 'AdvoxComponent/Tabs/Tabs.container';

/** @namespace Pwa/Component/Tabs/Container */
export class TabsContainer extends SourceTabsContainer {
    state = {
        activeTab: 1,
        hashId: uuidv4(),
    };

    componentDidMount() {}

    componentDidUpdate() {}

    containerProps() {
        const { hashId } = this.state;
        const { 'data-tabs-variant': tabsVariant } = this.props;

        return {
            ...super.containerProps(),
            hashId,
            tabsVariant,
        };
    }
}

export default TabsContainer;
