import { SET_ADVOX_POPUPS } from 'Store/AdvoxPopup/AdvoxPopup.action';

/** @namespace Pwa/Store/AdvoxPopup/Reducer/getInitialState */
export const getInitialState = () => ({
    popups: null,
});

/** @namespace Pwa/Store/AdvoxPopup/Reducer/AdvoxPopupReducer */
export const AdvoxPopupReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case SET_ADVOX_POPUPS:
            const { popups } = action;

            return {
                ...state,
                popups,
            };
        default:
            return state;
    }
};

export default AdvoxPopupReducer;
