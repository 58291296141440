import PropTypes from 'prop-types';

import { ExpandableContent as AdvoxExpandableContent } from 'AdvoxComponent/ExpandableContent/ExpandableContent.component';
import Icons from 'Component/Icons';
import TextPlaceholder from 'Component/TextPlaceholder';
import { isCrawler, isSSR } from 'Util/Browser';

/** @namespace Pwa/Component/ExpandableContent/Component */
export class ExpandableContent extends AdvoxExpandableContent {
    static propTypes = {
        ...AdvoxExpandableContent.propTypes,
        isScrolled: PropTypes.bool,
        id: PropTypes.string,
    };

    static defaultProps = {
        ...AdvoxExpandableContent.defaultProps,
        isScrolled: true,
        id: '',
    };

    __construct(props) {
        super.__construct(props);
        const { isContentExpanded } = this.props;

        const isForceExpanded = isSSR() || isCrawler();

        this.state = {
            isContentExpanded: isForceExpanded || isContentExpanded,
            // eslint-disable-next-line react/no-unused-state
            prevIsContentExpanded: isContentExpanded,
        };
    }

    static getDerivedStateFromProps({ isContentExpanded }, { prevIsContentExpanded }) {
        if (isContentExpanded !== prevIsContentExpanded) {
            return {
                prevIsContentExpanded: isContentExpanded,
                isContentExpanded,
            };
        }

        return null;
    }

    toggleExpand() {
        const { onClick, isScrolled } = this.props;

        if (onClick) {
            onClick();

            return;
        }
        this.setState(
            ({ isContentExpanded }) => ({ isContentExpanded: !isContentExpanded }),
            () => isScrolled && this.scrollToExpandedContent()
        );
    }

    renderIconMap = {
        primary: {
            up: () => <Icons name="arrow_up" fill="#8A8989" width="10" />,
            down: () => <Icons name="arrow_down" fill="#8A8989" width="10" />,
        },
        secondary: {
            down: () => <Icons name="dropdown_arrow" fill="#000" width="28" />,
            up: () => <Icons name="dropdown_arrow_up" fill="#000" width="28" />,
        },
    };

    renderButton() {
        const { isContentExpanded } = this.state;
        const { id, heading, mix, iconVariant, headerIcon } = this.props;
        return (
            <div
                role="button"
                tabIndex={0}
                block="ExpandableContent"
                elem="Button"
                mods={{ isContentExpanded, iconVariant }}
                mix={{ ...mix, elem: 'ExpandableContentButton' }}
                onClick={this.toggleExpand}
                onKeyDown={this.toggleExpand}
                id={id}
            >
                <div block="ExpandableContent" elem="Heading" mix={{ ...mix, elem: 'ExpandableContentHeading' }}>
                    {headerIcon}
                    {typeof heading === 'string' ? <TextPlaceholder content={heading} length="medium" /> : heading}
                </div>
                {this.renderButtonIcon()}
            </div>
        );
    }
}

export default ExpandableContent;
