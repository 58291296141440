import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Icons from 'Component/Icons';

import { SCROLL_OFFSET, TEXT_MAX_LENGTH } from './ShowHideText.config';

import './ShowHideText.style';

/** @namespace Pwa/Component/ShowHideText/Component */
export class ShowHideText extends PureComponent {
    static propTypes = {
        children: PropTypes.node,
        visibleTextLength: PropTypes.number,
        hideVisually: PropTypes.bool,
        hideVisuallyHeight: PropTypes.number,
        onlyMobile: PropTypes.bool,
        togglerPosition: PropTypes.oneOf(['left', 'right']),
        togglerOnMobilePosition: PropTypes.oneOf(['left', 'right']),
    };

    static defaultProps = {
        visibleTextLength: TEXT_MAX_LENGTH,
        hideVisually: false,
        togglerPosition: 'left',
        togglerOnMobilePosition: 'left',
        hideVisuallyHeight: 44,
    };

    state = {
        isOpen: false,
        renderVisuallyHiddenSwitcher: false,
    };

    componentDidMount() {
        this.checkHeights();
    }

    componentDidUpdate({ isMobile: prevIsMobile, children: prevChildren }) {
        const { isMobile, children } = this.props;

        if (prevIsMobile !== isMobile || prevChildren !== children) {
            this.checkHeights();
        }
    }

    __construct(props) {
        super.__construct(props);
        this.elementContent = React.createRef();
        this.elementContainer = React.createRef();
    }

    checkHeights() {
        const { hideVisuallyHeight, hideVisually } = this.props;

        if (this.elementContent && this.elementContent.current && hideVisually) {
            const contentHeight = this.elementContent.current.clientHeight;
            this.setState({ renderVisuallyHiddenSwitcher: contentHeight > hideVisuallyHeight });
        }
    }

    toggle() {
        const y = this.elementContainer.current.getBoundingClientRect().top + window.pageYOffset + SCROLL_OFFSET;
        this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    renderHideByStrip() {
        const { children: text, visibleTextLength, togglerPosition, togglerOnMobilePosition } = this.props;
        const { isOpen } = this.state;

        const firstBlock = text && text.substring(0, visibleTextLength);
        const hiddenBlock = text && text.substring(visibleTextLength);
        const finalContent = `${firstBlock}${isOpen && hiddenBlock}`;
        return text ? (
            <div block="ShowHideText">
                <p block="ShowHideText" elem="Text">
                    {finalContent}
                </p>
                {hiddenBlock.length && (
                    <div block="ShowHideText" elem="TogglerWrapper" mods={{ togglerPosition, togglerOnMobilePosition }}>
                        <button block="ShowHideText" elem="Toggler" onClick={this.toggle.bind(this)}>
                            {isOpen ? __('Hide') : __('Expand')}
                        </button>
                        <Icons name={isOpen ? 'arrow_up' : 'arrow_down'} width="10" height="10" />
                    </div>
                )}
            </div>
        ) : null;
    }

    renderHideByHeight() {
        const { children, togglerPosition, togglerOnMobilePosition, hideVisuallyHeight, isMobile, onlyMobile } =
            this.props;
        const { isOpen, renderVisuallyHiddenSwitcher } = this.state;

        const styles = {
            height: isOpen ? 'auto' : `${hideVisuallyHeight}px`,
            overflow: 'hidden',
        };

        return (onlyMobile && isMobile) || !onlyMobile ? (
            <div block="ShowHideText" ref={this.elementContainer}>
                <div block="ShowHideText" elem="Text" style={renderVisuallyHiddenSwitcher ? styles : {}}>
                    <div ref={this.elementContent}>{children}</div>
                </div>
                {renderVisuallyHiddenSwitcher ? (
                    <div block="ShowHideText" elem="TogglerWrapper" mods={{ togglerPosition, togglerOnMobilePosition }}>
                        <button block="ShowHideText" elem="Toggler" onClick={this.toggle.bind(this)}>
                            {isOpen ? __('Hide') : __('Expand')}
                        </button>
                        <Icons name={isOpen ? 'arrow_up' : 'arrow_down'} width="10" height="10" />
                    </div>
                ) : null}
            </div>
        ) : (
            <div block="ShowHideText">
                <div block="ShowHideText" elem="Text">
                    <div>{children}</div>
                </div>
            </div>
        );
    }

    render() {
        const { hideVisually } = this.props;

        return hideVisually ? this.renderHideByHeight() : this.renderHideByStrip();
    }
}

export default ShowHideText;
