import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import { mapDispatchToProps, mapStateToProps } from 'Component/MyAccountAddressForm/MyAccountAddressForm.container';
import CompanyDataQuery from 'Query/CompanyData.query';
import { CheckoutAddressFormContainer as SourceCheckoutAddressFormContainer } from 'SourceComponent/CheckoutAddressForm/CheckoutAddressForm.container';
import { fetchQuery } from 'Util/Request';

export { mapStateToProps, mapDispatchToProps };

/** @namespace Pwa/Component/CheckoutAddressForm/Container */
export class CheckoutAddressFormContainer extends SourceCheckoutAddressFormContainer {
    state = {
        ...this.state,
        isCheckingNip: false,
    };

    containerFunctions = {
        ...this.containerFunctions,
        onVatIdBlur: this.onVatIdBlur.bind(this),
    };

    // inject values from gusData object into form fields
    injectValuesIntoForm(gusData) {
        if (gusData && Object.keys(gusData).length) {
            const fieldsForInject = ['city', 'postcode', 'street_0', 'street_1', 'company'];
            const { city, zipCode, street, propertyNumber, apartmentNumber, name } = gusData;
            const valuesToInject = {
                city,
                postcode: zipCode,
                street_0: street,
                street_1: `${propertyNumber ?? ''}${apartmentNumber ? `/${apartmentNumber}` : ''}`,
                company: name,
            };

            fieldsForInject.forEach((key) => {
                const field = document.querySelector(`#BILLING_STEP [name="${key}"]`);

                if (field && valuesToInject[key] && !isEmpty(valuesToInject[key])) {
                    field.value = valuesToInject[key];
                }
            });
        }
    }

    async onVatIdBlur(e) {
        const nip = e.target.value.replaceAll(' ', '');
        this.setState({ isCheckingNip: true });

        await fetchQuery(CompanyDataQuery.getCompanyDataByNip(nip)).then(
            /** @namespace Pwa/Component/CheckoutAddressForm/Container/CheckoutAddressFormContainer/onVatIdBlur/fetchQuery/then */
            ({ gusDataByNip }) => {
                this.setState({ isCheckingNip: false });
                this.injectValuesIntoForm(gusDataByNip);
            },
            /** @namespace Pwa/Component/CheckoutAddressForm/Container/CheckoutAddressFormContainer/onVatIdBlur/fetchQuery/then/catch */
            () => {
                this.setState({ isCheckingNip: false });
            }
        );
    }

    containerProps() {
        const { isCheckingNip } = this.state;

        return {
            ...super.containerProps(),
            ...this.props,
            isCheckingNip,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressFormContainer);
