import {
    cloneElement,
    useEffect,
    useRef,
} from 'react';

import { fireImpressionEvent } from '../event/list';
import { registerCallback, unregisterCallback } from '../util/observer';

export const VISIBILITY_TIMEOUT_MS = 150;

/** @namespace Scandiweb/GoogleTagManager/Component/ProductTracker/ProductListTracker */
export function ProductListTracker(props) {
    const { children, products, list } = props;
    const productsListRef = useRef(null);

    useEffect(() => {
        const { current: productListEl } = productsListRef;

        const visibilityTimeout = setTimeout(() => {
            registerCallback(productListEl, () => {

                if (!products.length) {
                    return;
                }

                fireImpressionEvent(
                    products,
                    list
                );

                unregisterCallback(productListEl);
            });
        }, VISIBILITY_TIMEOUT_MS);

        return () => {
            const { current: productListEl } = productsListRef;

            clearTimeout(visibilityTimeout);

            if (!productListEl) {
                return;
            }

            unregisterCallback(productListEl);
        };
    }, []);

    return cloneElement(
        children,
        {
            ref: productsListRef,
            ...children.props
        },
        children.props.children
    );
}
