import BlogCategories from '@altom/blog/src/component/BlogCategories';
import BlogRecentPosts from '@altom/blog/src/component/BlogRecentPosts';
import BlogRelatedPosts from '@altom/blog/src/component/BlogRelatedPosts';
import BlogRelatedProducts from '@altom/blog/src/component/BlogRelatedProducts';
import BlogSearchBar from '@altom/blog/src/component/BlogSearchBar';
import { Helmet as ReactHelmet } from 'react-helmet';

import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import Icons from 'Component/Icons';
import Image from 'Component/Image';
import TextPlaceholder from 'Component/TextPlaceholder';
import NoMatch from 'Route/NoMatch';
import NoMatchHandler from 'Route/NoMatchHandler';

import { blogContentScroll } from '../util/BlogContent';
import { convertBlogDateFormat } from '../util/BlogDate';

import '../route/PostsDetails/PostsDetails.style.scss';

export default {
    'Altom/Blog/Route/PostsDetails/Component/PostsDetailsComponent': {
        'member-function': {
            componentDidMount() {
                const sectionTitle = '.BlogPostDetails-SectionTitle';
                blogContentScroll(sectionTitle);
            },
            renderTitle(args, callback, instance) {
                const {
                    post: { title }
                } = instance.props;

                return (
                    <h1 mix={ { block: 'BlogPostDetails', elem: 'Title' } }>
                        <TextPlaceholder length="medium" content={ title } />
                    </h1>
                );
            },
            renderTags(args, callback, instance) {
                const {
                    post: { tags },
                    device: { isMobile },
                    isLoaded,
                } = instance.props;

                if (isMobile) {
                    return null;
                }
                if (isLoaded && !tags) {
                    return null;
                }

                return tags.map((tag) => (
                    <div key={ tag.title } mix={ { block: 'BlogPostDetails', elem: 'Tag' } }>
                        { tag.title }
                    </div>
                ));
            },
            renderViewCount(args, callback, instance) {
                const {
                    post: { views_count }
                } = instance.props;

                return (
                    <div mix={ { block: 'BlogPostDetails', elem: 'ViewCountBox' } }>
                        <Icons name="eye" width="22" height="22" />
                        <span block="BlogPostDetails" elem="ViewsCount">
                            { views_count }
                        </span>
                    </div>
                );
            },
            renderPublishDate(args, callback, instance) {
                const {
                    post: { publish_time }
                } = instance.props;

                if (!publish_time) {
                    return null;
                }

                return (
                    <div mix={ { block: 'BlogPostDetails', elem: 'Date' } }>
                        <Icons name="calendar" width="22" height="22" />
                        <TextPlaceholder
                          mix={ { block: 'BlogRecentPosts', elem: 'DatePlaceholder' } }
                          content={ convertBlogDateFormat(publish_time) }
                        />
                    </div>
                );
            },
            renderArticleSchema(args, callback, instance) {
                const {
                    post: { title, publish_time, first_image, meta_description },
                    store_name,
                    base_url
                } = instance.props;

                const description = meta_description?.replace(/"/g, '\\"');

                return (
                    <ReactHelmet>
                        <script defer type="application/ld+json">
                            {`
                                {"@context": "http://schema.org/",
                                    "@type": "Article",
                                    "datePublished": "${publish_time}",
                                    "description": "${description}",
                                    "headline": "${title}",
                                    "image": {
                                        "@type": "ImageObject",
                                        "image": "${first_image}"
                                    },
                                    "publisher": {
                                        "@type": "Organization",
                                        "name": "${store_name}",
                                        "url": "${base_url}"
                                    }
                                }
                            `}
                        </script>
                    </ReactHelmet>
                );
            },
            renderContent(args, callback, instance) {
                const {
                    post: { content }
                } = instance.props;

                if (!content) {
                    return (
                        <TextPlaceholder
                          mix={ { block: 'BlogPostDetails', elem: 'ContentPlaceholder' } }
                          length="custom"
                        />
                    );
                }

                return (
                    <div mix={ { block: 'BlogPostDetails', elem: 'Content' } }>
                        <Html content={ content } />
                    </div>
                );
            },
            renderMainContent(args, callback, instance) {
                return (
                    <div>
                        {instance.renderTitle()}
                        {instance.renderTags()}
                        <div block="BlogPostDetails" elem="InfoWrapper">
                            {instance.renderPublishDate()}
                            {instance.renderViewCount()}
                        </div>
                        {instance.renderImage()}
                        {instance.renderContent()}
                        {instance.renderMediaGallery()}
                    </div>
                );
            },
            renderImage(args, callback, instance) {
                const {
                    post: { title, post_id, first_image }
                } = instance.props;

                if (!first_image && post_id) {
                    return null;
                }

                return (
                    <Image
                      block="PostsDetails"
                      elem="FeaturedImage"
                      src={ first_image }
                      ratio="4x3"
                      alt={title}
                      isPlaceholder={ !first_image }
                    />
                );
            },
            render(args, callback, instance) {
                const { isPostMatch, isLoaded, device: { isMobile } } = instance.props;

                if (!isPostMatch && isLoaded) {
                    return <NoMatch />;
                }

                if (isPostMatch) {
                    return (
                        <NoMatchHandler>
                            <main block="BlogPostDetails">
                                {instance.renderArticleSchema()}
                                <ContentWrapper wrapperMix={{ block: 'BlogPostDetails', elem: 'Wrapper' }} label="Post">
                                    <div block="BlogPostDetails" elem="ColumnWrapper">
                                        <div block="BlogPostDetails" elem="Description">
                                            {instance.renderPostListingDetails()}
                                            {isMobile ? <BlogRelatedProducts /> : null}
                                        </div>
                                        <div block="Blog" elem="Sidebar">
                                            <BlogSearchBar />
                                            <BlogCategories />
                                            <BlogRecentPosts />
                                            <BlogRelatedPosts />
                                        </div>
                                    </div>
                                    {!isMobile ? <BlogRelatedProducts /> : null}
                                </ContentWrapper>
                            </main>
                        </NoMatchHandler>
                    );
                }

                return null;
            },
        }
    }
};
