import PropTypes from 'prop-types';

import { FIELD_TYPE } from 'Component/Field/Field.config';
import Icons from 'Component/Icons';
import Loader from 'Component/Loader';
import { FieldFile as SourceFieldFile } from 'SourceComponent/FieldFile/FieldFile.component';

/**
 * Field File
 * @class FieldFile
 * @namespace Pwa/Component/FieldFile/Component */
export class FieldFile extends SourceFieldFile {
    static propTypes = {
        ...SourceFieldFile.propTypes,
        fileNames: PropTypes.arrayOf(PropTypes.string.isRequired),
    };

    renderEmptyLabel() {
        const { fileNames = [] } = this.props;
        if (fileNames.length) return null;
        return <span>{__('No file selected')}</span>;
    }

    renderInputLabel() {
        const { attr: { id = '', multiple = false, buttonLabel } = {}, isLoading = false } = this.props;

        if (isLoading) {
            return <Loader isLoading />;
        }

        const selectLabel = buttonLabel ?? (multiple ? __('Add attachments') : __('Add attachment'));

        return (
            <label htmlFor={id}>
                <Icons name="paper_clip" />
                <span>{selectLabel}</span>
            </label>
        );
    }

    renderFilesName() {
        const { fileNames = [], onRemove } = this.props;

        if (!fileNames.length) return null;

        return (
            <ul block="FieldFile" elem="List">
                {fileNames.map((fileName, key) => (
                    <li key={fileName}>
                        <Icons name="paper_clip" />
                        {fileName}
                        <button block="FieldFile" elem="Remove" onClick={() => onRemove(key)}>
                            <Icons name="close" />
                        </button>
                    </li>
                ))}
            </ul>
        );
    }

    render() {
        const { attr = {}, attr: { accept = '' } = {}, events = {}, setRef, isLoading } = this.props;

        const allowedFieldTypes = (accept || '')
            .split(',')
            .map((type = '') => type.split('/').slice(-1)[0])
            .join(', ');

        return (
            <div block="FieldFile">
                <Loader isLoading={isLoading} isFullPage />
                <div block="FieldFile" elem="Wrapper">
                    <div block="FieldFile" elem="Button">
                        <input
                            ref={(elem) => setRef(elem)}
                            type={FIELD_TYPE.file}
                            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                            {...attr}
                            // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                            {...events}
                        />
                        {this.renderInputLabel()}
                    </div>
                    {this.renderEmptyLabel()}
                </div>
                {allowedFieldTypes.length > 0 && this.renderSubLabel(allowedFieldTypes)}
                {this.renderFilesName()}
            </div>
        );
    }
}

export default FieldFile;
