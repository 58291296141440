import { Field } from 'Util/Query';

/** @namespace Pwa/Query/ElasticSuite/Query */
export class ElasticSuiteQuery {
    getTrackerVariablesField() {
        return new Field('tracker_variables').addFieldList(['name', 'value']);
    }
}

export default new ElasticSuiteQuery();
