import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import { ChildrenType } from 'Type/Common.type';

import './CmsBlock.style';

/**
 * Cms Block
 * @class CmsBlock
 * @namespace Pwa/Component/CmsBlock/Component */
export class CmsBlock extends PureComponent {
    static propTypes = {
        cmsBlock: PropTypes.shape({
            identifier: PropTypes.string,
            content: PropTypes.string,
            disabled: PropTypes.bool,
        }),
        blockType: PropTypes.string,
        children: ChildrenType,
    };

    static defaultProps = {
        cmsBlock: {},
        children: [],
        blockType: '',
    };

    renderPlaceholder() {
        const { children } = this.props;

        if (children.length) {
            return children;
        }

        return null;
    }

    render() {
        const {
            cmsBlock: { identifier, content, disabled },
            blockType,
        } = this.props;

        if (disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        if (identifier === 'myAccount_newsletter_subscription_text') {
            const cleanText = content.replace(/<\/?[^>]+(>|$)/g, '');
            return (
                <div block="CmsBlock" elem="Wrapper" mods={{ type: blockType }}>
                    <Html content={cleanText} />
                </div>
            );
        }

        return (
            <div block="CmsBlock" elem="Wrapper" mods={{ type: blockType }}>
                <Html content={content} />
            </div>
        );
    }
}

export default CmsBlock;
