import CheckoutPayments from 'Component/CheckoutPayments';

const renderPayments = (args, callback, instance) => {
    const {
        paymentMethods,
        onPaymentMethodSelect,
        setLoading,
        setDetailsStep,
        shippingAddress,
        savePaymentInformationOnly,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
    } = instance.props;

    if (!paymentMethods.length) {
        return null;
    }

    return (
        <CheckoutPayments
            setLoading={setLoading}
            setDetailsStep={setDetailsStep}
            paymentMethods={paymentMethods}
            onPaymentMethodSelect={onPaymentMethodSelect}
            setOrderButtonVisibility={instance.setOrderButtonVisibility}
            billingAddress={shippingAddress}
            setOrderButtonEnableStatus={instance.setOrderButtonEnableStatus}
            savePaymentInformationOnly={savePaymentInformationOnly}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
        />
    );
};

export default {
    'Pwa/Component/CheckoutBilling/Component': {
        'member-function': {
            renderPayments,
        },
    },
};
