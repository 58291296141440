import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer,
} from 'SourceComponent/ProductPrice/ProductPrice.container';

import ProductPrice from './ProductPrice.component';

/** @namespace Pwa/Component/ProductPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    urlRewrite: state.UrlRewritesReducer.urlRewrite,
});

export { mapDispatchToProps };

/**
 * Product price
 * @class ProductPrice
 * @namespace Pwa/Component/ProductPrice/Container */
export class ProductPriceContainer extends SourceProductPriceContainer {
    containerProps() {
        const { urlRewrite } = this.props;

        return {
            ...super.containerProps(),
            urlRewrite,
        };
    }

    render() {
        return <ProductPrice {...this.containerProps()} />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer));
