/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */


import { getPageData } from './page';
import { getProductsListData } from './product';

/** @namespace Scandiweb/GoogleTagManager/Data/List/getProductImpressionsData */
export const getProductImpressionsData = async (
    products, forcedList
) => {
    const { pageType: list } = await getPageData();
    const listId = forcedList || list;

    return {
        ecommerce: {
            item_list_id: listId,
            item_list_name: listId,
            items: await getProductsListData(
                products
            ),
            _clear: true
        }
    };
};

/** @namespace Scandiweb/GoogleTagManager/Data/List/getProductClickData */
export const getProductClickData = async (
    product, position, forcedList
) => {
    const { pageType: list } = await getPageData();
    // ^^^ Reuse page data as list information
    const listId = forcedList || list;

    return {
        ecommerce: {
            item_list_id: listId,
            item_list_name: listId,
            items: [await getProductListEntryData(
              product, position
            )],
            _clear: true
        }
    };
};
