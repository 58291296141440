import { FieldFileContainer as SourceFieldFileContainer } from 'SourceComponent/FieldFile/FieldFile.container';

/** @namespace Pwa/Component/FieldFile/Container */
export class FieldFileContainer extends SourceFieldFileContainer {
    containerFunctions = {
        ...this.containerFunctions,
        onRemove: this.onRemove.bind(this),
    };

    state = {
        isLoading: false,
        fileNames: [],
        value: [],
    };

    onRemove(key) {
        this.setState((prevState) => ({
            fileNames: prevState.fileNames.filter((_, fileKey) => key !== fileKey),
            isLoading: false,
            value: prevState.value.filter((_, fileKey) => key === fileKey),
        }));

        if (typeof this.fieldRef.fileData === 'string') {
            const fileData = JSON.parse(this.fieldRef.fileData).filter((_, fileKey) => key !== fileKey);
            this.fieldRef.fileData = fileData.length ? JSON.stringify(fileData) : undefined;
        }
    }

    onChange() {
        const { events: { onChange } = {} } = this.props;

        if (this.fieldRef) {
            const { files } = this.fieldRef;
            this.setState({ isLoading: true });

            // eslint-disable-next-line no-restricted-syntax
            for (const file of files) {
                const reader = new FileReader();

                // eslint-disable-next-line no-loop-func
                reader.onload = () => {
                    const fieldRefData =
                        (typeof this.fieldRef.fileData === 'string'
                            ? JSON.parse(this.fieldRef.fileData)
                            : this.fieldRef.fileData) ?? [];
                    const content = reader.result.split(',')[1];
                    const filename = file.name.replace(/\.[^/.]+$/, '');
                    const filetype = file.name.split('.').pop();

                    this.setState((prevState) => ({
                        fileNames: [...prevState.fileNames, [filename, filetype].join('.')],
                        value: [...prevState.value, content],
                    }));

                    const fileData = [
                        ...fieldRefData,
                        {
                            content,
                            filename,
                            filetype,
                        },
                    ];

                    this.fieldRef.fileData = JSON.stringify(fileData);

                    if (typeof onChange === 'function') {
                        onChange(fileData);
                    }
                };

                reader.onerror = () => {
                    this.setState({ fileNames: [], isLoading: false });

                    if (typeof onChange === 'function') {
                        onChange([]);
                    }
                };

                reader.readAsDataURL(file);
            }

            this.setState({ isLoading: false });
        }
    }

    containerProps() {
        const { events, attr: { autoComplete, autocomplete, ...attr } = {}, setRef } = this.props;
        const { fileNames, isLoading, value } = this.state;

        return {
            attr: {
                ...attr,
                autoComplete: autoComplete || autocomplete,
            },
            setRef,
            events: {
                ...events,
                onChange: this.onChange.bind(this),
            },
            fileNames,
            isLoading,
            value,
        };
    }
}

export default FieldFileContainer;
