import { FieldNumberContainer as AdvoxFieldNumberContainer } from 'AdvoxComponent/FieldNumber/FieldNumber.container';

/**
 * Field Number
 * @class FieldNumberContainer
 * @namespace Pwa/Component/FieldNumber/Container */
export class FieldNumberContainer extends AdvoxFieldNumberContainer {
    componentDidUpdate(prevProps) {
        const { attr: { min, defaultValue = min } = {} } = this.props;
        const { attr: { defaultValue: prevDefaultValue } = {} } = prevProps;

        if (defaultValue < 0 || prevDefaultValue < 0) {
            this.setState({ value: min });
        }

        if (defaultValue < min) {
            this.handleInitialLoad(min);
        }
    }
}

export default FieldNumberContainer;
