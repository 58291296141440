import { FieldSelect as AdvoxFieldSelect } from 'AdvoxComponent/FieldSelect/FieldSelect.component';

import './FieldSelect.override.style';

/** @namespace Pwa/Component/FieldSelect/Component */
export class FieldSelect extends AdvoxFieldSelect {
    renderNativeOption(option) {
        const { id, value, disabled, label, subLabel = '', isAvailable = true } = option;

        const { isDisabled } = this.props;

        return (
            <option key={id} id={id} value={value} disabled={disabled || isDisabled || !isAvailable}>
                {`${label} ${subLabel}`}
            </option>
        );
    }
}

export default FieldSelect;
