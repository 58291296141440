import React, { PureComponent } from 'react';

import StaticPopup from './StaticPopup.component';

/** @namespace Pwa/Component/StaticPopup/Container */
export class StaticPopupContainer extends PureComponent {
    state = {
        isVisible: false,
    };

    containerFunctions = {
        closePopup: this.closePopup.bind(this),
    };

    componentDidMount() {
        const { popupId } = this.props;
        const triggers = document.querySelectorAll(`[data-trigger-popup="${popupId}"]`);
        const self = this;

        if (triggers && triggers.length) {
            triggers.forEach((element) => {
                element.addEventListener('click', this.openPopup.bind(self));
            });
        }
    }

    componentWillUnmount() {
        const { popupId } = this.props;
        const triggers = document.querySelectorAll(`[data-trigger-popup="${popupId}"]`);
        const self = this;

        if (triggers && triggers.length) {
            triggers.forEach((element) => {
                element.addEventListener('click', this.openPopup.bind(self));
            });
        }
    }

    containerProps() {
        const { children } = this.props;
        const { isVisible } = this.state;

        return { isVisible, content: children };
    }

    openPopup(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({ isVisible: true });
    }

    closePopup() {
        this.setState({ isVisible: false });
    }

    render() {
        return <StaticPopup {...this.containerProps()} {...this.containerFunctions} />;
    }
}

export default StaticPopupContainer;
