/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const GTM_EVENT_KEY_GENERAL = 'general';
// vvv Event/plugin "notFound"
export const GTM_EVENT_KEY_NOT_FOUND = 'not_found';
// vvv Event/plugin "user"
export const GTM_EVENT_KEY_USER_LOGIN = 'login';
export const GTM_EVENT_KEY_USER_REGISTER = 'sign_up';
// vvv Event/plugin "search"
export const GTM_EVENT_KEY_SEARCH = 'search';
export const GTM_EVENT_KEY_SEARCH_STARTED = 'site_search_started';
// vvv Event/plugin "list"
export const GTM_EVENT_KEY_IMPRESSIONS = 'view_item_list';
export const GTM_EVENT_KEY_PRODUCT_CLICK = 'select_item';
// vvv Event/plugin "product"
export const GTM_EVENT_KEY_PRODUCT_DETAIL = 'view_item';
// vvv Event/plugin "cart"
export const GTM_EVENT_KEY_ADD_TO_CART = 'add_to_cart';
export const GTM_EVENT_KEY_REMOVE_FROM_CART = 'remove_from_cart';
// vvv Event/plugin "checkout"
export const GTM_EVENT_KEY_CHECKOUT_BEGIN = 'begin_checkout';
export const GTM_EVENT_KEY_CHECKOUT_ADD_PAYMENT_INFO = 'add_payment_info';
export const GTM_EVENT_KEY_CHECKOUT_ADD_SHIPPING_INFO = 'add_shipping_info';
// vvv Event/plugin "purchase"
export const GTM_EVENT_KEY_PURCHASE = 'purchase';
