export const NOTIFICATION_POSITION_SEPARATOR = '::';

/** @namespace Pwa/Util/Notification/Options/getNotificationPosition */
export const getNotificationPosition = (prefix, productId) => prefix + NOTIFICATION_POSITION_SEPARATOR + productId;

/** @namespace Pwa/Util/Notification/Options/getNotificationPositions */
export const getNotificationPositions = (prefix, products) => {
    if (!prefix) {
        return [];
    }

    if (!Array.isArray(products)) {
        return [getNotificationPosition(prefix, products)];
    }

    if (products?.length === 0) {
        return [prefix];
    }

    return (
        products
            ?.filter(({ productId }) => !!productId)
            ?.map(({ productId }) => getNotificationPosition(prefix, productId)) ?? []
    );
};
