import {
    GET_REWARDS_POINTS,
    GET_REWARDS_POINTS_BALANCE,
    GET_REWARDS_POINTS_ERROR,
    GET_REWARDS_POINTS_RESET,
} from 'Store/RewardsPoints/RewardsPoints.actions';

/** @namespace Pwa/Store/RewardsPoints/Reducer/getInitialState */
export const getInitialState = () => ({
    error: null,
    isLoading: true,
    isApplied: false,
    spendPoints: 0,
    spendMinPoints: 0,
    spendMaxPoints: 0,
    baseSpendAmount: 0,
    balance: 0,
    moneyBalance: { value: 0, currency: 'USD' },
    earnPoints: 0,
});

/** @namespace Pwa/Store/RewardsPoints/Reducer/RewardsPointsReducer */
export const RewardsPointsReducer = (state = getInitialState(), action) => {
    const { type, error, balance, moneyBalance, ...rewardsPoints } = action;

    switch (type) {
        case GET_REWARDS_POINTS:
            return {
                ...state,
                ...rewardsPoints,
                isLoading: false,
            };
        case GET_REWARDS_POINTS_BALANCE:
            return {
                ...state,
                balance,
                moneyBalance,
            };
        case GET_REWARDS_POINTS_ERROR:
            return {
                ...state,
                isLoading: false,
                error,
            };
        case GET_REWARDS_POINTS_RESET:
            return getInitialState();
        default:
            return state;
    }
};

export default RewardsPointsReducer;
