import { connect } from 'react-redux';

import {
    BannerSliderContainer as SourceBannerSliderContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'AdvoxComponent/BannerSlider/BannerSlider.container';
import SliderQuery from 'Query/Slider.query';
import { fetchQuery } from 'Util/Request';

/** @namespace Pwa/Component/BannerSlider/Container */
export class BannerSliderContainer extends SourceBannerSliderContainer {
    __construct(props) {
        const { code } = props;

        super.__construct(props, `BannerSlider-${code}`);
    }

    getSliderData() {
        const { position, code } = this.props;

        fetchQuery([SliderQuery.getSliderQuery(position, code)]).then(
            /** @namespace Pwa/Component/BannerSlider/Container/BannerSliderContainer/getSliderData/fetchQuery/then */
            ({ slider }) => {
                this.setState({
                    slider,
                });
            },
            /** @namespace Pwa/Component/BannerSlider/Container/BannerSliderContainer/getSliderData/fetchQuery/then/catch */
            () => {
                this.setState({});
            }
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerSliderContainer);
