import { MyAccountAddressForm as SourceMyAccountAddressForm } from 'AdvoxComponent/MyAccountAddressForm/MyAccountAddressForm.component';

import myAccountAddressForm from './MyAccountAddressForm.form';

import './MyAccountAddressForm.override.style';

/** @namespace Pwa/Component/MyAccountAddressForm/Component */
export class MyAccountAddressForm extends SourceMyAccountAddressForm {
    get fieldMap() {
        const {
            address,
            countries,
            addressLinesQty,
            regionDisplayAll,
            showVatNumber,
            defaultCountry,
            availableRegions,
            isStateRequired,
            countryId,
            currentRegion,
            currentCity,
            currentRegionId,
            currentZipcode,
            onCountryChange,
            onZipcodeChange,
            onCityChange,
            onRegionChange,
            onRegionIdChange,
        } = this.props;

        const fieldMap = myAccountAddressForm(
            {
                address,
                countries,
                addressLinesQty,
                regionDisplayAll,
                showVatNumber,
                defaultCountry,
                availableRegions,
                isStateRequired,
                countryId,
                currentRegion,
                currentCity,
                currentRegionId,
                currentZipcode,
                ...address,
            },
            {
                onCountryChange,
                onZipcodeChange,
                onCityChange,
                onRegionChange,
                onRegionIdChange,
            }
        );

        Object.keys(fieldMap).forEach((key) => (fieldMap[key].attr?.name === 'company' ? delete fieldMap[key] : null));

        return fieldMap;
    }
}

export default MyAccountAddressForm;
