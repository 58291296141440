import React from 'react';

import { AddToCart as AdvoxAddToCart } from 'AdvoxComponent/AddToCart/AddToCart.component';
import Icons from 'Component/Icons';
import NotificationList from 'Component/NotificationList';
import { getNotificationPosition } from 'Util/Notification/Options';

import './AddToCart.override.style.scss';
/** @namespace Pwa/Component/AddToCart/Component */
export class AddToCart extends AdvoxAddToCart {
    renderNotification() {
        const { magentoProduct: [{ productId }] = [] } = this.props;

        if (!productId) {
            return null;
        }

        return <NotificationList position={getNotificationPosition('AddToCart', productId)} />;
    }

    renderCartIcon() {
        const { isIconEnabled } = this.props;

        if (!isIconEnabled) {
            return null;
        }

        return <Icons name="bag" fill="#fff" width="20" height="20" />;
    }

    renderButtonText() {
        const { product, isAdding, inStock } = this.props;

        if (!product) return null;

        if (!inStock && Object.keys(product).length !== 0) return __('sold out');

        return (
            <>
                {this.renderCartIcon()}
                {isAdding ? __('Adding...') : __('Add to cart')}
            </>
        );
    }

    render() {
        const { inStock, mix, addProductToCart, layout, isDisabled, isAdding } = this.props;

        return (
            <button
                onClick={addProductToCart}
                block="Button AddToCart"
                mix={mix}
                mods={{ layout }}
                disabled={!inStock || isAdding || isDisabled}
            >
                {this.renderButtonText()}
                {this.renderNotification()}
            </button>
        );
    }
}

export default AddToCart;
