import { PureComponent } from 'react';

import Icons from 'Component/Icons';
import Link from 'Component/Link';

import './PromotedCategories.style';

/** @namespace Pwa/Component/PromotedCategories/Component */
export class PromotedCategories extends PureComponent {
    renderCategories() {
        const { categories, isContentExpanded } = this.props;

        return (
            <div block="PromotedCategories" elem="Items" mods={{ isExpanded: isContentExpanded }}>
                {categories.map((item, key) => {
                    const { name, url, is_promoted } = item;
                    if (is_promoted === 0) return;
                    return (
                        <Link to={url} key={key}>
                            <div block="PromotedCategories" elem="Category">
                                {name}
                            </div>
                        </Link>
                    );
                })}
            </div>
        );
    }

    renderButtonContent(desktopView = true) {
        const {
            device: { isMobile, isTablet },
            isContentExpanded,
            toggleExpand,
        } = this.props;

        if ((desktopView && !isMobile && !isTablet) || (!desktopView && (isMobile || isTablet))) {
            return (
                <button type="button" onClick={toggleExpand} block="PromotedCategories" elem="Switcher">
                    {isContentExpanded ? __('Collapse') : __('Expand')}
                    <Icons name={isContentExpanded ? 'arrow_up' : 'arrow_down'} width="10" height="10" fill="#000000" />
                </button>
            );
        }

        return null;
    }

    render() {
        const { categories } = this.props;

        return categories && categories.length ? (
            <div block="PromotedCategories">
                <div block="PromotedCategories" elem="Header">
                    <h4 block="PromotedCategories" elem="Heading">
                        {__('Popular collections')}
                    </h4>
                    {this.renderButtonContent()}
                </div>
                {this.renderCategories()}
                {this.renderButtonContent(false)}
            </div>
        ) : null;
    }
}

export default PromotedCategories;
