import { CategoryTreeComponent as AdvoxCategoryTreeComponent } from 'AdvoxComponent/CategoryTree/CategoryTree.component';
import Link from 'Component/Link';
import ShowHideText from 'Component/ShowHideText';

import './CategoryTree.override.style';

/** @namespace Pwa/Component/CategoryTree/Component */
export class CategoryTreeComponent extends AdvoxCategoryTreeComponent {
    renderChildrenItems(category, showProductCount, isSecondaryVariant) {
        return category.children.map(({ id, name, url, product_count, include_in_menu }) => {
            if (include_in_menu === 1)
                return (
                    <li block="CategoryTree" elem="CategoryItem" key={id}>
                        <Link
                            to={url}
                            block="CategoryTree"
                            elem="Category"
                            mix={{
                                block: 'CategoryTree',
                                elem: 'Button',
                            }}
                        >
                            {name}
                            {showProductCount && !isSecondaryVariant ? (
                                <div block="CategoryTree" elem="Count">
                                    {showProductCount ? `(${product_count})` : null}
                                </div>
                            ) : null}
                        </Link>
                    </li>
                );

            return null;
        });
    }

    renderChildren() {
        const { category, hasParentCategory, showProductCount, isSecondaryVariant } = this.props;

        if (!category.children) return null;

        return (
            <>
                {isSecondaryVariant ? this.renderHeading() : null}
                <ShowHideText hideVisually hideVisuallyHeight={isSecondaryVariant ? 170 : 100} onlyMobile>
                    <ul block="CategoryTree" elem="Categories" mods={{ hasParentCategory }}>
                        {this.renderChildrenItems(category, showProductCount, isSecondaryVariant)}
                    </ul>
                </ShowHideText>
            </>
        );
    }

    renderHeading() {
        return (
            <div block="CategoryTree" elem="Heading" mix={{ block: 'LPBase', elem: 'Title', mods: { medium: true } }}>
                {__('Collection catalog')}
            </div>
        );
    }

    render() {
        const { category, isSecondaryVariant } = this.props || {};

        return (
            <div block="CategoryTree" mods={{ isSecondaryVariant }}>
                {this.renderBackButton()}
                {category?.children?.length ? <>{this.renderChildren()}</> : null}
            </div>
        );
    }
}

export default CategoryTreeComponent;
