import { SHOW_NOTIFICATION } from 'SourceStore/Notification/Notification.action';

export * from 'SourceStore/Notification/Notification.action';

/** @namespace Pwa/Store/Notification/Action/showNotification */
export const showNotification = (msgType, msgText, msgDebug, msgOptions = {}) => ({
    type: SHOW_NOTIFICATION,
    msgType,
    msgText,
    msgDebug,
    msgOptions,
});
