import { SET_ANNOUNCEMENT, SET_CURRENT_LOCATION } from 'Store/Announcement/Announcement.action';

/** @namespace Pwa/Store/Announcement/Reducer/getInitialState */
export const getInitialState = () => ({
    announcement: null,
    announcementBackgroundColor: null,
    currentLocation: null,
});

/** @namespace Pwa/Store/Announcement/Reducer/AnnouncementReducer */
export const AnnouncementReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case SET_ANNOUNCEMENT:
            const { announcement } = action;

            return {
                ...state,
                announcement,
            };
        case SET_CURRENT_LOCATION:
            const { currentLocation } = action;

            return {
                ...state,
                currentLocation,
            };
        default:
            return state;
    }
};

export default AnnouncementReducer;
