/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/MageplazaSocialLogin/Query/SocialToken/Query/SocialTokenQuery */
export class SocialTokenQuery {
    getQuery({ type }) {
        return new Field('socialToken')
            .addArgument('type', 'String!', type)
            .addFieldList(this._getChildFields());
    }

    _getChildFields() {
        return [
            'token'
        ];
    }
}

export default new SocialTokenQuery();
