import { withRouter } from 'react-router-dom';

import { Label as SourceLabel } from 'AdvoxComponent/Label/Label.component';

/** @namespace Pwa/Component/Label/Component */
export class Label extends SourceLabel {
    static defaultProps = {
        ...SourceLabel.defaultProps,
        text_background_color: '#A8959A',
        text_color: '#fff',
    };

    render() {
        const { image, text, text_background_color, text_color, url, history } = this.props;
        const iconOnly = image && !text;
        const color = text_color || '#fff';
        let background = text_background_color || '#A8959A';

        if (iconOnly) {
            background = null;
        }

        return (
            <div
                block="Label"
                mods={{ iconOnly }}
                style={{ color, background }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (url) {
                        history.push(url);
                    }
                }}
            >
                {image ? <img block="Label" elem="Image" mods={{ iconOnly }} src={image} alt={text} /> : null}
                {text}
            </div>
        );
    }
}

export default withRouter(Label);
