import { ProductCustomizableOption as SourceProductCustomizableOption } from 'SourceComponent/ProductCustomizableOption/ProductCustomizableOption.component';

/** @namespace Pwa/Component/ProductCustomizableOption/Component */
export class ProductCustomizableOption extends SourceProductCustomizableOption {
    renderOptionGroupTitle(title) {
        const { isRequired } = this.props;

        return (
            <div block="ProductCustomizableItem" elem="Heading">
                {title}
                {isRequired && (
                    <strong block="ProductCustomizableItem" elem="Required">
                        *
                    </strong>
                )}
            </div>
        );
    }
}

export default ProductCustomizableOption;
