export * from 'SourceUtil/Product/Product';

/** @namespace Pwa/Util/Product/getAttributesWithValues */
export const getAttributesWithValues = (product) => {
    const { attributes = {}, parameters = {} } = product;

    return Object.entries(attributes).reduce((acc, [key, val]) => {
        const { attribute_value } = val;

        if (attribute_value) {
            return { ...acc, [key]: val };
        }

        const valueIndexFromParameter = parameters[key];

        if (valueIndexFromParameter) {
            return { ...acc, [key]: { ...val, attribute_value: valueIndexFromParameter } };
        }

        return acc;
    }, {});
};
/** @namespace Pwa/Util/Product/filterConfigurableOptions */
export const filterConfigurableOptions = (options, variants) =>
    Object.values(options).reduce((acc, option) => {
        const { attribute_values, attribute_code } = option;

        // show option if it exist as variant for configurable product
        const filteredOptions = attribute_values?.reduce((acc, value) => {
            const isVariantExist = variants.find(({ attributes }) => {
                const { attribute_value: foundValue } = attributes[attribute_code] || {};

                return value === foundValue;
            });

            if (isVariantExist) {
                acc.push(value);
            }

            return acc;
        }, []);

        return {
            ...acc,
            [attribute_code]: {
                ...option,
                attribute_values: filteredOptions,
            },
        };
    }, {});
