/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import ClickOutside from 'Component/ClickOutside';
import { MixType } from 'Type/Common.type';

import BlogSearchOverlay from '../BlogSearchOverlay';

import './BlogSearchBar.style';

/** @namespace Altom/Blog/Component/BlogSearchBar/Component/BlogSearchBarComponent */
export class BlogSearchBarComponent extends PureComponent {
    static propTypes = {
        searchCriteria: PropTypes.string.isRequired,
        isSearchVisible: PropTypes.bool.isRequired,
        onSearchBarClick: PropTypes.func.isRequired,
        onSearchBarChange: PropTypes.func.isRequired,
        onSearchOutsideClick: PropTypes.func.isRequired,
        mix: MixType,
    };

    static defaultProps = {
        mix: {},
    };

    render() {
        const { mix, searchCriteria, isSearchVisible, onSearchBarClick, onSearchBarChange, onSearchOutsideClick } =
            this.props;

        return (
            <div mix={mix}>
                <ClickOutside onClick={onSearchOutsideClick}>
                    <div block="BlogSearchBar" elem="SearchWrapper" aria-label="Search">
                        <div block="BlogSearchBar" elem="Search">
                            <input
                                id="posts-search-field"
                                placeholder="Search"
                                block="BlogSearchBar"
                                elem="SearchField"
                                onClick={onSearchBarClick}
                                onChange={onSearchBarChange}
                                value={searchCriteria}
                                mods={{ type: 'searchField' }}
                            />
                        </div>
                        <BlogSearchOverlay
                            searchCriteria={searchCriteria}
                            onSearchOutsideClick={onSearchOutsideClick}
                            isSearchVisible={isSearchVisible}
                        />
                    </div>
                </ClickOutside>
            </div>
        );
    }
}

export default BlogSearchBarComponent;
