import { PureComponent } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import './ReCaptcha.style';

/** @namespace Pwa/Component/ReCaptcha/Component */
export class ReCaptcha extends PureComponent {
    render() {
        const { isReCaptchaEnabled, reCaptchaKey, onChangeValue } = this.props;
        if (!reCaptchaKey || +isReCaptchaEnabled === 0) return null;

        return (
            <div block="ReCaptcha">
                <ReCAPTCHA sitekey={reCaptchaKey} onChange={onChangeValue} />
            </div>
        );
    }
}

export default ReCaptcha;
