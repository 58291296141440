import BlogRecentPosts from '@altom/blog/src/component/BlogRecentPosts';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import parser from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';

import { Html as SourceHtml, WidgetFactory } from 'AdvoxComponent/Html/Html.component';
import BannerSlider from 'Component/BannerSlider';
import BannerTiles from 'Component/BannerTiles';
import { BANNER_TILES_TYPE } from 'Component/BannerTiles/BannerTiles.config';
import CatalogWidget from 'Component/CatalogWidget';
import CategoryTree from 'Component/CategoryTree';
import CmsBlock from 'Component/CmsBlock';
import Delayed from 'Component/Delayed';
import EkomiWidget from 'Component/EkomiWidget';
import FeaturedBrands from 'Component/FeaturedBrands';
import NewsletterSubscription from 'Component/NewsletterSubscription';
import PromotedCategories from 'Component/PromotedCategories';
import ShowHideText from 'Component/ShowHideText';
import ShowRewardPoints from 'Component/ShowRewardPoints';
import StaticPopup from 'Component/StaticPopup';
import StaticSlider from 'Component/StaticSlider';
import { hash } from 'Util/Request/Hash';

import { RULES_TO_REMOVE } from './Html.config';

export { WidgetFactory };

/** @namespace Pwa/Component/Html/Component */
export class Html extends SourceHtml {
    rules = [
        ...(this.rules.filter(({ query }) =>
            query.attribs && query.attribs[0] ? !RULES_TO_REMOVE.includes(query.attribs[0]) : true
        ) || []),
        {
            query: { attribs: ['data-recent-posts'] },
            replace: this.replaceRecentPosts,
        },
        {
            query: { attribs: ['data-showhide'] },
            replace: this.replaceShowHide,
        },
        {
            query: { attribs: ['data-rewardpoints'] },
            replace: this.replaceRewardPoints,
        },
        {
            query: { attribs: ['data-promoted-categories'] },
            replace: this.replacePromotedCategories,
        },
        {
            query: { attribs: ['data-timer-banner'] },
            replace: this.replaceTimerBanner,
        },
        {
            query: { attribs: ['data-newsletter-form'] },
            replace: this.replaceNewsletterForm,
        },
        {
            query: { attribs: ['data-ekomi-widget'] },
            replace: this.replaceEkomiWidget,
        },
        {
            query: { attribs: ['data-slider'] },
            replace: this.replaceSlider,
        },
        {
            query: { attribs: ['data-category-tree'] },
            replace: this.replaceCategoryTree,
        },
        {
            query: { attribs: ['data-static-slider'] },
            replace: this.replaceStaticSlider,
        },
        {
            query: { attribs: ['data-static-popup'] },
            replace: this.replaceStaticPopup,
        },
        {
            query: { attribs: ['data-featured-brands-slider'] },
            replace: this.replaceBrandsSlider,
        },
    ];

    replaceEkomiWidget({ attribs }) {
        const {
            'data-ekomi-widget': ekomiWidget,
            'data-ekomi-widget-id': ekomiId,
            'data-ekomi-widget-token': ekomiToken,
        } = this.attributesToProps(attribs);

        return <EkomiWidget ekomiWidget={ekomiWidget} ekomiId={ekomiId} ekomiToken={ekomiToken} />;
    }

    replaceNewsletterForm() {
        const { device } = this.props;
        if (device.isMobile) return null;

        return <NewsletterSubscription key="NewsletterSubscription" />;
    }

    replacePromotedCategories() {
        return <PromotedCategories />;
    }

    replaceRecentPosts() {
        return <BlogRecentPosts />;
    }

    replaceShowHide({ attribs, children }) {
        const { 'data-showhide': canShow } = this.attributesToProps(attribs);

        return (
            <ShowHideText canShow={canShow} hideVisuallyHeight={100} hideVisually togglerPosition="right">
                {domToReact(children, this.parserOptions)}
            </ShowHideText>
        );
    }

    replaceRewardPoints({ attribs, children }) {
        const { 'data-rewardpoints': canShow } = this.attributesToProps(attribs);
        return <ShowRewardPoints canShow={canShow}>{domToReact(children, this.parserOptions)}</ShowRewardPoints>;
    }

    replaceCatalogWidget({ attribs }) {
        const { 'data-catalog-widget': catalogWidget, 'data-variant': variant } = this.attributesToProps(attribs);

        return <CatalogWidget code={catalogWidget} variant={variant} />;
    }

    replaceTimerBanner({ attribs }) {
        const { 'data-timer-banner': timerBanner, 'data-timer-banner-code': timerBannerCode } =
            this.attributesToProps(attribs);
        const { bannerVisibleFrom, bannerVisibleTo } = this.props;
        const parsedBannerVisibleFrom = parseISO(bannerVisibleFrom);
        const parsedBannerVisibleTo = parseISO(bannerVisibleTo);

        const fitBetweenDates =
            isValid(parsedBannerVisibleFrom) && isValid(parsedBannerVisibleTo)
                ? isBefore(new Date(), parsedBannerVisibleTo) && isAfter(new Date(), parsedBannerVisibleFrom)
                : false;

        const shouldRender =
            timerBanner && timerBanner === 'true' && (fitBetweenDates || (!bannerVisibleFrom && !bannerVisibleTo));

        return shouldRender ? <CmsBlock identifier={timerBannerCode} /> : null;
    }

    replaceScript(elem) {
        const { attribs, children } = elem;
        const { src = '' } = attribs;
        const scriptContent = children[0] ? children[0].data : '';
        const elemHash = hash(src + scriptContent);

        if (this.createdOutsideElements[elemHash]) {
            return <></>;
        }

        const script = document.createElement('script');

        Object.entries(attribs).forEach(([attr, value]) => script.setAttribute(attr, value));

        if (children && children[0]) {
            script.appendChild(document.createTextNode(children[0].data));
        }

        document.head.appendChild(script);

        this.createdOutsideElements[elemHash] = true;

        return <></>;
    }

    replaceSlider({ attribs }) {
        const { 'data-position': position, 'data-code': code, 'data-type': type } = this.attributesToProps(attribs);

        return type === BANNER_TILES_TYPE ? (
            <BannerTiles position={position} code={code} />
        ) : (
            <BannerSlider position={position} code={code} />
        );
    }

    replaceCategoryTree() {
        return (
            <Delayed>
                <CategoryTree isSecondaryVariant />
            </Delayed>
        );
    }

    replaceStaticSlider({ children }) {
        const filteredChildren = children.filter(
            (child) => typeof child?.attribs?.class === 'string' && child.attribs.class.includes('LPSlider-Item')
        );

        return <StaticSlider>{domToReact(filteredChildren, this.parserOptions)}</StaticSlider>;
    }

    replaceStaticPopup({ attribs, children }) {
        const { 'data-popup-id': popupId } = this.attributesToProps(attribs);

        return <StaticPopup popupId={popupId}>{domToReact(children, this.parserOptions)}</StaticPopup>;
    }

    replaceBrandsSlider({ children }) {
        const filteredChildren = children.filter(
            (child) =>
                typeof child?.attribs?.class === 'string' && child.attribs.class.includes('FeaturedBrands-SliderItem')
        );

        return <FeaturedBrands>{domToReact(filteredChildren, this.parserOptions)}</FeaturedBrands>;
    }

    render() {
        const { content } = this.props;

        if (!content) {
            return null;
        }

        return parser(content, this.parserOptions);
    }
}

export default Html;
