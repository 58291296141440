import { SliderPrevArrow as SourceSliderPrevArrow } from 'AdvoxComponent/SliderPrevArrow/SliderPrevArrow.component';
import Icons from 'Component/Icons';

/** @namespace Pwa/Component/SliderPrevArrow/Component */
export class SliderPrevArrow extends SourceSliderPrevArrow {
    iconMap = {
        default: () => <Icons name="arrow_left" width="24" />,
        secondary: () => (
            <div block="SliderNextArrow" elem="Wrapper">
                <Icons name="chevron_left" width="22" height="28" />
            </div>
        ),
        tertiary: () => (
            <div block="SliderNextArrow" elem="Wrapper">
                <Icons name="chevron_left" width="22" height="28" />
            </div>
        ),
    };

    render() {
        const { onClick, className, variant } = this.props;
        const isDisabled = className.search('disabled') !== -1;

        return (
            <div block="SliderPrevArrow" mods={{ disabled: isDisabled, variant }} onClick={onClick}>
                {typeof this.iconMap[variant] === 'function' ? this.iconMap[variant]() : this.iconMap.default()}
            </div>
        );
    }
}

export default SliderPrevArrow;
