/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { AvailableSocialsType } from '../../type/Social.type';
import SocialLoginButtons from './SocialLoginButtons.component';

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButtons/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    availableSocials: state.ConfigReducer.availableSocials,
});

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButtons/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Scandiweb/MageplazaSocialLogin/Component/SocialLoginButtons/Container */
export class SocialLoginButtonsContainer extends PureComponent {
    static propTypes = {
        availableSocials: AvailableSocialsType.isRequired,
    };

    containerFunctions = {};

    __construct(props) {
        super.__construct(props);
    }

    containerProps() {
        const { availableSocials } = this.props;

        return {
            availableSocials,
        };
    }

    render() {
        return <SocialLoginButtons {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialLoginButtonsContainer);
