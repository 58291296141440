import { PureComponent } from 'react';

import Icons from 'Component/Icons';
import { formatPrice } from 'Util/Price';
import { getPluralPointsTranslation } from 'Util/Rewards';

import './ShowRewardPoints.style';

/** @namespace Pwa/Component/ShowRewardPoints/Component */
export class ShowRewardPoints extends PureComponent {
    renderContent() {
        const {
            children,
            location: { pathname },
            totals: { items_qty },
            earnPoints,
            balance,
            moneyBalance: { value, currency } = {},
        } = this.props;

        if (pathname === '/cart' && items_qty === 0) return null;

        return children && Array.isArray(children) && children.length ? (
            <div block="ShowRewardPoints">
                <div block="ShowRewardPoints" elem="Icon">
                    <Icons name="coins" width="24" height="24" />
                </div>
                <div block="ShowRewardPoints" elem="Content">
                    {children.map((item) => {
                        if (item.type === 'span' && item?.props?.children) {
                            let replacedValues = item.props.children
                                .replace(/{user_points}/g, `${balance} ${getPluralPointsTranslation(balance)}`)
                                .replace(/{valueInCurrency}/g, formatPrice(value, currency));

                            if (pathname === '/cart') {
                                replacedValues = item.props.children.replace(
                                    /{user_points}/g,
                                    `${earnPoints} ${getPluralPointsTranslation(earnPoints)}`
                                );
                            }

                            return (
                                <span block="ShowRewardPoints" elem="TextBold">
                                    {replacedValues}
                                </span>
                            );
                        }

                        return item.toString();
                    })}
                </div>
            </div>
        ) : null;
    }

    render() {
        const { isSignedIn, canShow } = this.props;
        if (!isSignedIn || !canShow) return null;

        return this.renderContent();
    }
}

export default ShowRewardPoints;
