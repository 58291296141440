import PropTypes from 'prop-types';

import { ButtonComponent as SourceButtonComponent } from 'AdvoxComponent/Button/Button.component';

/** @namespace Pwa/Component/Button/Component */
export class ButtonComponent extends SourceButtonComponent {
    static propTypes = {
        ...SourceButtonComponent.propTypes,
        variant: PropTypes.oneOf([
            'primary',
            'secondary',
            'gray',
            'outline',
            'outlineInverse',
            'outlineDark',
            'outlineWhite',
            'outlineDarkTransparentBg',
            'likeLink',
            'iconOnly',
        ]),
    };
}

export default ButtonComponent;
