import SlickSlider from 'react-slick';

import ProductCard from 'Component/ProductCard';
import { DEFAULT_SLICK_SETTINGS } from 'Component/ProductLinks/ProductLinks.config';
import SliderNextArrow from 'Component/SliderNextArrow';
import SliderPrevArrow from 'Component/SliderPrevArrow';
import TypographyHeader from 'Component/TypographyHeader';
import { ProductLinks as SourceProductLinks } from 'SourceComponent/ProductLinks/ProductLinks.component';

import { SLIDES_TO_SHOW_DESKTOP } from './ProductLinks.config';

import './ProductLinks.style';

/** @namespace Pwa/Component/ProductLinks/Component */
export class ProductLinks extends SourceProductLinks {
    renderProductCard(item) {
        const { sku } = item;
        return (
            <div block="ProductLinksSlider" elem="Item" key={`productlinks-${sku}`}>
                <ProductCard product={item} sliderMode />
            </div>
        );
    }

    render() {
        const { areDetailsLoaded, title } = this.props;

        const {
            linkType,
            linkedProducts: {
                [linkType]: { items },
            },
        } = this.props;

        if (!areDetailsLoaded) {
            return null;
        }

        return (
            <div block="ProductLinksSlider">
                <TypographyHeader tag="p" variant="normal" mix={{ block: 'ProductLinksSlider', elem: 'Title' }}>
                    {title}
                </TypographyHeader>
                <SlickSlider
                    prevArrow={<SliderPrevArrow />}
                    nextArrow={<SliderNextArrow />}
                    {...DEFAULT_SLICK_SETTINGS}
                    {...(items.length < SLIDES_TO_SHOW_DESKTOP ? { infinite: false } : {})}
                >
                    {items.map(this.renderProductCard.bind(this))}
                </SlickSlider>
            </div>
        );
    }
}

export default ProductLinks;
