import { Accordion as SourceAccordion } from 'AdvoxComponent/Accordion/Accordion.component';

/** @namespace Pwa/Component/Accordion/Component */
export class Accordion extends SourceAccordion {
    render() {
        const { dataDevice, children, setRef } = this.props;

        return (
            <div block="Accordion" data-device={dataDevice} ref={setRef}>
                {children}
            </div>
        );
    }
}

export default Accordion;
