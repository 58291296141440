import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ShowHideText from './ShowHideText.component';

/** @namespace Pwa/Component/ShowHideText/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/ShowHideText/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace Pwa/Component/ShowHideText/Container */
export class ShowHideTextContainer extends PureComponent {
    render() {
        return <ShowHideText {...this.props} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowHideTextContainer);
