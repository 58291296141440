export * from 'AdvoxComponent/BannerSlider/BannerSlider.config';

export const CTA_LINK_TYPE_BANNER = 'banner';

export const DEFAULT_SLICK_SETTINGS = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 1023,
            settings: {
                dots: true,
                arrows: false,
            },
        },
    ],
};
