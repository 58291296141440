/** @namespace Pwa/Util/Checkout/Observable */
export class CheckoutObservable {
    observers = new Map();

    subscribe(event, callback) {
        this.observers.set(event, callback);
    }

    unsubscribe(event) {
        this.observers.delete(event);
    }

    notify(trigger, data) {
        this.observers.forEach((callback, event) => {
            if (trigger === event) {
                callback(data);
            }
        });
    }
}

export const PAYMENT_ADDRESS_EVENT = 'PAYMENT_ADDRESS_EVENT';
export const PAYMENT_METHOD_EVENT = 'PAYMENT_METHOD_EVENT';
export const DELIVERY_ADDRESS_EVENT = 'DELIVERY_ADDRESS_EVENT';
export const TERMS_EVENT = 'TERMS_EVENT';

export default new CheckoutObservable();
