import { PureComponent } from 'react';
import { connect } from 'react-redux';

import PromotedCategoriesQuery from 'Query/PromotedCategories.query';
import { fetchQuery } from 'Util/Request';

import PromotedCategories from './PromotedCategories.component';

/** @namespace Pwa/Component/PromotedCategories/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/PromotedCategories/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace Pwa/Component/PromotedCategories/Container */
export class PromotedCategoriesContainer extends PureComponent {
    state = {
        categories: [],
        isContentExpanded: false,
    };

    containerFunctions = {
        toggleExpand: this.toggleExpand.bind(this),
    };

    componentDidMount() {
        this.getPromotedCategories();
    }

    toggleExpand() {
        this.setState(({ isContentExpanded }) => ({
            isContentExpanded: !isContentExpanded,
        }));
    }

    async getPromotedCategories() {
        await fetchQuery(PromotedCategoriesQuery.getQuery()).then(
            /** @namespace Pwa/Component/PromotedCategories/Container/PromotedCategoriesContainer/getPromotedCategories/fetchQuery/then */
            ({ categories: { items } }) => this.setState({ categories: items })
        );
    }

    containerProps() {
        return {
            ...this.props,
            ...this.state,
        };
    }

    render() {
        return <PromotedCategories {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotedCategoriesContainer);
