/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getGuestQuoteId } from '@scandipwa/scandipwa/src/util/Cart';

import { DL_VAL_CHECKOUT_BILLING_STEP, DL_VAL_CHECKOUT_SHIPPING_STEP } from '../../data/checkout';
import { fireCheckoutEvent, fireCheckoutOptionEvent, firePurchaseEvent } from '../../event/checkout';

const addFireCheckoutOptionEventForBilling = (args, callback, instance) => {
    callback(...args);

    const { paymentMethods } = instance.props;
    const { paymentMethod } = instance.state;

    const selectedPaymentMethodTitle = paymentMethods.find(
        ({ code }) =>
            code === paymentMethod
    )?.title;

    fireCheckoutOptionEvent(DL_VAL_CHECKOUT_BILLING_STEP, selectedPaymentMethodTitle);
};

const addFireCheckoutOptionEventForShipping = (args, callback, instance) => {
    callback(...args);

    const { shippingMethods, selectedShippingMethod } = instance.props;

    const selectedShippingMethodTitle = shippingMethods.find(
        ({ method_code }) =>
            method_code === selectedShippingMethod
    )?.method_title;

    fireCheckoutOptionEvent(DL_VAL_CHECKOUT_SHIPPING_STEP, selectedShippingMethodTitle);
};

const addFireCheckoutEventForShipping = (args, callback) => {
    callback(...args);
    fireCheckoutEvent();
};

const addFirePurchaseEvent = (args, callback) => {
    const [order] = args;

    const guestQuoteId = getGuestQuoteId();
    const {
        placeOrder: {
            order: { order_number },
        },
    } = order;

    // ^^^ getting this here, as it will soon be reset
    firePurchaseEvent(order_number, guestQuoteId);
    // vvv This must be called after guest quote id is obtained
    callback(...args);
};

export default {
    'Component/CheckoutBilling/Container': {
        'member-function': {
            componentDidMount: addFireCheckoutOptionEventForShipping,
            onBillingSuccess: addFireCheckoutOptionEventForBilling,
        },
    },
    'Component/CheckoutShipping/Container': {
        'member-function': {
            componentDidMount: addFireCheckoutEventForShipping,
        },
    },
    'Advox/Route/Checkout/Container': {
        'member-function': {
            setDetailsStep: addFirePurchaseEvent,
        },
    },
};
