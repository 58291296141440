import { getFormattedRegion } from 'Util/Address';

export * from 'AdvoxComponent/MyAccountAddressTable/MyAccountAddressTable.table';

/** @namespace Pwa/Component/MyAccountAddressTable/Table/getAddressTablePairArray */
export const getAddressTablePairArray = (props) => {
    const {
        title: { value },
        address,
        countries,
        showAdditionalFields,
    } = props;

    const regionData = getFormattedRegion(address, countries);

    const additionalFields = [
        {
            key: 'city',
            label: __('Town'),
            source: address,
        },
        {
            key: 'country',
            label: __('Country'),
            source: regionData,
        },
        {
            key: 'vat_id',
            label: __('VAT Number'),
            source: address,
        },
    ];

    const billingFields = [
        {
            key: 'city',
            label: __('Town'),
            source: address,
        },
        {
            key: 'company',
            label: __('Company name'),
            source: address,
        },
        {
            key: 'vat_id',
            label: __('VAT Number'),
            source: address,
        },
    ];

    return [
        {
            key: 'firstname',
            label: __('First name'),
            source: address,
        },
        {
            key: 'lastname',
            label: __('Last name'),
            source: address,
        },
        {
            key: 'street',
            label: __('Street'),
            source: address,
        },
        {
            key: 'city',
            label: __('Town'),
            source: address,
        },
        {
            key: 'postcode',
            label: __('Postal code'),
            source: address,
        },
        {
            key: 'telephone',
            label: __('Phone number'),
            source: address,
        },
        ...(value === 'Billing address' ? billingFields : []),
        ...(showAdditionalFields ? additionalFields : []),
    ];
};
