import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ReCaptcha from './ReCaptcha.component';

/** @namespace Pwa/Component/ReCaptcha/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    reCaptchaKey: state.ConfigReducer.g_recaptcha_recaptcha_public_key,
    isReCaptchaEnabled: state.ConfigReducer.g_recaptcha_recaptcha_enabled,
});

/** @namespace Pwa/Component/ReCaptcha/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/ReCaptcha/Container */
export class ReCaptchaContainer extends PureComponent {
    static propTypes = {
        onChangeValue: PropTypes.func.isRequired,
    };

    containerProps() {
        const { isReCaptchaEnabled, reCaptchaKey, onChangeValue } = this.props;
        return { isReCaptchaEnabled, reCaptchaKey, onChangeValue };
    }

    render() {
        return <ReCaptcha {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReCaptchaContainer));
