import { omit } from 'lodash/object';

import PRODUCT_TYPE from 'Component/Product/Product.config';
import { ADD_TO_CART, getBundleOptions } from 'Util/Product/Product';

export * from 'SourceUtil/Product/Transform';
export { default } from 'SourceUtil/Product/Transform';

/** @namespace Pwa/Util/Product/Transform/getMagentoProductsExcludeFields */
export const getMagentoProductsExcludeFields = (products, excludeFields = ['productId']) => {
    if (!Array.isArray(products)) {
        return [];
    }

    return products.map((product) => omit(product, excludeFields));
};

/** @namespace Pwa/Util/Product/Transform/magentoProductTransform */
export const magentoProductTransform = (
    action = ADD_TO_CART,
    product,
    quantity = 1,
    enteredOptions = [],
    selectedOptions = []
) => {
    const { sku, type_id: typeId, id: productId } = product || {};

    const productData = [];

    if (typeId === PRODUCT_TYPE.grouped && action === ADD_TO_CART) {
        if (Object.keys(quantity).length === 0) {
            return productData;
        }

        const { items } = product;
        const groupedProducts = [];

        items.forEach(({ product: { id } }) => {
            const { [id]: groupedQuantity = 0 } = quantity;
            groupedProducts.push(btoa(`grouped/${id}/${groupedQuantity}`));
        });

        productData.push({
            sku,
            quantity: 1,
            selected_options: [...selectedOptions, ...groupedProducts],
            entered_options: enteredOptions,
            productId,
        });
    } else {
        const baseProductToAdd = {
            sku,
            quantity,
            productId,
            selected_options: selectedOptions,
            entered_options: enteredOptions,
        };

        productData.push(baseProductToAdd);
    }

    return productData;
};

/** @namespace Pwa/Util/Product/Transform/getAutoSelectedOptions */
export const getAutoSelectedOptions = (bundle_options, items) => {
    const bundleOptions = getBundleOptions(bundle_options, items);

    const autoSelectedOptions = bundleOptions.reduce((selectedOptions, option) => {
        const { options } = option;
        return [...selectedOptions, options[0].uid];
    }, []);

    return autoSelectedOptions;
};
