/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import MyAccountLoader from '../component/MyAccountLoader';
import SocialLoginButtons from '../component/SocialLoginButtons';

const addSocialButtonsToRender = (args, callback, _instance) => (
    <>
        { callback(...args) }
        <SocialLoginButtons />
    </>
);

const addMyAccountLoaderToRender = (args, callback, _instance) => (
    <>
        { callback(...args) }
        <MyAccountLoader />
    </>
);

export const config = {
    'Component/MyAccountSignIn/Component': {
        'member-function': {
            render: addSocialButtonsToRender,
            renderSignInForm: addMyAccountLoaderToRender
        }
    },
    'Component/MyAccountCreateAccount/Component': {
        'member-function': {
            render: addSocialButtonsToRender,
            renderSignInForm: addMyAccountLoaderToRender
        }
    }


};

export default config;
