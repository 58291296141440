import PropTypes from 'prop-types';

import { KeyValueTable as AdvoxKeyValueTable } from 'AdvoxComponent/KeyValueTable/KeyValueTable.component';
import Field from 'Component/Field';

import './KeyValueTable.override.style';

/** @namespace Pwa/Component/KeyValueTable/Component */
export class KeyValueTable extends AdvoxKeyValueTable {
    static propTypes = {
        ...AdvoxKeyValueTable.propTypes,
        dataPairArray: PropTypes.array,
    };

    static defaultProps = {
        ...AdvoxKeyValueTable.defaultProps,
        dataPairArray: [],
    };

    renderHeading() {
        const { title, isSelected } = this.props;

        if (!title) {
            return null;
        }

        return (
            <tr>
                <th block="KeyValueTable" elem="Heading" colSpan={2}>
                    <Field type="radio" attr={{ checked: isSelected, name: 'option-item' }} />
                    {title}
                </th>
            </tr>
        );
    }

    renderTableRow(data) {
        const { key, prefix, label, component, source } = data ?? {};
        const value = this.getValueFromSource(data);
        return (
            <tr key={key}>
                <th>{prefix ? label + prefix : label}</th>
                <td>{typeof component !== 'function' ? value || '-' : component(value, source)}</td>
            </tr>
        );
    }

    get dataPairArray() {
        const { dataPairArray } = this.props;
        return dataPairArray ?? [];
    }
}

export default KeyValueTable;
