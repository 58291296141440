import CheckoutPayment from 'Component/CheckoutPayment';
import { CheckoutPayments as SourceCheckoutPayments } from 'SourceComponent/CheckoutPayments/CheckoutPayments.component';

import './CheckoutPayments.override.style';

/** @namespace Pwa/Component/CheckoutPayments/Component */
export class CheckoutPayments extends SourceCheckoutPayments {
    renderHeading() {
        return (
            <h2 block="Checkout" elem="SubHeading">
                <span>3.</span> {__('Payment method')}
            </h2>
        );
    }

    renderPayment(method) {
        const {
            selectPaymentMethod,
            selectedPaymentCode,
            currencyCode,
            advoxExtraFeeEnabled,
            advoxExtraFeeValue,
            advoxExtraFeeTaxEnabled,
            advoxExtraFeeValueIncludingTax,
        } = this.props;
        const { code } = method;

        return (
            <CheckoutPayment
                key={code}
                isSelected={selectedPaymentCode === code}
                method={method}
                onClick={selectPaymentMethod}
                currencyCode={currencyCode}
                advoxExtraFeeEnabled={advoxExtraFeeEnabled}
                advoxExtraFeeValue={advoxExtraFeeValue}
                advoxExtraFeeTaxEnabled={advoxExtraFeeTaxEnabled}
                advoxExtraFeeValueIncludingTax={advoxExtraFeeValueIncludingTax}
            />
        );
    }

    renderNoPaymentOptions() {
        return (
            <p block="CheckoutPayment" elem="NoOptions">
                {__('There are no payment methods available.')}
            </p>
        );
    }

    sortPaymentMethods(paymentMethods) {
        const payPo = paymentMethods.find(({ code }) => code === 'przelewy24_227');
        const payUIndex = paymentMethods.findIndex(({ code }) => code === 'payu_gateway');

        if (payPo && payUIndex) {
            const paymentMethodsWithoutPayPo = paymentMethods.filter(({ code }) => code !== 'przelewy24_227');

            return paymentMethodsWithoutPayPo.toSpliced(payUIndex, 0, payPo);
        }

        return paymentMethods;
    }

    renderPayments() {
        const { paymentMethods } = this.props;

        if (!paymentMethods.length) {
            return this.renderNoPaymentOptions();
        }

        return this.sortPaymentMethods(paymentMethods).map(this.renderPayment);
    }
}

export default CheckoutPayments;
