/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { lazy, Suspense } from 'react';

export const ExtraFee = lazy(() =>
    import(
        /* webpackMode: "lazy", webpackChunkName: "social-share" */
        '../component/ExtraFee'
    )
);

const renderExtraFee = (props) => {
    const { checkoutStep } = props;

    return (
        <Suspense fallback={null}>
            <ExtraFee checkoutStep={checkoutStep} />
        </Suspense>
    );
};

const renderShipping = (args, callback, instance) => (
    <>
        {callback.apply(instance, args)}
        {renderExtraFee(instance.props)}
    </>
);

export const config = {
    'Pwa/Component/CheckoutOrderSummary/Component': {
        'member-function': {
            renderShipping,
        },
    },
};

export default config;
