import { connect } from 'react-redux';

import {
    CatalogWidgetContainer as SourceCatalogWidgetContainer,
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'AdvoxComponent/CatalogWidget/CatalogWidget.container';
import CatalogWidgetQuery from 'Query/CatalogWidget.query';
import { getIndexedProduct } from 'Util/Product';

/** @namespace Pwa/Component/CatalogWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace Pwa/Component/CatalogWidget/Container */
export class CatalogWidgetContainer extends SourceCatalogWidgetContainer {
    __construct(props) {
        const { code } = props;

        super.__construct(props, `CatalogWidget-${code}`);
    }

    state = {
        items: [],
        title: null,
        isLoading: false,
        isInspirationGrid: false,
    };

    performDataTransformations(data) {
        return data.map(({ image, ...rest }) => ({
            ...getIndexedProduct(rest),
            image: image || {},
            small_image: image || {},
        }));
    }

    async getCatalogWidgetData() {
        const { code } = this.props;
        this.setState({ isLoading: true });

        await this.fetchData(
            [CatalogWidgetQuery.getCatalogWidgetQuery(code, { isPlp: true, noVariantAttributes: true })],
            ({ catalogWidget: { items = [], widget_code } = {} } = {}) => {
                if (!items.length) {
                    this.setState({ isLoading: false });
                    return;
                }
                this.setState({
                    items: this.performDataTransformations(items),
                    isLoading: false,
                    isInspirationGrid: widget_code.includes('grid'),
                });
            },
            () => {
                this.setState({
                    items: [],
                    isLoading: false,
                    isInspirationGrid: false,
                });
            }
        );
    }

    containerProps() {
        const { items, isLoading, isInspirationGrid } = this.state;
        const { variant } = this.props;

        return {
            items,
            isLoading,
            isInspirationGrid,
            variant,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CatalogWidgetContainer);
