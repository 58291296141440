import { getInitialState, reduceFilters } from 'SourceStore/ProductListInfo/ProductListInfo.reducer';
import { UPDATE_INFO_LOAD_STATUS, UPDATE_PRODUCT_LIST_INFO } from 'Store/ProductListInfo/ProductListInfo.action';

/** @namespace Pwa/Store/ProductListInfo/Reducer/ProductListReducer */
export const ProductListReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case UPDATE_PRODUCT_LIST_INFO: {
            const {
                selectedFilter,
                products: {
                    filters: availableFilters = [],
                    min_price,
                    max_price,
                    sort_fields: sortFields,
                    tracker_variables: trackerVariables,
                } = {},
            } = action;

            return {
                ...state,
                filters: reduceFilters(availableFilters),
                sortFields,
                minPrice: Math.floor(min_price),
                maxPrice: Math.ceil(max_price),
                isLoading: false,
                selectedFilter,
                trackerVariables,
            };
        }

        case UPDATE_INFO_LOAD_STATUS: {
            const { isLoading } = action;

            return {
                ...state,
                isLoading,
            };
        }

        default:
            return state;
    }
};

export default ProductListReducer;
