import { UPDATE_PRODUCT_LIST_ITEMS } from 'SourceStore/ProductList/ProductList.action';

export * from 'SourceStore/ProductList/ProductList.action';

/** @namespace Pwa/Store/ProductList/Action/updateProductListItems */
export const updateProductListItems = (items, currentPage, total_count, total_pages, tracker_variables, args) => ({
    type: UPDATE_PRODUCT_LIST_ITEMS,
    items,
    currentPage,
    total_pages,
    total_count,
    tracker_variables,
    args,
});
