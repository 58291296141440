import React from 'react';

import Field from 'Component/Field';
import { CheckoutPayment as SourceCheckoutPayment } from 'SourceComponent/CheckoutPayment/CheckoutPayment.component';
import { formatPrice } from 'Util/Price';

import banktransfer from '../../assets/icons/banktransfer.png';
import przelewy24_154 from '../../assets/icons/blik.png';
import cashondelivery from '../../assets/icons/cashondelivery.png';
import przelewy24 from '../../assets/icons/P24.png';
import przelewy24_266 from '../../assets/icons/p24now-logo.png';
import przelewy24_227 from '../../assets/icons/paypo.png';
import payu_gateway from '../../assets/icons/payu_small.png';

import './CheckoutPayment.style';

/** @namespace Pwa/Component/CheckoutPayment/Component */
export class CheckoutPayment extends SourceCheckoutPayment {
    renderTitleWithExtraInfo(title) {
        const splitedTitle = title.split('|');

        return (
            <>
                <span>{splitedTitle[0]}</span>
            </>
        );
    }

    renderExtraFeePrice() {
        const {
            advoxExtraFeeEnabled,
            advoxExtraFeeValue,
            advoxExtraFeeTaxEnabled,
            advoxExtraFeeValueIncludingTax,
            currencyCode,
        } = this.props;

        if (+advoxExtraFeeEnabled) {
            if (+advoxExtraFeeTaxEnabled) {
                return formatPrice(advoxExtraFeeValueIncludingTax, currencyCode);
            }

            return formatPrice(advoxExtraFeeValue, currencyCode);
        }

        return null;
    }

    render() {
        const {
            isSelected,
            method: { title, code },
            currencyCode,
        } = this.props;

        const icons = {
            przelewy24_154: {
                img: przelewy24_154,
            },
            przelewy24_266: {
                img: przelewy24_266,
            },
            przelewy24_227: {
                img: przelewy24_227,
            },
            banktransfer: {
                img: banktransfer,
            },
            cashondelivery: {
                img: cashondelivery,
            },
            przelewy24: {
                img: przelewy24,
            },
            payu_gateway: {
                img: payu_gateway,
            },
        };

        return (
            <li block="CheckoutPayment" mods={{ isActive: isSelected }}>
                <button
                    block="CheckoutPayment"
                    mods={{ type: code }}
                    elem="Button"
                    type="button"
                    onClick={this.onClick}
                >
                    <Field
                        type="radio"
                        attr={{
                            id: `option-${code}`,
                            name: `option-${code}`,
                            checked: isSelected,
                        }}
                    />
                    <div block="CheckoutPayment" elem="Row">
                        <div block="CheckoutPayment" elem={`IconWithName ${code}`}>
                            {icons[code] && (
                                <div block="CheckoutPayment" elem="PaymentMethodIconWrapper">
                                    <img
                                        block="CheckoutPayment"
                                        elem={`PaymentMethodIcon ${code}`}
                                        src={icons[code].img}
                                        alt={title}
                                    />
                                </div>
                            )}
                            <span block="CheckoutDeliveryOption" elem="Title">
                                {!title.includes('|') ? title : this.renderTitleWithExtraInfo(title)}
                            </span>
                        </div>
                        <div block="CheckoutDeliveryOption" elem="Price">
                            {code === 'cashondelivery' ? this.renderExtraFeePrice() : formatPrice(0, currencyCode)}
                        </div>
                    </div>
                </button>
            </li>
        );
    }
}

export default CheckoutPayment;
