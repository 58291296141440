import { getPluralTranslation } from 'Util/Translations';

/** @namespace Pwa/Util/Rewards/Points/getPluralPointsTranslation */
export const getPluralPointsTranslation = (amount) => {
    if (amount === 0) {
        return getPluralTranslation('point', 'zero');
    }

    if (amount === 1) {
        return getPluralTranslation('point', 'one');
    }

    if ((amount % 100 > 20 || amount % 100 < 10) && [2, 3, 4].includes(amount % 10)) {
        return getPluralTranslation('point', 'few');
    }

    return getPluralTranslation('point', 'many');
};

/** @namespace Pwa/Util/Rewards/Points/getPluralUsedPointsTranslation */
export const getPluralUsedPointsTranslation = (amount) => {
    if (amount === 0) {
        return getPluralTranslation('point_were_used', 'zero');
    }

    if (amount === 1) {
        return getPluralTranslation('point_were_used', 'one');
    }

    if ((amount % 100 > 20 || amount % 100 < 10) && [2, 3, 4].includes(amount % 10)) {
        return getPluralTranslation('point_were_used', 'few');
    }

    return getPluralTranslation('point_were_used', 'many');
};
