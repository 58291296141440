import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { CategoryTreeType } from 'Type/Category.type';

import CategoryTree from './CategoryTree.component';

/** @namespace Pwa/Component/CategoryTree/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    category: state.CategoryReducer.category,
    showProductCount: state.ConfigReducer.layered_navigation_product_count_enabled,
});

/** @namespace Pwa/Component/CategoryTree/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
});

/** @namespace Pwa/Component/CategoryTree/Container */
export class CategoryTreeContainer extends PureComponent {
    static propTypes = {
        category: CategoryTreeType.isRequired,
        heading: PropTypes.string,
        showProductCount: PropTypes.bool,
    };

    static defaultProp = {
        category: {},
        heading: __('Categories'),
        showProductCount: false,
    };

    render() {
        const { category, heading } = this.props;
        const hasParentCategory = !!(category.breadcrumbs && category.breadcrumbs.length);
        const parentCategory = hasParentCategory ? category.breadcrumbs.slice(-1)[0] : {};

        return (
            <CategoryTree
                {...this.props}
                hasParentCategory={hasParentCategory}
                parentCategory={parentCategory}
                heading={heading}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTreeContainer);
