/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getCartItemsData } from './cart';
import { DL_VAL_PAGE_CHECKOUT } from './page';

export const DL_VAL_CHECKOUT_SHIPPING_STEP = 1;
export const DL_VAL_CHECKOUT_BILLING_STEP = 2;

/** @namespace Scandiweb/GoogleTagManager/Data/Checkout/getCheckoutEventData */
export const getCheckoutEventData = async () => ({
    ecommerce: await getCartItemsData(),
});

/** @namespace Scandiweb/GoogleTagManager/Data/Checkout/getCheckoutOptionEventData */
export const getCheckoutOptionEventData = async () => ({
    ecommerce: await getCartItemsData()
});
