/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';

const addAvailableSocialsToInitialState = (args, callback) => {
    return {
        ...callback(...args),
        availableSocials: []
    }
   
};

const getAvailableSocialsFromAction = (args, callback) => {
    const result = callback(...args);
    const [, action] = args;

    const {
        type,
        config: {
            availableSocials
        } = {}
    } = action;

    if (type !== UPDATE_CONFIG) {
        return result;
    }

    return {
        ...result,
        availableSocials
    };
};


export default {
    'Advox/Store/Config/Reducer/getInitialState': {
        function: addAvailableSocialsToInitialState
    },'Advox/Store/Config/Reducer/ConfigReducer': {
        function: getAvailableSocialsFromAction
    }
};
