/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';
import Image from 'Component/Image';
import Link from 'Component/Link';

import { getPostUrl } from '../../util/Posts';

import './BlogPostCard.style';

/** @namespace Altom/Blog/Component/BlogPostCard/Component/BlogPostCardComponent */
export class BlogPostCardComponent extends PureComponent {
    static propTypes = {
        post: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        isPlaceholder: PropTypes.bool,
        isCompactOnMobile: PropTypes.bool,
        isRelated: PropTypes.bool,
    };

    static defaultProps = {
        post: false,
        isPlaceholder: false,
        isCompactOnMobile: false,
        isRelated: false,
    };

    renderImage() {
        const {
            post: { first_image, title },
            post,
        } = this.props;

        if (!first_image) {
            return null;
        }

        return (
            <Link
                to={getPostUrl(post)}
                title={__('View %s', title)}
                mix={{ block: 'BlogPostCard', elem: 'ImageWrapper' }}
            >
                <Image mix={{ block: 'BlogPostCard', elem: 'Image' }} ratio="square" src={first_image} />
            </Link>
        );
    }

    renderTitle() {
        const {
            post: { title },
            post,
            isRelated,
        } = this.props;

        if (!title) {
            return null;
        }

        return (
            <Link to={getPostUrl(post)}>
                <div block="BlogPostCard" elem="Title" mods={{ isRelated }}>
                    {title}
                </div>
            </Link>
        );
    }

    renderDescription() {
        const {
            post: { short_content },
        } = this.props;

        if (!short_content) {
            return null;
        }

        return <Html content={short_content} />;
    }

    renderContents() {
        const { isPlaceholder, isRelated } = this.props;

        if (isPlaceholder) {
            return null;
        }

        return (
            <div block="BlogPostCard" elem="ContentWrapper" mods={{ expand: true, isRelated }}>
                {this.renderImage()}
                <div block="BlogPostCard" elem="Details" mods={{ isRelated }}>
                    {this.renderTitle()}
                </div>
            </div>
        );
    }

    render() {
        const { isPlaceholder, isCompactOnMobile } = this.props;
        return (
            <div block="BlogPostCard" mods={{ isLoading: isPlaceholder, isCompactOnMobile }}>
                {this.renderContents()}
            </div>
        );
    }
}

export default BlogPostCardComponent;
