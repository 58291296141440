export const SLIDES_TO_SHOW_DESKTOP = 3;

export const STATIC_SLIDER_WIDGET_SLICK_SETTINGS = {
    lazyLoad: false,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                lazyLoad: false,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                slidesToShow: 2,
                lazyLoad: false,
            },
        },
        {
            breakpoint: 99999,
            settings: {
                swipeToSlide: true,
                slidesToShow: SLIDES_TO_SHOW_DESKTOP,
                slidesToScroll: SLIDES_TO_SHOW_DESKTOP,
            },
        },
    ],
};
