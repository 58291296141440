import { ProductAttributeValue as SourceProductAttributeValue } from 'AdvoxComponent/ProductAttributeValue/ProductAttributeValue.component';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';

import './ProductAttributeValue.override.style';

/** @namespace Pwa/Component/ProductAttributeValue/Component */
export class ProductAttributeValue extends SourceProductAttributeValue {
    renderColorValue(color, label, count) {
        const { isFormattedAsText, isSelected } = this.props;
        const isLight = this.getIsColorLight(color);

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const style = {
            '--option-background-color': color,
            '--option-border-color': isLight ? '#a1a1a1' : color,
            '--option-check-mark-background': isLight ? '#000' : '#fff',
            // stylelint-disable-next-line value-keyword-case
            '--option-is-selected': isSelected ? 1 : 0,
        };

        if (isSelected) {
            style['--option-border-color'] = '#a8959a';
        }

        return (
            <data block="ProductAttributeValue" elem="ColorSwatch" title={label} mods={{ isSelected }}>
                <div block="ProductAttributeValue" elem="ColorSwatchColor" style={style} />
                {this.renderDropdown(label, count)}
            </data>
        );
    }

    renderImageValue(img, label, count) {
        const { isFormattedAsText, isSelected } = this.props;

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const style = {
            // stylelint-disable-next-line value-keyword-case
            '--option-check-mark-background': '#fff',
            '--option-is-selected': isSelected ? 1 : 0,
        };

        if (isSelected) {
            style['--option-border-color'] = '#a8959a';
        }

        return (
            <data block="ProductAttributeValue" elem="ImageSwatch" title={label} mods={{ isSelected }}>
                <div block="ProductAttributeValue" elem="ImageSwatchImage" style={style}>
                    <img src={`/media/attribute/swatch${img}`} alt={label} />
                </div>
                {this.renderDropdown(label, count)}
            </data>
        );
    }

    renderDropdown(value, subLabel) {
        const {
            isSelected,
            attribute: { attribute_code },
        } = this.props;

        const trimedValue = value.replaceAll(' ', '');
        return (
            <Field
                type={FIELD_TYPE.checkbox}
                attr={{
                    id: `${attribute_code}${trimedValue}`,
                    name: `${attribute_code}${trimedValue}`,
                    checked: isSelected,
                }}
                events={{ onChange: () => {} }}
                label={this.getCheckboxLabel(value, subLabel)}
            />
        );
    }

    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            showProductAttributeAsLink,
        } = this.props;

        if (attribute_code && !attribute_value) {
            return null;
        }

        const href = getLink(attribute);
        // Invert to apply css rule without using not()
        const isNotAvailable = !isAvailable;

        if (isFormattedAsText) {
            return (
                <div block="ProductAttributeValue" mix={mix}>
                    {this.renderAttributeByType()}
                </div>
            );
        }

        if (!showProductAttributeAsLink) {
            return (
                <span
                    block="ProductAttributeValue"
                    mods={{ isNotAvailable }}
                    onClick={this.clickHandler}
                    onKeyDown={this.clickHandler}
                    role="link"
                    tabIndex="-1"
                    aria-hidden={isNotAvailable}
                    mix={mix}
                >
                    {this.renderAttributeByType()}
                </span>
            );
        }

        return (
            <>
                {/* eslint-disable-next-line react/forbid-elements */}
                <a
                    href={href}
                    block="ProductAttributeValue"
                    mods={{ isNotAvailable }}
                    onClick={this.clickHandler}
                    aria-hidden={isNotAvailable}
                    mix={mix}
                    rel="nofollow"
                >
                    {this.renderAttributeByType()}
                </a>
            </>
        );
    }
}

export default ProductAttributeValue;
