import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    ProductWishlistButtonContainer as SourceProductWishlistButtonContainer,
} from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';
import { showNotification } from 'Store/Notification/Notification.action';
import { isSignedIn } from 'Util/Auth';
import { getNotificationPositions } from 'Util/Notification/Options';

export * from 'SourceComponent/ProductWishlistButton/ProductWishlistButton.container';

/** @namespace Pwa/Component/ProductWishlistButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message, debug = null, options = {}) =>
        dispatch(showNotification(type, message, debug, options)),
});

/** @namespace Pwa/Component/ProductWishlistButton/Container */
export class ProductWishlistButtonContainer extends SourceProductWishlistButtonContainer {
    async toggleProductInWishlist(add = true) {
        const {
            magentoProduct,
            magentoProduct: [{ sku, productId }] = [],
            showNotification,
            addProductToWishlist,
            removeProductFromWishlist,
            wishlistId,
        } = this.props;

        const { isWishListToggle } = this.state;

        if (!isSignedIn()) {
            return showNotification('info', __('You must login or register to add items to your wishlist.'), null, {
                position: getNotificationPositions('ProductWishlistButton', productId),
            });
        }

        this.setWishlistButtonLoading(true);

        if (isWishListToggle) {
            return;
        }

        this.setState({ isWishListToggle: true });

        if (add) {
            await addProductToWishlist({
                items: magentoProduct,
                wishlistId,
            });

            this.setState({ isWishListToggle: false });

            return;
        }

        const wishlistItem = this.getWishlistItem(sku);

        if (!wishlistItem) {
            this.setState({ isWishListToggle: false });

            return;
        }

        const {
            wishlist: { id: itemId },
        } = wishlistItem;

        this.setState({ isWishListToggle: false });

        return removeProductFromWishlist({ item_id: itemId, productId });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductWishlistButtonContainer);
