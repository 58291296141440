import { Meta as SourceMeta } from 'SourceComponent/Meta/Meta.component';

/**
 * Page Meta data
 * @class Meta
 * @namespace Pwa/Component/Meta/Component */
export class Meta extends SourceMeta {
    renderMeta() {
        const { metadata } = this.props;

        return (
            <>
                {this.renderTitle()}
                {this.renderCanonical()}
                {metadata.map((tag) => {
                    const { name = null, property = null, content = null } = tag;
                    if (name === 'keywords') return null;

                    return <meta key={name || property} name={name} content={content} />;
                })}
            </>
        );
    }

    renderTitle() {
        const { default_title, title_prefix, title_suffix, title } = this.props;

        const titlePrefix = title_prefix ? `${title_prefix} | ` : '';
        const titleSuffix = title_suffix ? `${title_suffix}` : '';

        return <title>{`${titlePrefix}${title || default_title}${titleSuffix}`}</title>;
    }

    renderCanonical() {
        const { canonical_url } = this.props;

        if (!canonical_url) return null;

        return <link rel="canonical" href={canonical_url} />;
    }
}

export default Meta;
