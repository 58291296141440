import { Field } from 'Util/Query';

/** @namespace Pwa/Query/PromotedCategories/Query */
export class PromotedCategories {
    getQuery() {
        const query = new Field('categories')
            .addArgument('filters', 'CategoryFilterInput', { is_promoted: { eq: '1' } })
            .addField(this._getCategoriesFields());

        return query;
    }

    _getCategoriesFields() {
        return new Field('items').addFieldList(this._getItemsFields());
    }

    _getItemsFields() {
        return ['name', 'url', 'is_promoted'];
    }
}

export default new PromotedCategories();
