import BlogRecentPosts from '@altom/blog/src/component/BlogRecentPosts';
import BlogSearchBar from '@altom/blog/src/component/BlogSearchBar';
import React from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Pagination from 'Component/Pagination';

import '../route/PostsListing/PostsListing.style.scss';

export default {
    'Altom/Blog/Route/PostsListing/Component/PostsListingComponent': {
        'member-function': {
            renderTitle(args, callback, instance) {
                const { updateBreadcrumbs, getCategoryTitle } = instance.props;
                updateBreadcrumbs();
                return (
                    <h1 block="BlogListing" elem="Title">
                        {getCategoryTitle() || __('Magazyn')}
                    </h1>
                );
            },
            renderMainContent(args, callback, instance) {
                const { isLoaded, posts } = instance.props;

                return (
                    <>
                        { instance.renderTitle() }
                        <div block="BlogListing" elem="Grid">
                            { instance.renderGrid() }
                        </div>
                        <Pagination isLoading={!isLoaded} totalPages={posts.totalPages} />
                    </>
                );
            },

            render(args, callback, instance) {
                return (
                    <main block="BlogListing">
                        <ContentWrapper label="PostsListing">
                            <div block="BlogListing" elem="ColumnWrapper">
                                <div block="BlogListing" elem="GridWrapper">
                                    {instance.renderPostListingDetails() }
                                </div>
                                <div block="Blog" elem="Sidebar">
                                    <BlogSearchBar mix={{ block: 'BlogListing', elem: 'Search' }} />
                                    {instance.renderBlogCategoriesIfNotMobile()}
                                    {instance.renderMobileBlogCategories()}
                                    <BlogRecentPosts />
                                </div>
                            </div>
                        </ContentWrapper>
                    </main>
                );
            },
        }
    }
};
