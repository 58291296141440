import React from 'react';
import { usePopper } from 'react-popper';

/** @namespace Pwa/Util/Popper/WithPopper/withPopper */
export const withPopper = (Component) => (props) => {
    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const { placement = 'bottom-start', strategy = 'fixed', modifiers = [], ...rest } = props;

    const popper = usePopper(referenceElement, popperElement, {
        placement,
        strategy,
        modifiers,
    });

    return (
        <Component
            {...rest}
            popper={popper}
            setReferenceElement={setReferenceElement}
            setPopperElement={setPopperElement}
        />
    );
};
