import { decodeString } from 'Util/Common';

export * from 'SourceUtil/Url/Url';

/**
 * Check if environment is localhost.
 * Use only when node.env variable is useless.
 * @namespace Pwa/Util/Url/isLocalhost */
export const isLocalhost = () => process.env.NODE_ENV === 'development';

/** @namespace Pwa/Util/Url/getQueryParam */
export const getQueryParam = (variable, location) => {
    const query = decodeString(location.search.substring(1));
    const vars = query.split('&');

    return vars.reduce((acc, item) => {
        const splitIdx = item.indexOf('=');
        const [k, v] = [item.slice(0, splitIdx), item.slice(splitIdx + 1)];
        return k === variable ? v : acc;
    }, false);
};
