export * from 'SourceUtil/Request/Error';

export const GRAPHQL_AUTHORIZATION = 'graphql-authorization';

/** @namespace Pwa/Util/Request/Error/getErrorCategory */
export const getErrorCategory = (error) => {
    const { extensions } = error?.length ? error[0] : error || {};
    const { category } = extensions || {};

    return category;
};
