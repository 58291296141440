import { connect } from 'react-redux';

import NotificationList from 'Component/NotificationList/NotificationList.component';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceComponent/NotificationList/NotificationList.container';
import NotificationReducer from 'Store/Notification/Notification.reducer';
import { withReducers } from 'Util/DynamicReducer';

/** @namespace Pwa/Component/NotificationList/Container/getNotificationByPosition */
export const getNotificationByPosition = (state, props) =>
    Object.entries(state.NotificationReducer.notifications)?.reduce((notifications, [key, value]) => {
        if (value?.msgOptions?.position?.length > 0) {
            if (!value?.msgOptions?.position?.includes(props?.position)) {
                return notifications;
            }

            return {
                ...notifications,
                [key]: value,
            };
        }

        if (props?.position) {
            return notifications;
        }

        return {
            ...notifications,
            [key]: value,
        };
    }, {});

/** @namespace Pwa/Component/NotificationList/Container/mapStateToProps */
export const mapStateToProps = (state, props) => ({
    ...sourceMapStateToProps(state),
    notifications: getNotificationByPosition(state, props),
});

export default withReducers({
    NotificationReducer,
})(connect(mapStateToProps, mapDispatchToProps)(NotificationList));
