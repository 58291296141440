import { CheckoutBillingComponent as AdvoxCheckoutBilling } from 'AdvoxComponent/CheckoutBilling/CheckoutBilling.component';
import CheckoutAddressBook from 'Component/CheckoutAddressBook';
import CheckoutPayments from 'Component/CheckoutPayments';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Form from 'Component/Form';
import { STORE_IN_PICK_UP_METHOD_CODE } from 'Component/StoreInPickUp/StoreInPickUp.config';
import { BILLING_STEP } from 'Route/Checkout/Checkout.config';

import { PAYMENT_STEP } from '../../route/Checkout/Checkout.config';

export * from 'SourceComponent/CheckoutBilling/CheckoutBilling.component';

/** @namespace Pwa/Component/CheckoutBilling/Component */
export class CheckoutBilling extends AdvoxCheckoutBilling {
    renderSameAsShippingCheckbox() {
        const {
            isSameAsShipping,
            onSameAsShippingChange,
            totals: { is_virtual },
            selectedShippingMethod,
        } = this.props;

        if (is_virtual) {
            return null;
        }

        return (
            <Field
                type={FIELD_TYPE.checkbox}
                attr={{
                    id: 'sameAsShippingAddress',
                    name: 'sameAsShippingAddress',
                    value: 'sameAsShippingAddress',
                    checked: !isSameAsShipping && selectedShippingMethod !== STORE_IN_PICK_UP_METHOD_CODE,
                }}
                events={{
                    onChange: onSameAsShippingChange,
                }}
                mix={{ block: 'CheckoutBilling', elem: 'Checkbox', mods: { invoice: true } }}
                label={__('Check this label to receive an invoice')}
                onChange={onSameAsShippingChange}
                isDisabled={selectedShippingMethod === STORE_IN_PICK_UP_METHOD_CODE}
            />
        );
    }

    renderAddressBook() {
        const {
            onAddressSelect,
            isSameAsShipping,
            totals: { is_virtual },
            shippingAddress,
        } = this.props;

        if (isSameAsShipping && !is_virtual) {
            return null;
        }

        return (
            <CheckoutAddressBook
                shippingAddress={shippingAddress}
                onAddressSelect={onAddressSelect}
                isBilling
                is_virtual
            />
        );
    }

    renderPayments() {
        const {
            paymentMethods,
            onPaymentMethodSelect,
            setLoading,
            setDetailsStep,
            shippingAddress,
            selectedPaymentMethod,
            setSelectedPaymentMethod,
        } = this.props;

        return (
            <CheckoutPayments
                setLoading={setLoading}
                setDetailsStep={setDetailsStep}
                paymentMethods={paymentMethods}
                onPaymentMethodSelect={onPaymentMethodSelect}
                setOrderButtonVisibility={this.setOrderButtonVisibility}
                billingAddress={shippingAddress}
                setOrderButtonEnableStatus={this.setOrderButtonEnableStatus}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
            />
        );
    }

    renderSectionHeading() {
        const { isSameAsShipping } = this.props;

        if (isSameAsShipping) {
            return null;
        }

        return (
            <h2 block="Checkout" elem="SubHeading">
                {__('Invoice data')}
            </h2>
        );
    }

    renderAddresses() {
        return (
            <>
                {this.renderSameAsShippingCheckbox()}
                {this.renderSectionHeading()}
                {this.renderAddressBook()}
            </>
        );
    }

    render() {
        const { onBillingSuccess, onBillingError, onlyAddress, setPaymentAddressFormRef, setPaymentMethodsFormRef } =
            this.props;

        return onlyAddress ? (
            <Form
                attr={{
                    id: BILLING_STEP,
                }}
                mix={{ block: 'CheckoutBilling', mix: { block: 'CheckoutBilling', elem: 'AddressWrapper' } }}
                onError={onBillingError}
                onSubmit={onBillingSuccess}
                elemRef={setPaymentAddressFormRef}
            >
                {this.renderAddresses()}
            </Form>
        ) : (
            <Form
                attr={{
                    id: PAYMENT_STEP,
                }}
                mix={{ block: 'CheckoutBilling', mix: { block: 'CheckoutBilling', elem: 'MethodsWrapper' } }}
                onError={onBillingError}
                onSubmit={onBillingSuccess}
                elemRef={setPaymentMethodsFormRef}
            >
                {this.renderPayments()}
            </Form>
        );
    }
}

export default CheckoutBilling;
