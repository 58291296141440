import PropTypes from 'prop-types';
import React from 'react';

import { TabsComponent as SourceTabsComponent } from 'AdvoxComponent/Tabs/Tabs.component';

/** @namespace Pwa/Component/Tabs/Component */
export class TabsComponent extends SourceTabsComponent {
    static propTypes = {
        ...SourceTabsComponent.propTypes,
        tabsVariant: PropTypes.string,
    };

    renderChildren() {
        const { children, activeTab } = this.props;
        return React.Children.toArray(children).filter((child) => child?.type !== React.Fragment)[activeTab - 1];
    }

    render() {
        const { hashId, tabsVariant } = this.props;

        return (
            <div block="Tabs" mods={{ tabsVariant }} mix={{ block: `Tabs${hashId}` }}>
                <div block="Tabs" elem="Tabs">
                    {this.renderTabs()}
                </div>
                {this.renderChildren()}
            </div>
        );
    }
}

export default TabsComponent;
