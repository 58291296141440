import { createRef } from 'react';
import { connect } from 'react-redux';

import {
    CheckoutBillingContainer as AdvoxCheckoutBillingContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'AdvoxComponent/CheckoutBilling/CheckoutBilling.container';
import { STORE_IN_PICK_UP_METHOD_CODE } from 'Component/StoreInPickUp/StoreInPickUp.config';
import {
    getFormFields,
    setAddressesInFormObject,
    trimCheckoutAddress,
    trimCheckoutCustomerAddress,
} from 'Util/Address';
import CheckoutObservable, { PAYMENT_ADDRESS_EVENT, PAYMENT_METHOD_EVENT } from 'Util/Checkout';
import scrollToError from 'Util/Form/Form';
import transformToNameValuePair from 'Util/Form/Transform';

export { mapDispatchToProps, mapStateToProps };

/** @namespace Pwa/Component/CheckoutBilling/Container */
export class CheckoutBillingContainer extends AdvoxCheckoutBillingContainer {
    paymentAddressFormRef = createRef();

    paymentMethodsFormRef = createRef();

    containerFunctions = {
        ...this.containerFunctions,
        setPaymentMethodsFormRef: this.setPaymentMethodsFormRef.bind(this),
        setPaymentAddressFormRef: this.setPaymentAddressFormRef.bind(this),
        onBillingError: this.onBillingError.bind(this),
    };

    componentDidMount() {
        const { onlyAddress } = this.props;

        if (onlyAddress) {
            CheckoutObservable.subscribe(PAYMENT_ADDRESS_EVENT, this.handlePaymentAddressEvent.bind(this));
        } else {
            CheckoutObservable.subscribe(PAYMENT_METHOD_EVENT, this.handlePaymentMethodEvent.bind(this));
        }
    }

    handlePaymentAddressEvent() {
        this.paymentAddressFormRef.current?.dispatchEvent(new Event('submit', { cancelable: true }));
    }

    handlePaymentMethodEvent() {
        this.paymentMethodsFormRef.current?.dispatchEvent(new Event('submit', { cancelable: true }));
    }

    setPaymentMethodsFormRef(elem) {
        this.paymentMethodsFormRef.current = elem;
    }

    setPaymentAddressFormRef(elem) {
        this.paymentAddressFormRef.current = elem;
    }

    componentWillUnmount() {
        const { onlyAddress } = this.props;

        if (onlyAddress) {
            CheckoutObservable.unsubscribe(PAYMENT_ADDRESS_EVENT);
        } else {
            CheckoutObservable.unsubscribe(PAYMENT_METHOD_EVENT);
        }
    }

    onBillingSuccess(form, fields, asyncData) {
        const { savePaymentInformation, savePaymentMethodAndPlaceOrder, onlyAddress } = this.props;
        const { isSameAsShipping } = this.state;

        const extractedFields = transformToNameValuePair(fields);

        if (onlyAddress) {
            const address = this._getAddress(extractedFields);

            savePaymentInformation({
                billing_address: address,
                same_as_shipping: isSameAsShipping,
            });
        } else {
            const paymentMethod = this._getPaymentData(extractedFields, asyncData);
            const { customer_comment } = extractedFields;

            savePaymentMethodAndPlaceOrder(
                {
                    paymentMethod,
                },
                customer_comment
            );
        }
    }

    isSameShippingAddress({ default_billing, default_shipping }) {
        const {
            totals: { is_virtual },
            selectedShippingMethod,
            newShippingId,
            newShippingStreet,
        } = this.props;

        if (is_virtual) {
            return false;
        }

        const firstRule = !newShippingId && !newShippingStreet && default_billing === default_shipping;
        const secondRule = default_billing && parseInt(default_billing, 10) === newShippingId;

        return (firstRule || secondRule || !default_billing) && selectedShippingMethod !== STORE_IN_PICK_UP_METHOD_CODE;
    }

    _getAddress(fields) {
        const { addressLinesQty } = this.props;
        const { isSameAsShipping, selectedCustomerAddressId } = this.state;
        const formFields = getFormFields(fields, addressLinesQty);

        if (isSameAsShipping) {
            return this.getBillingSameAsShipping();
        }

        if (!selectedCustomerAddressId) {
            const joinedStreetAddressFields = setAddressesInFormObject(formFields, addressLinesQty, 'street_');

            return trimCheckoutAddress(joinedStreetAddressFields);
        }

        const {
            customer: { addresses },
        } = this.props;
        const address = addresses.find(({ id }) => id === selectedCustomerAddressId);

        return {
            ...trimCheckoutCustomerAddress(address),
            save_in_address_book: false,
        };
    }

    containerProps() {
        const { selectedPaymentMethod, setSelectedPaymentMethod, onlyAddress } = this.props;

        return {
            ...super.containerProps(),
            selectedPaymentMethod,
            setSelectedPaymentMethod,
            onlyAddress,
        };
    }

    onPaymentMethodSelect(code) {
        const { setSelectedPaymentMethod } = this.props;

        this.setState({ paymentMethod: code });
        setSelectedPaymentMethod(code);
    }

    onBillingError(_, fields, validation) {
        scrollToError(fields, validation);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutBillingContainer);
