import { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import SliderNextArrow from 'Component/SliderNextArrow';
import SliderPrevArrow from 'Component/SliderPrevArrow';

import { STATIC_SLIDER_WIDGET_SLICK_SETTINGS } from './StaticSlider.config';

import './StaticSlider.style';

/** @namespace Pwa/Component/StaticSlider/Component */
export class StaticSlider extends PureComponent {
    render() {
        const { children } = this.props;

        return (
            <div block="StaticSlider">
                <SlickSlider
                    prevArrow={<SliderPrevArrow variant="tertiary" />}
                    nextArrow={<SliderNextArrow variant="tertiary" />}
                    {...STATIC_SLIDER_WIDGET_SLICK_SETTINGS}
                >
                    {children.map((item) => (
                        <div block="StaticSlider" elem="Item">
                            {item}
                        </div>
                    ))}
                </SlickSlider>
            </div>
        );
    }
}

export default StaticSlider;
