import React, { PureComponent } from 'react';

import ProductLinks from 'Component/ProductLinks';
import { RELATED } from 'Store/LinkedProducts/LinkedProducts.reducer';

import './BlogRelatedProducts.style';

/** @namespace Altom/Blog/Component/BlogRelatedProducts/Component/BlogRelatedProductsComponent */
export class BlogRelatedProductsComponent extends PureComponent {
    render() {
        return (
            <div block="BlogRelatedProducts" elem="Wrapper">
                <ProductLinks linkType={RELATED} title={__('Check out these products')} />
            </div>
        );
    }
}

export default BlogRelatedProductsComponent;
