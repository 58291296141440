import CmsBlock from 'Component/CmsBlock';
import FIELD_TYPE from 'Component/Field/Field.config';
import { CHECKBOX_LABEL_CMS_BLOCK } from 'Component/NewsletterSubscription/NewsletterSubscription.config';
import { VALIDATION_INPUT_TYPE } from 'Util/Validator/Config';

export * from 'AdvoxComponent/NewsletterSubscription/NewsletterForm.form.js';

/* @namespace Pwa/Component/NewsletterSubscription/NewsletterForm/Form/newsletterSubscriptionForm */
export const newsletterSubscriptionForm = (inPopup) => [
    {
        type: FIELD_TYPE.email,
        attr: {
            id: 'newsletterEmail',
            name: 'newsletterEmail',
            placeholder: inPopup ? __('Enter email...') : __('Enter your email address'),
            'aria-label': __('Email address'),
        },
        validateOn: ['onSubmit'],
        validationRule: {
            inputType: VALIDATION_INPUT_TYPE.email,
            isRequired: true,
        },
    },
    {
        type: FIELD_TYPE.checkbox,
        label: <CmsBlock identifier={CHECKBOX_LABEL_CMS_BLOCK} />,
        attr: {
            id: inPopup ? 'popupNewsletterAgreement' : 'newsletterAgreement',
            name: inPopup ? 'popupNewsletterAgreement' : 'newsletterAgreement',
        },
        validateOn: ['onSubmit'],
        validationRule: {
            isRequired: true,
        },
    },
];

export default newsletterSubscriptionForm;
