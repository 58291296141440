/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import Overlay from 'Component/Overlay';
import TextPlaceholder from 'Component/TextPlaceholder';

import { getPostUrl } from '../../util/Posts';
import { BLOG_SEARCH } from '../BlogSearchBar/BlogSearchBar.config';

import './BlogSearchOverlay.style';

/** @namespace Altom/Blog/Component/BlogSearchOverlay/Component/BlogSearchOverlayComponent */
export class BlogSearchOverlayComponent extends PureComponent {
    static propTypes = {
        searchCriteria: PropTypes.string,
        isLoading: PropTypes.bool.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        searchResults: PropTypes.array.isRequired,
    };

    static defaultProps = {
        searchCriteria: '',
    };

    renderSearchItem(post, i) {
        const { title, first_image } = post;

        return (
            <li block="BlogSearchOverlay" elem="Item" key={i}>
                <Link block="BlogSearchOverlay" elem="Link" to={getPostUrl(post)}>
                    <figure block="BlogSearchOverlay" elem="Wrapper">
                        <Image
                            src={first_image}
                            ratio="16x9"
                            alt={__('Product %s thumbnail.', title)}
                            isPlaceholder={!first_image}
                        />
                        <figcaption block="BlogSearchOverlay" elem="Content">
                            <h4 block="BlogSearchOverlay" elem="Title" mods={{ isLoaded: !!name }}>
                                <TextPlaceholder content={title} length="medium" />
                            </h4>
                        </figcaption>
                    </figure>
                </Link>
            </li>
        );
    }

    renderSearchCriteria() {
        const { searchCriteria } = this.props;

        return (
            <p block="BlogSearchOverlay" elem="Criteria" mods={{ isVisible: !!searchCriteria }}>
                {__('Results for:')}
                <strong>{searchCriteria}</strong>
            </p>
        );
    }

    renderNoSearchCriteria() {
        return <p>{__('Start typing to search posts!')}</p>;
    }

    renderNoResults() {
        return <p>{__('No results found!')}</p>;
    }

    renderSearchResults() {
        const { searchCriteria, searchResults, isLoading } = this.props;

        if (!searchCriteria) {
            return this.renderNoSearchCriteria();
        }

        if (!searchResults.length && !isLoading && !this.timeout) {
            return this.renderNoResults();
        }

        const resultsToRender = isLoading || this.timeout ? Array(searchResults.length).fill({}) : searchResults;

        return <ul>{resultsToRender.map(this.renderSearchItem)}</ul>;
    }

    render() {
        return (
            <Overlay id={BLOG_SEARCH} mix={{ block: 'BlogSearchOverlay' }}>
                {this.renderSearchCriteria()}
                <article block="BlogSearchOverlay" elem="Results">
                    {this.renderSearchResults()}
                </article>
            </Overlay>
        );
    }
}

export default BlogSearchOverlayComponent;
