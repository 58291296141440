import { scrollToTop } from 'Util/Browser';
import { getQueryParam } from 'Util/Url';

export default {
    'Altom/Blog/Route/PostsListing/Container/PostsListingContainer': {
        'member-function': {
            componentDidUpdate(args, callback, instance) {
                const [prevProps] = args;
                const { location } = instance.props;
                const { location: prevLocation } = prevProps;

                callback.apply(instance, args);

                if (location.search !== prevLocation.search) {
                    scrollToTop();
                    instance.requestPosts();
                }
            },
            updateBreadcrumbs(args, callback, instance) {
                const { updateBreadcrumbs } = instance.props;
                const breadcrumbs = [
                    ...instance.getCategoryBreadcrumb(),
                    {
                        url: '/blog',
                        name: __('Blog'),
                    }
                ];

                updateBreadcrumbs(breadcrumbs);
            },
            updateMeta(args, callback, instance) {
                const { updateMeta } = instance.props;

                updateMeta({
                    title: __('Blog'),
                    canonical_url: instance.getCanonicalUrl()
                });
            },
            requestPosts(args, callback, instance) {
                const { options } = args;
                const { requestPosts, location } = instance.props;
                const { postsOptions } = instance.options;
                const currentPage = +(getQueryParam('page', location) || 1);

                requestPosts({ ...postsOptions, ...options, currentPage });
            }
        },
    },
};
