import { deleteAuthorizationToken, isInitiallySignedIn, isSignedIn } from 'SourceUtil/Auth/Token';
import BrowserDatabase from 'Util/BrowserDatabase';
import { debounce } from 'Util/Request';
import getStore from 'Util/Store';

export { deleteAuthorizationToken, isInitiallySignedIn, isSignedIn };

// export { isSignedIn, isInitiallySignedIn, deleteAuthorizationToken };

export const AUTH_TOKEN = 'auth_token';

export const ONE_HOUR_IN_SECONDS = 3600;
export const ONE_HOUR = 1;
export const TOKEN_REFRESH_DELAY = 5000;
export const REMEMBER_ME_TIME = 2500000000000000;

/** @namespace Pwa/Util/Auth/Token/setAuthorizationToken */
export const setAuthorizationToken = (token) => {
    if (!token) {
        return;
    }

    const state = getStore().getState();

    const {
        MyAccountReducer: { isRememberMe },
    } = state;

    const { access_token_lifetime = ONE_HOUR } = state.ConfigReducer;

    BrowserDatabase.setItem(
        token,
        AUTH_TOKEN,
        (isRememberMe ? REMEMBER_ME_TIME : access_token_lifetime) * ONE_HOUR_IN_SECONDS
    );
};

/** @namespace Pwa/Util/Auth/Token/getAuthorizationToken */
export const getAuthorizationToken = () => BrowserDatabase.getItem(AUTH_TOKEN);

/** @namespace Util/Auth/Token/refreshAuthorizationToken */
export const refreshAuthorizationToken = debounce(
    () => setAuthorizationToken(getAuthorizationToken()),
    TOKEN_REFRESH_DELAY
);
