/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

/** @namespace Altom/Blog/Util/Posts/getPostUrl */
// eslint-disable-next-line import/prefer-default-export
export function getPostUrl(post) {
    const { identifier } = post;

    return `/magazyn/${identifier}`;
}
