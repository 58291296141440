import PropTypes from 'prop-types';
import { PatternFormat } from 'react-number-format';

import { FieldComponent as AdvoxField } from 'AdvoxComponent/Field/Field.component';
import { FIELD_TYPE } from 'Component/Field/Field.config';

/** @namespace Pwa/Component/Field/Component */
export class Field extends AdvoxField {
    static propTypes = {
        ...AdvoxField.propTypes,
        hidden: PropTypes.bool,
    };

    static defaultProps = {
        ...AdvoxField.defaultProps,
        hidden: false,
    };

    renderMap = {
        ...this.renderMap,
        [FIELD_TYPE.tel]: this.renderDefaultInput.bind(this),
    };

    renderLabel() {
        const { type, label, attr: { name } = {}, showColon } = this.props;

        if (!label) {
            return null;
        }

        return (
            <div block="Field" elem="LabelContainer">
                <label block="Field" elem="Label" htmlFor={name || `input-${type}`}>
                    {`${label}${showColon ? ':' : ''}`}
                    {this.renderRequiredTag()}
                </label>
            </div>
        );
    }

    renderTextArea() {
        const { setRef, attr, events, isDisabled } = this.props;
        const { customPlaceholder } = attr ?? {};

        return (
            <div block="FieldTextarea">
                <textarea
                    ref={(elem) => setRef(elem)}
                    disabled={isDisabled}
                    placeholder={customPlaceholder ? ' ' : attr?.placeholder ?? ''}
                    {...attr}
                    {...events}
                />
                {customPlaceholder ? (
                    <span block="FieldTextarea" elem="Placeholder">
                        {customPlaceholder}
                    </span>
                ) : null}
            </div>
        );
    }

    renderDefaultInput() {
        const { type, setRef, attr, attr: { format, mask = '_' } = {}, events, isDisabled } = this.props;

        if (format) {
            return (
                <PatternFormat
                    {...attr}
                    {...events}
                    format={format}
                    mask={mask}
                    displayType="input"
                    getInputRef={(elem) => setRef(elem)}
                    disabled={isDisabled}
                    type={type}
                />
            );
        }

        return <input ref={(elem) => setRef(elem)} disabled={isDisabled} type={type} {...attr} {...events} />;
    }

    renderCheckboxOrRadio() {
        const { type, setRef, attr, attr: { id = '', checked = false } = {}, events, isDisabled, label } = this.props;

        const elem = type.charAt(0).toUpperCase() + type.slice(1);

        return (
            <label htmlFor={id} block="Field" elem={`${elem}Label`} mods={{ disabled: isDisabled, checked }}>
                <input ref={(elem) => setRef(elem)} disabled={isDisabled} type={type} {...attr} {...events} />
                <div block="input-control" mods={{ disabled: isDisabled }} />
                <div block="Field" elem={`${elem}LabelContent`}>
                    {label}
                </div>
            </label>
        );
    }

    render() {
        const { type, validationResponse, mix, variant, size, hidden } = this.props;
        const inputRenderer = this.renderMap[type];

        const isHidden = hidden ? { hidden: true, 'aria-hidden': true } : {};

        return (
            <div block="Field" elem="Wrapper" mods={{ type }} {...isHidden}>
                <div
                    block="Field"
                    mods={{
                        type,
                        isValid: validationResponse === true,
                        hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0,
                        variant,
                        size,
                    }}
                    mix={mix}
                >
                    {type !== FIELD_TYPE.checkbox && type !== FIELD_TYPE.radio && this.renderLabel()}
                    {inputRenderer && inputRenderer()}
                    {this.renderErrorMessages()}
                </div>

                {this.renderSubLabel()}
            </div>
        );
    }
}

export default Field;
