import { Product as SourceProduct } from 'AdvoxComponent/Product/Product.component';
import AddToCart from 'Component/AddToCart';
import ProductPrice from 'Component/ProductPrice';
import { GRID_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';

/** @namespace Pwa/Component/Product/Component */
export class Product extends SourceProduct {
    renderCustomAndBundleOptions() {
        const { configFormRef } = this.props;

        return <form ref={configFormRef}>{this.renderCustomizableOptions()}</form>;
    }

    renderPrice(isPreview = false) {
        const { getActiveProduct, productPrice } = this.props;
        const product = getActiveProduct();

        const { type_id: type, price_tiers: priceTiers, variants = [] } = product;
        if (!productPrice) {
            return null;
        }

        return (
            <div block={this.className} elem="PriceWrapper">
                <ProductPrice
                    meta
                    price={productPrice}
                    priceType={type}
                    tierPrices={priceTiers}
                    isPreview={isPreview}
                    mix={{ block: this.className, elem: 'Price' }}
                    isSchemaRequired
                    variantsCount={variants.length}
                />
            </div>
        );
    }

    renderAddToCartButton(layout = GRID_LAYOUT, enableIcon = false) {
        const { addToCart, inStock, quantity, getActiveProduct, getSelectedProduct } = this.props;

        return (
            <AddToCart
                mix={{ block: this.className, elem: 'AddToCart' }}
                addToCart={addToCart}
                isDisabled={!inStock}
                isIconEnabled={enableIcon}
                layout={layout}
                quantity={quantity}
                product={getActiveProduct()}
                selectedProduct={getSelectedProduct()}
            />
        );
    }
}

export default Product;
