/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { SINGLE_STEP } from 'Route/Checkout/Checkout.config';
import { formatPrice } from 'Util/Price';

import { extraFeeType } from '../../type/ExtraFee';

/** @namespace Altom/Extrafee/Component/ExtraFee/Component */
export class ExtraFeeComponent extends PureComponent {
    static propTypes = {
        isEmptyCart: PropTypes.bool,
        extraFee: extraFeeType,
        currency: PropTypes.string,
        checkoutStep: PropTypes.string,
    };

    static defaultProps = {
        isEmptyCart: false,
    };

    renderFeeForCheckout(title, extraFee, description) {
        const { currency } = this.props;

        return (
            <li block="CheckoutOrderSummary" elem="SummaryItem">
                <p block="CheckoutOrderSummary" elem="Text">
                    {title}
                </p>
                <span block="CheckoutOrderSummary" elem="Text">
                    {formatPrice(extraFee, currency)}
                </span>
            </li>
        );
    }

    renderFeeForCart(title, extraFee) {
        const { currency } = this.props;

        return (
            <>
                <dt>{title}</dt>
                <dd>{formatPrice(extraFee, currency)}</dd>
            </>
        );
    }

    render() {
        const {
            extraFee: { isEnabled, isTaxEnabled, displaySuffix, title, description, extraFee, extraFeeTax } = {},
            checkoutStep,
            isEmptyCart,
        } = this.props;

        if (!isEnabled || extraFee === 0 || isEmptyCart) {
            return null;
        }

        const extraFeeWithTax = extraFee + extraFeeTax;
        const isCheckout = checkoutStep === SINGLE_STEP;
        const isCart = checkoutStep === '';

        if (displaySuffix && isTaxEnabled) {
            if (isCheckout) {
                return (
                    <>
                        {this.renderFeeForCheckout(__(`${title} Excl. tax`), extraFee, description)}
                        {this.renderFeeForCheckout(__(`${title} Incl. tax`), extraFeeWithTax, description)}
                    </>
                );
            }
            if (isCart) {
                return (
                    <>
                        {this.renderFeeForCart(__(`${title} Excl. tax`), extraFee)}
                        {this.renderFeeForCart(__(`${title} Incl. tax`), extraFeeWithTax)}
                    </>
                );
            }
        }

        if (isCheckout) {
            return <>{this.renderFeeForCheckout(title, isTaxEnabled ? extraFeeWithTax : extraFee, description)}</>;
        }

        if (isCart) {
            return <>{this.renderFeeForCart(title, isTaxEnabled ? extraFeeWithTax : extraFee, description)}</>;
        }

        return null;
    }
}

export default ExtraFeeComponent;
