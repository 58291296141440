import { ProductCompareButton as AdvoxProductCompareButton } from 'AdvoxComponent/ProductCompareButton/ProductCompareButton.component';
import Icons from 'Component/Icons';
import Loader from 'Component/Loader';
import NotificationList from 'Component/NotificationList';
import { getNotificationPosition } from 'Util/Notification/Options';

export * from 'AdvoxComponent/ProductCompareButton/ProductCompareButton.component';

/** @namespace Pwa/Component/ProductCompareButton/Component */
export class ProductCompareButton extends AdvoxProductCompareButton {
    renderNotifications() {
        const { productId } = this.props;

        if (!productId) {
            return null;
        }

        return <NotificationList position={getNotificationPosition('ProductCompareButton', productId)} />;
    }

    render() {
        const { isActive, isLoading, mix, isComparisonEnabled } = this.props;

        if (!+isComparisonEnabled) {
            return null;
        }

        return (
            <div block="ProductCompareButton" mods={{ isActive }} mix={mix}>
                <button
                    block="ProductCompareButton"
                    elem="Button"
                    onClick={this._handleClick}
                    aria-label={__('Compare')}
                    title={__('Compare products')}
                >
                    <Icons name="compare" width="20" height="20" />
                    <Loader isLoading={isLoading} />
                </button>
                {this.renderNotifications()}
            </div>
        );
    }
}

export default ProductCompareButton;
