import { Image as AdvoxImage } from 'AdvoxComponent/Image/Image.component';
import { IMAGE_LOADED, IMAGE_NOT_FOUND } from 'Component/Image/Image.config';
import { isCrawler } from 'Util/Browser/Browser';

import { IMAGE_LOADING } from './Image.config';

import './Image.override.style.scss';

/** @namespace Pwa/Component/Image/Component */
export class Image extends AdvoxImage {
    onError() {
        if (isCrawler()) {
            this.setState({ imageStatus: IMAGE_LOADED });
        } else {
            this.setState({ imageStatus: IMAGE_NOT_FOUND });
        }
    }

    renderStyledImage() {
        const { onClick, alt, src, style, title, removeLazyLoading } = this.props;
        const { imageStatus } = this.state;

        const lazyLoading = !removeLazyLoading ? { loading: 'lazy' } : {};

        return (
            <img
                block="Image"
                elem="Image"
                src={src || ''}
                alt={alt}
                mods={{ isLoading: imageStatus === IMAGE_LOADING }}
                style={style}
                title={title}
                onLoad={this.onLoad}
                onError={this.onError}
                onClick={onClick}
                {...lazyLoading}
            />
        );
    }
}

export default Image;
