import { SET_CHECKOUT_DPD_PARCEL_LOCKER } from 'Store/CheckoutDpd/CheckoutDpd.action';

/** @namespace Pwa/Store/CheckoutDpd/Reducer/getInitialState */
export const getInitialState = () => ({
    parcelLocker: null,
});

/** @namespace Pwa/Store/CheckoutDpd/Reducer/CheckoutDpdReducer */
export const CheckoutDpdReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
        case SET_CHECKOUT_DPD_PARCEL_LOCKER:
            const { parcelLocker } = action;

            return {
                parcelLocker,
            };
        default:
            return state;
    }
};

export default CheckoutDpdReducer;
