import { connect } from 'react-redux';

import {
    CheckoutAddressBookContainer as SourceCheckoutAddressBookContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/CheckoutAddressBook/CheckoutAddressBook.container';

export * from 'SourceComponent/CheckoutAddressBook/CheckoutAddressBook.container';

/** @namespace Pwa/Component/CheckoutAddressBook/Container */
export class CheckoutAddressBookContainer extends SourceCheckoutAddressBookContainer {
    containerProps() {
        const { shippingAddress } = this.props;

        return {
            ...super.containerProps(),
            shippingAddress,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddressBookContainer);
