import {
    VALIDATION_INPUT_TYPE,
    VALIDATION_MESSAGES as SOURCE_VALIDATION_MESSAGES,
} from 'AdvoxUtil/Validator/Config.js';

export * from 'AdvoxUtil/Validator/Config.js';

export const VALIDATION_MESSAGES = {
    ...SOURCE_VALIDATION_MESSAGES,
    isRequiredMyAccount: __('This field is required'),
    [VALIDATION_INPUT_TYPE.date]: __('Incorrect date input, please enter date in the correct format, e.g. YYYYY-MM-DD'),
};
