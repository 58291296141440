import { removeEmptyStreets } from 'Util/Address';

export * from 'AdvoxUtil/Address';

/** @namespace Pwa/Util/Address/Index/getDefaultAddressLabel */
export const getDefaultAddressLabel = (address) => {
    const { default_billing, default_shipping } = address;

    if (!default_billing && !default_shipping) {
        return '';
    }

    if (default_billing && default_shipping) {
        return __(' (default shipping & billing address)');
    }

    if (default_billing) {
        return __(' (default billing address)');
    }

    return __(' (default shipping address)');
};

/** @namespace Pwa/Util/Address/Index/setAddressesInFormObject */
export const setAddressesInFormObject = (fields, numberOfLines, prefix = 'street') => {
    const addressKeys = new Array(numberOfLines).fill('').map((_, index) => `${prefix}${index}`);

    const addressValues = addressKeys.map((key) => fields[key]);

    // removing street related fields from the form object
    const newFields = Object.keys(fields)
        .filter((key) => !addressKeys.includes(key))
        .reduce((acc, key) => {
            acc[key] = fields[key];

            return acc;
        }, {});

    // setting single street entry to the form object
    newFields.street = removeEmptyStreets(addressValues);

    return newFields;
};
