import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import Notification from 'Component/Notification';
import { NotificationList as SourceNotificationList } from 'SourceComponent/NotificationList/NotificationList.component';
import { withPopper } from 'Util/Popper/withPopper';

import './NotificationList.override.style';

/** @namespace Pwa/Component/NotificationList/Component */
export class NotificationList extends SourceNotificationList {
    static propTypes = {
        ...SourceNotificationList.propTypes,
        position: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        ...SourceNotificationList.defaultProps,
        position: [],
    };

    componentDidUpdate(prevProps) {
        const { position, popper, notifications } = this.props;
        const { notifications: prevNotifications } = prevProps;
        const { update } = popper ?? {};

        if (position && !isEqual(notifications, prevNotifications) && typeof update === 'function') {
            update();
        }
    }

    render() {
        const { popper, position, notifications, onHideNotification, setReferenceElement, setPopperElement } =
            this.props;
        const { styles } = popper ?? {};
        const isLeft = styles.popper.left === '0';
        const isRight = styles.popper.right === '0';

        if (position?.length) {
            return (
                <>
                    <div block="NotificationList" elem="Reference" ref={setReferenceElement} />
                    <div
                        block="NotificationList"
                        elem="Element"
                        mods={{ isLeft, isRight }}
                        ref={setPopperElement}
                        style={styles.popper}
                    >
                        {Object.keys(notifications).map((id) => (
                            <Notification
                                key={id}
                                notificationId={id}
                                notification={notifications[id]}
                                onHideNotification={onHideNotification}
                            />
                        ))}
                    </div>
                </>
            );
        }

        return (
            <div block="NotificationList">
                {Object.keys(notifications).map((id) => (
                    <Notification
                        key={id}
                        notificationId={id}
                        notification={notifications[id]}
                        onHideNotification={onHideNotification}
                    />
                ))}
            </div>
        );
    }
}

export default withPopper(NotificationList);
