import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    IconsContainer as SourceIconsContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'AdvoxComponent/Icons/Icons.container';

export { mapStateToProps, mapDispatchToProps };

/** @namespace Pwa/Component/Icons/Container */
export class IconsContainer extends SourceIconsContainer {
    static propTypes = {
        ...SourceIconsContainer.propTypes,
        name: PropTypes.oneOf([
            'magnifier',
            'user',
            'bag',
            'compare',
            'dropdown_arrow',
            'dropdown_arrow_up',
            'close',
            'arrow_left',
            'arrow_right',
            'arrow_up',
            'arrow_down',
            'phone',
            'envelop',
            'clock',
            'youtube',
            'twitter',
            'facebook',
            'pinterest',
            'instagram',
            'eye_crossed',
            'eye',
            'calendar',
            'badge',
            'hand',
            'chat',
            'car',
            'heart',
            'pin',
            'logo_small',
            'trash',
            'plus',
            'time',
            'pencil',
            'truck',
            'envelope',
            'clock_2',
            'truck_2',
            'phone_2',
            'box',
            'angle_right',
            'freeshipping',
            'edit',
            'flatrate',
            'next',
            'check',
            'warning',
            'notice',
            'coins',
            'tag',
            'check_fill',
            'warning_fill',
            'file_download',
            'paper_clip',
            'saving_coins',
            'return_arrow',
            'live_chat',
            'info',
            'play',
            'info_circle',
            'chevron_left',
            'chevron_right',
            'close_thin',
        ]).isRequired,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IconsContainer);
