import { connect } from 'react-redux';

import { runWPAddToCartPixel } from 'Component/WPEvents/WPEvents.util';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/AddToCart/AddToCart.container';
import { ADD_TO_CART } from 'Util/Product';
import { getProductInStock } from 'Util/Product/Extract';
import { magentoProductTransform } from 'Util/Product/Transform';

/* @namespace Pwa/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    async addProductToCart(e) {
        const { product, addToCart, quantity } = this.props;

        if ((!product || Object.keys(product).length === 0) && !addToCart) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();
        this.setState({ isAdding: true });

        if (!this.validate()) {
            return;
        }

        if (typeof addToCart === 'function') {
            try {
                await addToCart();
            } finally {
                this.setState({ isAdding: false });
                runWPAddToCartPixel(product, quantity);
            }
        } else {
            const { quantity, cartId, fallbackAddToCart } = this.props;
            const magentoProduct = magentoProductTransform(ADD_TO_CART, product, quantity);

            try {
                await fallbackAddToCart({
                    products: magentoProduct,
                    cartId,
                });
            } finally {
                this.setState({ isAdding: false });
                runWPAddToCartPixel(product, quantity);
            }
        }

        this.setState({ isAdding: false });
    }

    containerProps() {
        const { product, selectedProduct, quantity } = this.props;
        const magentoProduct = magentoProductTransform(ADD_TO_CART, selectedProduct, quantity);
        const inStock = getProductInStock(product);
        return {
            ...super.containerProps(),
            magentoProduct,
            inStock,
            product,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
