export class CheckoutPaymentsContainerPlugin {
    componentDidMount(args, callback, instance) {
        const { savePaymentInformationOnly, paymentMethods, setSelectedPaymentMethod } = instance.props;
        const { selectedPaymentCode } = instance.state;
        const [{ code } = {}] = paymentMethods;

        setSelectedPaymentMethod(code);
        savePaymentInformationOnly(selectedPaymentCode);
    }
}
const { componentDidMount } = new CheckoutPaymentsContainerPlugin();

export default {
    'Pwa/Component/CheckoutPayments/Container': {
        'member-function': {
            componentDidMount,
        },
    },
};
