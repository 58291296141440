import PropTypes from 'prop-types';

import { FieldContainer as SourceFieldContainer } from 'AdvoxComponent/Field/Field.container';

/** @namespace Pwa/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static propTypes = {
        ...SourceFieldContainer.propTypes,
        hidden: PropTypes.bool,
        showColon: PropTypes.bool,
    };

    static defaultProps = {
        ...SourceFieldContainer.defaultProps,
        hidden: false,
        showColon: true,
    };

    containerProps() {
        const { hidden, showColon } = this.props;

        return {
            ...super.containerProps(),
            hidden,
            showColon,
        };
    }
}

export default FieldContainer;
