import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MetaContainer as SourceMetaContainer,
} from 'SourceComponent/Meta/Meta.container';
import { getQueryParam } from 'Util/Url';

export { mapStateToProps, mapDispatchToProps };
/** @namespace Pwa/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    _getTitle() {
        const pageParam = getQueryParam('page', location);
        const { title, default_title } = this.props;

        if (pageParam && pageParam !== '1') {
            return `${title || default_title} | ${__('Page')} ${pageParam}`;
        }

        return String(title || default_title);
    }

    _getMetadata() {
        const { title_suffix } = this.props;

        const meta = {
            title: `${this._getTitle()}${title_suffix}`,
            description: this._getDescription(),
            keywords: this._getKeywords(),
            robots: this._getRobots(),
            'render:status_code': this._getStatusCode(),
        };

        return this._generateMetaFromMetadata(meta);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
