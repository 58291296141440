import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCompareButtonContainer as SourceProductCompareButtonContainer,
} from 'SourceComponent/ProductCompareButton/ProductCompareButton.container';

/** @namespace Pwa/Component/ProductCompareButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isComparisonEnabled: state.ConfigReducer.advox_comparison_enabled,
});

/** @namespace Pwa/Component/ProductCompareButton/Container */
export class ProductCompareButtonContainer extends SourceProductCompareButtonContainer {
    containerProps() {
        const { isComparisonEnabled } = this.props;

        return {
            ...super.containerProps(),
            isComparisonEnabled,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCompareButtonContainer);
