import { createRef } from 'react';

import { AccordionContainer as SourceAccordionContainer } from 'AdvoxComponent/Accordion/Accordion.container';

import Accordion from './Accordion.component';

/** @namespace Pwa/Component/Accordion/Container */
export class AccordionContainer extends SourceAccordionContainer {
    ref = createRef();

    containerFunctions = {
        setRef: this.setRef.bind(this),
    };

    componentDidMount() {
        this.getAccordionBoxes().forEach((accordionBox) => {
            accordionBox.addEventListener('click', (e) => this.handleClickBox(e));
        });
    }

    componentWillUnmount() {
        this.getAccordionBoxes().forEach((accordionBox) => {
            accordionBox.removeEventListener('click', (e) => this.handleClickBox(e));
        });
    }

    setRef(element) {
        if (this.ref.current !== element) {
            this.ref.current = element;
        }
    }

    getAccordionBoxes() {
        if (!this.ref.current) return [];
        return this.ref.current.querySelectorAll('.Accordion-Box');
    }

    setActiveAccordionItem(clickedItem) {
        this.getAccordionBoxes().forEach((box) => {
            if (clickedItem !== box) {
                box.classList.remove('Accordion-Box_isOpen');
            }
        });
        clickedItem.classList.toggle('Accordion-Box_isOpen');
    }

    handleClickBox(e) {
        const box = e.target.closest('.Accordion-Box');
        this.setActiveAccordionItem(box);
    }

    render() {
        return <Accordion {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default AccordionContainer;
