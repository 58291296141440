import { Slider as SourceSlider } from 'AdvoxQuery/Slider.query';
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/Slider/Query */
export class Slider extends SourceSlider {
    _getSliderItemField() {
        return new Field('slides').addFieldList([
            'body',
            'cta_color',
            'cta_link',
            'cta_link_type',
            'cta_text',
            'header',
            'image',
            'image_height',
            'image_width',
            'mobile_image',
            'tablet_image',
            'content_position',
        ]);
    }
}

export default new Slider();
