import { getStaticReducers as sourceGetStaticReducers } from 'SourceStore';
import ProductRelatedAccessoriesReducer from 'Store//ProductRelatedAccessories/ProductRelatedAccessories.reducer';
import AdvoxPopupReducer from 'Store/AdvoxPopup/AdvoxPopup.reducer';
import AnnouncementReducer from 'Store/Announcement/Announcement.reducer';
import CheckoutDpdReducer from 'Store/CheckoutDpd/CheckoutDpd.reducer';
import CheckoutInpostReducer from 'Store/CheckoutInpost/CheckoutInpost.reducer';
import RewardsPointsReducer from 'Store/RewardsPoints/RewardsPoints.reducer';

/** @namespace Pwa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers(),
    AnnouncementReducer,
    ProductRelatedAccessoriesReducer,
    RewardsPointsReducer,
    AdvoxPopupReducer,
    CheckoutInpostReducer,
    CheckoutDpdReducer,
});

export default function injectStaticReducers(store) {
    // eslint-disable-next-line no-param-reassign
    store.asyncReducers = [];

    // Inject all the static reducers into the store
    Object.entries(getStaticReducers()).forEach(([name, reducer]) => {
        // eslint-disable-next-line no-param-reassign
        store.asyncReducers[name] = reducer;
        store.injectReducer(name, reducer);
    });

    return store;
}
