/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { roundPrice } from 'Util/Price';
import { fetchQuery } from 'Util/Request';
import getStore from 'Util/Store';

import PurchaseQuery from '../query/Purchase.query';
import { waitForCallback } from '../util/wait';
import { getCustomerData } from './customer';
import {getProductCategoriesLevelsData, getProductDimensionsData} from './product';

/** @namespace Scandiweb/GoogleTagManager/Data/Purchase/getPurchaseProductsData */
export const getPurchaseProductsData = async (purchase) => {
    const { products } = purchase;

    return Promise.all(products.map(async (product) => {
        const {
            categories,
            id,
            name,
            price,
            quantity
        } = product;

        return {
            ...getProductCategoriesLevelsData(product),
            item_id: id,
            item_name: name,
            price: +roundPrice(price),
            quantity,
        };
    }));
};

/** @namespace Scandiweb/GoogleTagManager/Data/Purchase/getPurchaseShippingData */
export const getPurchaseShippingData = (purchase) => {
    const { shippingAddress, additional_data } = purchase;

    if (!additional_data || !shippingAddress) {
        return {};
    }

    const {
        city,
        postcode,
        region,
        region_id,
        street
    } = shippingAddress;

    return {
        shipping_city: city,
        shipping_region: region,
        shipping_country_id: region_id,
        shipping_street: street.replace(/\r?\n|\r/g, ' '),
        shipping_postcode: postcode
    };
};

/** @namespace Scandiweb/GoogleTagManager/Data/Purchase/getPurchaseCustomerData */
export const getPurchaseCustomerData = async (purchase) => {
    const { additional_data } = purchase;

    if (!additional_data) {
        return {};
    }

    return {
        ...await getCustomerData(),
        shipping_email: getStore().getState().CheckoutReducer?.email || ''
    };
};

/** @namespace Scandiweb/GoogleTagManager/Data/Purchase/getPurchaseEventData */
export const getPurchaseEventData = async (orderId, guestQuoteId) => {
    // vvv Wait for currency code
    await waitForCallback(() => getStore().getState().ConfigReducer?.currencyData?.current_currency_code);

    const currencyCode = getStore().getState().ConfigReducer?.currencyData?.current_currency_code;

    const query = PurchaseQuery.getPurchaseField(orderId, guestQuoteId);
    const { purchase } = await fetchQuery(query);
    const {
        revenue,
        tax,
        shipping,
        coupon,
        discount_amount
    } = purchase;

    return {
        ecommerce: {
            transaction_id: orderId,
            tax: +roundPrice(tax),
            shipping: +roundPrice(shipping),
            currency: currencyCode,
            coupon: coupon === null ? '' : coupon,
            value: +roundPrice(revenue),
            items: await getPurchaseProductsData(purchase),
            _clear: true
        }
    };
};
