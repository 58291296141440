import { PureComponent } from 'react';
import SlickSlider from 'react-slick';

import { DEFAULT_SLICK_SETTINGS } from 'Component/FeaturedBrands/FeaturedBrands.config';
import SliderNextArrow from 'Component/SliderNextArrow';
import SliderPrevArrow from 'Component/SliderPrevArrow';

import './FeaturedBrands.style';

/** @namespace Pwa/Component/FeaturedBrands/Component */
export class FeaturedBrands extends PureComponent {
    render() {
        const { children } = this.props;

        return (
            <div block="FeaturedBrands">
                <SlickSlider
                    prevArrow={<SliderPrevArrow />}
                    nextArrow={<SliderNextArrow />}
                    {...DEFAULT_SLICK_SETTINGS}
                >
                    {children}
                </SlickSlider>
            </div>
        );
    }
}

export default FeaturedBrands;
