import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PRODUCT_TYPE from 'Component/Product/Product.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductContainer as SourceProductContainer,
} from 'SourceComponent/Product/Product.container';
import { clearRelatedProductList } from 'Store/ProductRelatedAccessories/ProductRelatedAccessories.action';
import { getGuestQuoteId } from 'Util/Cart';
import { getAutoSelectedOptions } from 'Util/Product/Transform';

/** @namespace Pwa/Component/Product/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    clearRelatedProductList: () => dispatch(clearRelatedProductList()),
});

/** @namespace Pwa/Component/Product/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    productsRelated: state.ProductRelatedAccessoriesReducer.productsRelated,
});

/**
 * Abstract Product class used to hold shared functionality
 * between ProductDetails & ProductCard
 * @class ProductContainer
 * @namespace Pwa/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    static propTypes = {
        ...this.propTypes,
        productsRelated: PropTypes.array,
    };

    containerFunctions = {
        ...this.containerFunctions,
        getSelectedProduct: this.getSelectedProduct.bind(this),
    };

    getSelectedProduct() {
        const { product } = this.props;

        return product;
    }

    async addToCart() {
        const {
            showError,
            addProductToCart,
            clearRelatedProductList,
            productsRelated,
            product: { type_id, bundle_options, items } = {},
        } = this.props;

        const magentoProduct = this.getMagentoProduct();

        // Auto-selecting for bundle product
        if (type_id === PRODUCT_TYPE.bundle) {
            magentoProduct[0].selected_options = getAutoSelectedOptions(bundle_options, items);
        }

        if (this.hasError()) {
            return;
        }

        // Auto-selecting for bundle product
        if (productsRelated && productsRelated.length > 0) {
            for (let i = 0; i < productsRelated.length; i++) {
                const { bundle_options, items, type_id, sku, quantity } = productsRelated[i];

                productsRelated[i] = {
                    ...(type_id === PRODUCT_TYPE.bundle
                        ? { selected_options: getAutoSelectedOptions(bundle_options, items) }
                        : {}),
                    sku,
                    quantity,
                };
            }
        }

        // The Products Related Accessories are added to the card
        const products = [...magentoProduct, ...productsRelated];

        await addProductToCart({ products, cartId: getGuestQuoteId() })
            .then(
                /** @namespace Pwa/Component/Product/Container/ProductContainer/addToCart/then/catch/addProductToCart/then */
                () => {
                    clearRelatedProductList();
                }
            )
            .catch(
                /** @namespace Pwa/Component/Product/Container/ProductContainer/addToCart/then/catch */
                (error) => {
                    if (error) {
                        showError(error);
                    }
                }
            );
    }

    validateConfigurableProduct() {
        const { parameters } = this.state;

        const {
            product: { configurable_options },
        } = this.props;

        if (!configurable_options) return false;

        const unselectedOptions = Object.keys(configurable_options).reduce((accumulator, value) => {
            if (!parameters[value]) {
                accumulator.push(value);
            }

            return accumulator;
        }, []);

        this.setState({ unselectedOptions });

        return unselectedOptions.length > 0;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
