import getStore from 'Util/Store';

/** @namespace Pwa/Util/Locale/getBrowserLocale */
export const getBrowserLocale = () => (navigator.languages ? navigator.languages[0] : navigator.language);

/** @namespace Pwa/Util/Locale/getDefaultLocale */
export const getDefaultLocale = () => window.defaultLocale;

/** @namespace Pwa/Util/Locale/getStoreLocale */
export const getStoreLocale = () => getStore()?.getState()?.ConfigReducer?.locale;

/** @namespace Pwa/Util/Locale/getLocale */
export const getLocale = () => (getStoreLocale() ?? getDefaultLocale() ?? getBrowserLocale() ?? '').split(/-|_/)[0];
