/**
 * Mageplaza Social Login compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import facebookIcon from '../../../assets/images/FB.png';
import GoogleIcon from '../../../assets/images/Google.png';

import './SocialLoginButton.style';

/** @namespace Altom/Mageplaza/Component/SocialLoginButton/Component */
export class SocialLoginButtonComponent extends PureComponent {
    static propTypes = {
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired
    };

    renderButton() {
        const { label, type, onClick } = this.props;
        if (label === 'Facebook') {
            return (
                <button block="SocialLoginButton" mods={{ type }} mix={{ block: 'Button' }} onClick={onClick}>
                    <img src={facebookIcon} alt="facebook" />
                    <span>{__('Log in with Facebook')}</span>
                </button>
            );
        }
        if (label === 'Google') {
            return (
                <button block="SocialLoginButton" mods={{ type }} mix={{ block: 'Button' }} onClick={onClick}>
                    <img src={GoogleIcon} alt="google" />
                    <span>{__('Log in with Google')}</span>
                </button>
            );
        }
    }

    render() {
        return <div block="SocialLoginButton">{ this.renderButton() }</div>;
    }
}

export default SocialLoginButtonComponent;
