import PropTypes from 'prop-types';
import { createContext } from 'react';

import CmsBlockQuery from 'Query/CmsBlock.query';
import DataContainer from 'Util/Request/DataContainer';

export const CmsBlockContext = createContext({});

/** @namespace Pwa/Component/CmsBlockProvider/Container */
export class CmsBlockProviderContainer extends DataContainer {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
        identifiers: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        identifiers: [],
    };

    static getContext = () => CmsBlockContext;

    static contextType = CmsBlockContext;

    state = {
        isLoading: true,
        blocks: [],
    };

    componentDidMount() {
        const { identifiers } = this.props;

        this.fetchData(
            [CmsBlockQuery.getQuery({ identifiers })],
            ({ cmsBlocks: { items: blocks } = {} }) => {
                if (!blocks?.length) {
                    return;
                }

                this.setState({ blocks });
            },
            (error) => {
                const [, { cmsBlocks: { items: blocks } = {} } = {}] = error || [];
                this.setState({ blocks: blocks ?? [] });
            }
        );
    }

    render() {
        const { children } = this.props;
        const { blocks } = this.state;

        return <CmsBlockContext.Provider value={{ blocks }}>{children}</CmsBlockContext.Provider>;
    }
}

export default CmsBlockProviderContainer;
