import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'AdvoxComponent/ProductCard/ProductCard.container';
import history from 'Util/History';
import { appendWithStoreCode, objectToUri } from 'Util/Url';

import ProductCard from './ProductCard.component';

export { mapDispatchToProps };

/** @namespace Pwa/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    shipping_time_enabled: state.ConfigReducer.advox_shipping_time_enabled,
    shipping_time_shipping_time: state.ConfigReducer.advox_shipping_time_shipping_time,
});

/** @namespace Pwa/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        ...SourceProductCardContainer.propTypes,
        shipping_time_enabled: PropTypes.string,
        shipping_time_shipping_time: PropTypes.string,
    };

    getLinkTo() {
        const {
            baseLinkUrl,
            productUsesCategories,
            categoryUrlSuffix,
            product: { url, url_rewrites = [] },
            product,
        } = this.props;
        const { pathname: storePrefix } = new URL(baseLinkUrl || window.location.origin);
        const {
            location: { pathname },
        } = history;

        if (!url) {
            return undefined;
        }

        const { parameters } = this.state;
        const { state: { category = null } = {} } = history.location;
        const categoryUrlPart = pathname.replace(storePrefix, '').replace(categoryUrlSuffix, '');
        const productUrl = `${categoryUrlPart}/${url.replace(storePrefix, '')}`;
        const fromSearch = pathname.includes('/search/');

        // if 'Product Use Categories' is enabled then use the current window location to see if the product
        // has any url_rewrite for that path. (if not then just use the default url)
        const rewriteUrl = url_rewrites.find(({ url }) => url.includes(productUrl)) || {};
        const rewriteUrlPath = productUsesCategories
            ? (rewriteUrl.url && appendWithStoreCode(rewriteUrl.url)) || url
            : url;

        return {
            pathname: rewriteUrlPath,
            state: { product, prevCategoryId: category, fromSearch },
            search: objectToUri(parameters),
        };
    }

    containerProps() {
        const { shipping_time_enabled, shipping_time_shipping_time, isWishlist, variant } = this.props;

        return {
            ...super.containerProps(),
            shipping_time_enabled,
            shipping_time_shipping_time,
            isWishlist,
            variant,
        };
    }

    render() {
        return <ProductCard {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
