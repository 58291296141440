import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

import ImageLazyLoading from 'Component/ImageLazyLoading';
import { appendWithStoreCode } from 'Util/Url';

import productPlaceholderSmall from '../../assets/images/productPlaceholderSmallRelated.png';

import './RelatedProductsByAttribute.style';

/** @namespace Pwa/Component/RelatedProductsByAttribute/Component */
export class RelatedProductsByAttribute extends PureComponent {
    static propTypes = {
        productUrlSuffix: PropTypes.string,
        products: PropTypes.array,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        variant: PropTypes.string,
    };

    static defaultProps = {
        productUrlSuffix: '',
        products: [],
        onMouseEnter: () => {},
        onMouseLeave: () => {},
        variant: '',
    };

    renderTitle() {
        return (
            <div block="RelatedProductsByAttribute" elem="Title">
                <p>{__('Similar products')}</p>
            </div>
        );
    }

    renderRelatedProduct({ url_key, name, image, small_image }, index) {
        const { productUrlSuffix, onMouseEnter, onMouseLeave, history } = this.props;

        return (
            <div
                block="RelatedProductsByAttribute"
                elem="RelatedProduct"
                key={`relatedproduct-${index}`}
                onClick={(e) => {
                    e.stopPropagation();
                    history.push(appendWithStoreCode(`/${url_key}${productUrlSuffix}`));
                }}
                onMouseEnter={() => onMouseEnter(image && image.url)}
                onMouseLeave={() => onMouseLeave()}
            >
                <ImageLazyLoading
                    src={small_image && small_image.url ? small_image.url : productPlaceholderSmall}
                    alt={name}
                    lazyLoadingType="productTileRelated"
                />
            </div>
        );
    }

    render() {
        const { products, variant } = this.props;

        if (!products || !products.length) return null;

        return (
            <div block="RelatedProductsByAttribute">
                {this.renderTitle()}
                <div block="RelatedProductsByAttribute" elem="RelatedProducts" mods={{ variant }}>
                    {products.map(this.renderRelatedProduct.bind(this))}
                </div>
            </div>
        );
    }
}

export default withRouter(RelatedProductsByAttribute);
