import { ClickOutside as SourceClickOutside } from 'SourceComponent/ClickOutside/ClickOutside.component';

/** @namespace Pwa/Component/ClickOutside/Component */
export class ClickOutside extends SourceClickOutside {
    handleClick({ target }) {
        const { onClick } = this.props;

        if (
            this.childrenRefs.every((ref) => {
                const elementRef = ref.current?.overlayRef?.current || ref.current;
                if (!elementRef) return;
                return !elementRef?.contains(target);
            })
        ) {
            onClick();
        }
    }
}

export default ClickOutside;
