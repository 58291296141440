import { Suspense } from 'react';

import CheckoutBilling from 'Component/CheckoutBilling';
import Loader from 'Component/Loader';

const renderBillingSection = (args, callback, instance) => {
    const {
        setLoading,
        setDetailsStep,
        shippingAddress,
        paymentMethods = [],
        savePaymentInformation,
        selectedShippingMethod,
        setNewsletterAccepted,
        newsletterSubscription,
        savePaymentInformationOnly,
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        shippingMethods,
        savePaymentMethodAndPlaceOrder,
    } = instance.props;
    const [onlyAddress] = args;

    return (
        <Suspense fallback={<Loader />}>
            <CheckoutBilling
                shippingMethods={shippingMethods}
                setLoading={setLoading}
                paymentMethods={paymentMethods}
                setDetailsStep={setDetailsStep}
                shippingAddress={shippingAddress}
                savePaymentInformationOnly={savePaymentInformationOnly}
                savePaymentInformation={savePaymentInformation}
                selectedShippingMethod={selectedShippingMethod}
                setNewsletterAccepted={setNewsletterAccepted}
                newsletterSubscription={newsletterSubscription}
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                onlyAddress={onlyAddress}
                savePaymentMethodAndPlaceOrder={savePaymentMethodAndPlaceOrder}
            />
        </Suspense>
    );
};

export default {
    'Pwa/Route/Checkout/Component': {
        'member-function': {
            renderBillingSection,
        },
    },
};
