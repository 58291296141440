import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

/** @namespace Pwa/Component/EkomiWidget/Component */
export class EkomiWidget extends PureComponent {
    static propTypes = {
        ekomiId: PropTypes.string,
        ekomiToken: PropTypes.string,
        ekomiWidget: PropTypes.bool,
    };

    static defaultProps = {
        ekomiId: '',
        ekomiToken: '',
        ekomiWidget: false,
    };

    componentDidMount() {
        this.appendScript();
    }

    componentWillUnmount() {
        this.removeScript();
    }

    appendScript() {
        const { ekomiId, ekomiToken } = this.props;

        const scriptTag = document.createElement('script');
        const data = `
            function registerWidget(w, token) {
                    w['_ekomiWidgetsServerUrl'] = 'https://widgets.ekomi.com';
                    w['_customerId'] = ${ekomiId};
                    if (w['_language'] == undefined) {
                        w['_language'] = new Array();
                    }
                    w['_language'][token] = 'auto';
                    if (typeof(w['_ekomiWidgetTokens']) !== 'undefined') {
                        w['_ekomiWidgetTokens'][w['_ekomiWidgetTokens'].length] = token;
                    } else {
                        w['_ekomiWidgetTokens'] = new Array(token);
                    }
                    if (typeof(ekomiWidgetJs) == 'undefined') {
                        ekomiWidgetJs = true;
                        var scr = document.createElement('script');
                        scr.src = 'https://sw-assets.ekomiapps.de/static_resources/widget.js';
                        var head = document.getElementsByTagName('head')[0];
                        head.appendChild(scr);
                    } else {
                        if (typeof ekomiWidgetMain != 'undefined') {
                            ekomiWidgetMain('ajax', token);
                        }
                    }
                    return true;
                }(function(w) {
                    var token = '${ekomiToken}';
                    var k = document.getElementsByClassName("ekomi-widget-" + token);
                    for (var x = 0; x < k.length; x++) {
                        registerWidget(w, token);
                    }
                })(window);
        `;
        const scriptText = document.createTextNode(data);
        scriptTag.appendChild(scriptText);
        scriptTag.id = `ekomi-widget-${ekomiToken}`;
        document.head.appendChild(scriptTag);
    }

    removeScript() {
        const { ekomiToken } = this.props;
        const scriptTag = document.getElementById(`ekomi-widget-${ekomiToken}`);

        if (scriptTag) {
            scriptTag.remove();
        }

        const scriptTags = document.getElementsByTagName('script');
        for (let i = 0; i < scriptTags.length; i++) {
            if (scriptTags[i].src.includes('ekomiapps.de')) {
                scriptTags[i].remove();
            }
        }
    }

    renderHtml() {
        const { ekomiToken } = this.props;

        return (
            <>
                <div id="widget-container" className={`ekomi-widget-container ekomi-widget-${ekomiToken}`} />
                <a href="https://www.ekomi-pl.com/opinie-pieknowdomu.pl.html" target="_blank" rel="noreferrer">
                    <img
                        alt="ALTOM SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ SPÓŁKA KOMANDYTOWA Reviews with ekomi-pl.com"
                        src="https://smart-widget-assets.ekomiapps.de/resources/ekomi_logo.png"
                        style={{ display: 'none' }}
                    />
                </a>
            </>
        );
    }

    render() {
        const { ekomiId, ekomiToken, ekomiWidget } = this.props;

        return ekomiWidget && ekomiId && ekomiToken ? this.renderHtml() : null;
    }
}

export default EkomiWidget;
