import PropTypes from 'prop-types';

import { ImageContainer as AdvoxImageContainer } from 'AdvoxComponent/Image/Image.container';
import { noopFn } from 'Util/Common';
/** @namespace Pwa/Component/Image/Container */
export class ImageContainer extends AdvoxImageContainer {
    static propTypes = {
        ...AdvoxImageContainer.propTypes,
        onClick: PropTypes.func,
        removeLazyLoading: PropTypes.bool,
    };

    static defaultProps = {
        ...AdvoxImageContainer.defaultProps,
        onClick: noopFn,
        removeLazyLoading: false,
    };

    containerProps() {
        const { onClick, removeLazyLoading } = this.props;

        return {
            ...super.containerProps(),
            onClick,
            removeLazyLoading,
        };
    }
}

export default ImageContainer;
