import { ProductListQuery as AdvoxProductListQuery } from 'AdvoxQuery/ProductList.query';
import { Field } from 'Util/Query';

import ElasticSuiteQuery from './ElasticSuite.query';

/** @namespace Pwa/Query/ProductList/Query */
export class ProductListQuery extends AdvoxProductListQuery {
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const {
            isPlp = false,
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false,
        } = this.options;

        // set option to always request images for product variants if they're requested for wishlist
        if (isForWishlist) {
            this.options.isForWishlist = true;
        }

        // Basic fields returned always
        const fields = [
            'uid',
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'salable_qty',
            'alternative_hover_image',
            this._getStockItemField(),
            this._getPriceRangeField(),
            this._getLowestPriceField(),
            this._getLabelField(),
            this._getRelatedProductsByAttribute(),
            ...this._getRelatedProductsByAttributeLabel(),
        ];

        // Additional fields, which we want to return always, except when it's variants on PLP (due to hugh number of items)
        if (!(isPlp && isVariant) || isForWishlist) {
            fields.push(
                this._getProductImageField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getShortDescriptionField(),
                'special_from_date',
                'special_to_date',
                this._getTierPricesField()
            );
        }

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push('url', this._getUrlRewritesFields(), this._getCustomizableProductFragment());

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment(),
                    this._getGroupedProductItems()
                );
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(
                'stock_status',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                this._getShippingCostsField(),
                this._getShippingCostsAdditionalField(),
                this._getReviewCountField(),
                this._getRatingSummaryField(),
                this._getPhysicalProductFragment()
            );

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    'canonical_url',
                    'meta_title',
                    'meta_keyword',
                    'meta_description',
                    this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment(),
                    this._getProductLinksField()
                );
            }
        }

        return fields;
    }

    _getProductFields() {
        const { requireInfo, isSingleProduct, notRequireInfo } = this.options;

        if (isSingleProduct || notRequireInfo) {
            return [this._getItemsField(), 'search_redirect_url', ElasticSuiteQuery.getTrackerVariablesField()];
        }

        if (requireInfo) {
            return [
                this._getSortField(),
                this._getAggregationsField(),
                'search_redirect_url',
                ElasticSuiteQuery.getTrackerVariablesField(),
            ];
        }

        return [
            'total_count',
            'search_redirect_url',
            this._getItemsField(),
            this._getPageInfoField(),
            ElasticSuiteQuery.getTrackerVariablesField(),
        ];
    }

    _getLowestPriceField() {
        return new Field('lowest_price').addFieldList([
            'omnibus_text',
            'omnibus_discount_text',
            'value_incl_tax',
            'value_excl_tax',
            'discount',
            'discount_percent',
        ]);
    }

    _getShippingCostsField() {
        return new Field('shipping_costs').addField('label').addField(this._getShippingCostsFields());
    }

    _getShippingCostsFields() {
        return new Field('value').addFieldList(['currency,value']);
    }

    _getShippingCostsAdditionalField() {
        return new Field('shipping_costs_additional_methods')
            .addField('label')
            .addField(this._getShippingCostsAdditionalFields());
    }

    _getShippingCostsAdditionalFields() {
        return new Field('value').addFieldList(['currency,value']);
    }

    _getItemsField() {
        const { isSingleProduct } = this.options;

        const items = super._getItemsField();

        if (isSingleProduct) {
            items.addField(this._getproductRelatedAccessoriesField());
            items.addField(this._getproductRelatedCategoriesField());
        }

        return items;
    }

    _getproductRelatedCategoriesField() {
        return new Field('product_related_categories').addField(this._getproductRelatedCategoriesFields());
    }

    _getproductRelatedCategoriesFields() {
        return new Field('items').addFieldList(['name', 'url', 'entity_id']);
    }

    _getRelatedProductsByAttribute() {
        return new Field('related_products_by_attribute').addFieldList([
            'url_key',
            'name',
            this._getProductImageField(),
            this._getProductSmallField(),
        ]);
    }

    _getRelatedProductsByAttributeLabel() {
        return ['related_product_attribute', 'related_products_by_attribute_label'];
    }

    _getproductRelatedAccessoriesField() {
        const fields = [
            'id',
            'sku',
            'name',
            'type_id',
            'url',
            this._getStockItemField(),
            this._getPriceRangeField(),
            this._getProductRelatedSmallField(),
            this._getBundleProductFragment(),
        ];

        return new Field('product_related_accessories').addFieldList(fields);
    }

    _getCartProductInterfaceFields() {
        return [
            ...super._getCartProductInterfaceFields(),
            this._getPriceRangeField(),
            this._getProductRelatedSmallField(),
        ];
    }

    _getProductRelatedSmallField() {
        return new Field('small_image').addFieldList(this._getProductSmallFields());
    }

    _getBundleOptionsFields() {
        const { isSingleProduct } = this.options;

        const fields = ['uid', 'quantity', 'is_default', this._getProductBundleOptionField()];

        if (isSingleProduct) {
            fields.push('position', 'price', 'price_type', 'can_change_quantity', 'label');
        }

        return fields;
    }

    _getBundlePriceOptionSelectionFields() {
        const { isSingleProduct } = this.options;

        const fields = ['selection_id'];

        if (isSingleProduct) {
            fields.push(
                'final_option_price',
                'final_option_price_excl_tax',
                'regular_option_price',
                'regular_option_price_excl_tax'
            );
        }

        return fields;
    }

    _getBundleItemsFields() {
        const { isSingleProduct } = this.options;

        const fields = ['uid', 'option_id', 'required', this._getBundleOptionsField()];

        if (isSingleProduct) {
            fields.push('title', 'type', 'position', 'sku');
        }

        return fields;
    }

    _getProductBundleOptionFields() {
        const { isSingleProduct } = this.options;

        const fields = ['name', 'salable_qty', 'stock_status'];

        if (isSingleProduct) {
            fields.push(this._getPriceRangeField());
        }

        return fields;
    }

    getSearchBarQuery(options) {
        if (!options) {
            throw new Error('Missing argument `options`');
        }

        this.options = options;

        return this._getSearchBarProductsField();
    }

    _getSearchBarProductsField() {
        const products = new Field('products').addFieldList([this._getSearchBarItemsField(), 'search_redirect_url']);

        this._getProductArguments().forEach((arg) => products.addArgument(...arg));

        return products;
    }

    _getSearchBarItemsField() {
        return new Field('items').addFieldList(['name', 'url', 'id', this._getProductThumbnailField()]);
    }

    _getProductThumbnailFields() {
        return [...super._getProductThumbnailFields(), 'label'];
    }

    _getPriceRangeFields() {
        return [this._getMinimalPriceField()];
    }
}

export default new ProductListQuery();
