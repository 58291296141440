import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace Pwa/Component/Delayed/Component */
export class Delayed extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        waitBeforeShow: PropTypes.number,
    };

    static defaultProps = {
        waitBeforeShow: 200,
    };

    state = { hidden: true };

    componentDidMount() {
        const { waitBeforeShow } = this.props;

        this.timer = setTimeout(() => this.setState({ hidden: false }), waitBeforeShow);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    render() {
        const { hidden } = this.state;
        const { children } = this.props;

        return hidden ? null : children;
    }
}

export default Delayed;
