/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { ProductListTracker } from '../../component/ProductListTracker';

const addProductListImpressionObserver = (args, callback, instance) => (
    <ProductListTracker products={ instance.props.items }>
        { callback(...args) }
    </ProductListTracker>
);

export default {
    'Component/ProductListPage/Component': {
        'member-function': {
            render: addProductListImpressionObserver
        }
    },
};
