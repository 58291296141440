export const SET_ANNOUNCEMENT = 'SET_ANNOUNCEMENT';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';

export const ANNOUNCEMENT_NOTICE = 'ANNOUNCEMENT_NOTICE';

/** @namespace Pwa/Store/Announcement/Action/setAnnouncement */
export const setAnnouncement = (announcement) => ({
    type: SET_ANNOUNCEMENT,
    announcement,
});

/** @namespace Pwa/Store/Announcement/Action/setCurrentLocation */
export const setCurrentLocation = (currentLocation) => ({
    type: SET_CURRENT_LOCATION,
    currentLocation,
});
