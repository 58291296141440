export const GET_REWARDS_POINTS = 'GET_REWARDS_POINTS';
export const GET_REWARDS_POINTS_BALANCE = 'GET_REWARDS_POINTS_BALANCE';
export const GET_REWARDS_POINTS_ERROR = 'GET_REWARDS_POINTS_ERROR';
export const GET_REWARDS_POINTS_RESET = 'GET_REWARDS_POINTS_RESET';

/** @namespace Pwa/Store/RewardsPoints/Actions/getRewardsPoints */
export const getRewardsPoints = (rewardsPoints = {}) => ({
    type: GET_REWARDS_POINTS,
    ...(rewardsPoints ?? {}),
});

/** @namespace Pwa/Store/RewardsPoints/Actions/getRewardsPointsBalance */
export const getRewardsPointsBalance = (balance, moneyBalance) => ({
    type: GET_REWARDS_POINTS_BALANCE,
    balance,
    moneyBalance,
});

/** @namespace Pwa/Store/RewardsPoints/Actions/getRewardsPointsError */
export const getRewardsPointsError = (error) => ({
    type: GET_REWARDS_POINTS_ERROR,
    error,
});

/** @namespace Pwa/Store/RewardsPoints/Actions/getRewardsPointsReset */
export const getRewardsPointsReset = () => ({
    type: GET_REWARDS_POINTS_RESET,
});
