import {
    BRANDS,
    COMPARE,
    DEMO_NOTICE,
    MY_ACCOUNT_WISHLIST,
    NAVIGATION_TABS,
    NEW_VERSION_POPUP,
    SHARED_WISHLIST,
} from 'AdvoxComponent/Router/Router.config';

export * from 'AdvoxComponent/Router/Router.config';

export const MY_ACCOUNT_RMA_ORDERS = 'MY_ACCOUNT_RMA_ORDERS';
export const MY_ACCOUNT_LOYALTY_PROGRAM = 'MY_ACCOUNT_LOYALTY_PROGRAM';
export const RMA = 'RMA';
export const PAYMENT_THANKYOU = 'PAYMENT_THANKYOU';
export const HEAD_INCLUDES = 'HEAD_INCLUDES';

export const BEFORE_ITEMS_TO_REMOVE = [NAVIGATION_TABS, DEMO_NOTICE, NEW_VERSION_POPUP];
export const SWITCH_ITEMS_TYPE_TO_REMOVE = [BRANDS, COMPARE, MY_ACCOUNT_WISHLIST, SHARED_WISHLIST];
