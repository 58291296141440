import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    getUpdateNewsletterMutation(options) {
        return new Field('updateCustomer')
            .addArgument('input', 'CustomerInput!', options)
            .addField(this._getCustomerField());
    }
}

export default new MyAccountQuery();
