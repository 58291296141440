import { ProductPrice as SourceProductPrice } from 'AdvoxComponent/ProductPrice/ProductPrice.component';
import DiscountLabel from 'Component/DiscountLabel/DiscountLabel.component';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import { TYPE_PRODUCT } from 'Route/UrlRewrites/UrlRewrites.config';
import { roundPercentage } from 'Util/Percentage/Percentage';

/** @namespace Pwa/Component/ProductPrice/Component */
export class ProductPrice extends SourceProductPrice {
    renderDiscountLabel() {
        const { discountPercentage } = this.props;

        return <DiscountLabel value={roundPercentage(discountPercentage)} />;
    }

    renderDefaultPrice(defaultLabel = null) {
        const { price: { finalPrice = {}, finalPriceExclTax = {} } = {}, label } = this.props;

        return (
            <>
                {this.renderOldPrice()}
                {this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, defaultLabel || label)}
                {this.renderSchema()}
            </>
        );
    }

    getCurrentPriceSchema(priceValue) {
        const { isSchemaRequired } = this.props;

        return isSchemaRequired ? { itemProp: 'price', content: priceValue } : {};
    }

    renderPrice(price, label) {
        const { value: priceValue, valueFormatted: priceFormatted = 0 } = price;
        const { discountPercentage } = this.props;
        const { itemProp = null, content = null } = this.getCurrentPriceSchema(priceValue);

        if (priceValue === 0) {
            return null;
        }

        return (
            <span block="ProductPrice" elem="Price">
                {this.renderPriceBadge(label)}
                <span
                    itemProp={itemProp}
                    content={content}
                    block="ProductPrice"
                    elem="PriceValue"
                    mods={{ isSpecialPrice: !!discountPercentage }}
                >
                    {priceFormatted}
                </span>
            </span>
        );
    }

    renderOldPrice() {
        const {
            price: { originalPrice: { value: originalPriceValue, valueFormatted: originalPriceFormatted } = {} } = {},
            discountPercentage,
            isSchemaRequired,
            variantsCount,
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        return (
            <>
                {this.renderDiscountLabel()}
                <span
                    block="ProductPrice"
                    elem="HighPrice"
                    aria-label={__('Old product price')}
                    itemProp={isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null}
                >
                    {originalPriceFormatted}
                </span>
            </>
        );
    }

    renderRegularPrice(price) {
        const { urlRewrite = {} } = this.props;
        const { value, valueFormatted } = price;

        if (!value || value <= 0 || !valueFormatted) {
            return null;
        }

        return (
            <>
                {urlRewrite.type === TYPE_PRODUCT && this.renderDiscountLabel()}
                <del block="ProductPrice" elem="HighPrice">
                    {valueFormatted}
                </del>
            </>
        );
    }

    renderBundlePrice() {
        const {
            urlRewrite = {},
            originalPrice: {
                minFinalPrice = {},
                minFinalPrice: { value: minValue = 0 } = {},
                minFinalPriceExclTax = {},
                minRegularPrice = {},
                minRegularPrice: { value: minRegularValue = 0 } = {},
            },
            discountPercentage,
            mix,
            isPreview,
        } = this.props;

        const renderer =
            minValue === 0
                ? this.renderDefaultPrice()
                : this.renderPriceWithOrWithoutTax(minFinalPrice, minFinalPriceExclTax);

        if (urlRewrite.type === TYPE_PRODUCT) {
            return (
                <p block="ProductPrice" mods={{ hasDiscount: discountPercentage !== 0, isPreview }} mix={mix}>
                    {minValue < minRegularValue && this.renderRegularPrice(minRegularPrice)}
                    {renderer}
                    {this.renderSchema()}
                </p>
            );
        }

        return (
            <p block="ProductPrice" mods={{ hasDiscount: discountPercentage !== 0, isPreview }} mix={mix}>
                {renderer}
                {minValue < minRegularValue && this.renderRegularPrice(minRegularPrice)}
                {this.renderSchema()}
            </p>
        );
    }

    render() {
        const {
            price: { finalPrice, originalPrice, finalPrice: { value: finalPriceValue = 0 } = {} } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        if (priceType === PRODUCT_TYPE.bundle) {
            return this.renderBundlePrice();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        return (
            <p
                block="ProductPrice"
                mods={{ hasDiscount: discountPercentage !== 0, isPreview }}
                mix={mix}
                aria-label={`Product price: ${finalPriceValue}`}
            >
                {isPreview && renderer && renderer()}
                {(!isPreview || !renderer) && this.renderDefaultPrice()}
                {priceType !== PRODUCT_TYPE.bundle && this.renderTierPrice()}
            </p>
        );
    }
}

export default ProductPrice;
