import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { formatPrice } from 'Util/Price';

import './LowestPriceIn30Days.style';

/** @namespace Pwa/Component/LowestPriceIn30Days/Component */
export class LowestPriceIn30Days extends PureComponent {
    static propTypes = {
        lowestPrice: PropTypes.object.isRequired,
    };

    renderContent() {
        const {
            lowestPrice: { value_excl_tax: value },
            currencyCode,
            omnibusEnableFront,
        } = this.props;

        if (!omnibusEnableFront || !value) return null;

        return (
            <p>
                {__('Lowest price within 30 days before the promotion: ')}
                {formatPrice(value, currencyCode)}
            </p>
        );
    }

    render() {
        return <div block="LowestPriceIn30Days">{this.renderContent()}</div>;
    }
}

export default LowestPriceIn30Days;
